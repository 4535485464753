/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  Tooltip,
  Badge,
} from '@material-ui/core'
import TableUploadDataHead from './TableUploadDataHead'
import DeleteIcon from '@material-ui/icons/Delete'
import UndoIcon from '@material-ui/icons/Undo'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import styles from '../../theme'
import EditLongCopyContainer from '../EditLongCopy/EditLongCopyContainer'
import EditFeatureBulletsContainer from '../EditFeatureBullets/EditFeatureBulletsContainer'
import DialogBoxDeleteContainer from '../DialogBoxDelete/DialogBoxDeleteContainer'
import FlagIcon from '@material-ui/icons/Flag'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DialogBoxAutoGenAiContainer from '../DialogBoxAutoGenAi/DialogBoxAutoGenAiContainer'
import {
  RESTRICTED_PROFANE_WARNING,
  INAPROPRIATE_PROFANE_WARNING,
} from '../../../../Shared/Constants'
import { lwoDiscFunc, loneDiscFunc } from '../../../../Shared/SharedUtils'

let TableUploadData = (props) => {
  const {
    selected,
    classes,
    uploadData,
    handleSelectAllClick,
    isSelected,
    handleClick,
    revertBackFeatureBullets,
    revertBackLongCopy,
    handleDeleteConfirmation,
    handleAutoGenAi,
  } = props

  return (
    <div>
      {uploadData.length ? (
        <Paper className={classes.tableWrapper}>
          {/* <div className={classes.profaneMainWrap}>
            <Tooltip title={RESTRICTED_PROFANE_WARNING}>
              <div className={classes.profaneWrap}>
                <span className={classes.profaneHeading}>Restricted word</span>
                <span className={classes.loneColour} />
              </div>
            </Tooltip>
            <Tooltip title={INAPROPRIATE_PROFANE_WARNING}>
              <div className={classes.profaneWrap}>
                <span className={classes.profaneHeading}>
                  Inappropriate Word
                </span>
                <span className={classes.ltwoColour} />
              </div>
            </Tooltip>
          </div> */}
          <div className={classes.tableWrapperInner}>
            <Table aria-labelledby="tableTitle" stickyHeader>
              <TableUploadDataHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={uploadData.length}
              />
              <TableBody>
                {uploadData.map((data) => {
                  const findIsSelected = isSelected(data)
                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={findIsSelected}
                      tabIndex={-1}
                      key={data.tcin}
                      selected={findIsSelected}
                      className="buldUploadWrapper"
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={findIsSelected}
                          onClick={(event) => handleClick(event, data)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell padding="none">{data.tcin}</TableCell>
                      <TableCell
                        className={classes.editFeatureBullets}
                        padding="none"
                      >
                        <EditFeatureBulletsContainer
                          data={data}
                          genAiFlag={false}
                        />
                      </TableCell>

                      <TableCell className={classes.revertBackCell}>
                        {data.isFeatureBulletsEdited && (
                          <Tooltip title="Revert back Feature Bullets">
                            <UndoIcon
                              className={classes.revertBack}
                              onClick={() =>
                                revertBackFeatureBullets(data.tcin)
                              }
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        {data.featureBullets.length < 3 && (
                          <Tooltip title="Minimum 3 bullets recommended">
                            <Badge
                              color="error"
                              badgeContent={data.featureBullets.length}
                              className={classes.defaultCursor}
                            >
                              {''}
                            </Badge>
                          </Tooltip>
                        )}
                      </TableCell>

                      <TableCell
                        className={classes.editLongCopy}
                        padding="none"
                      >
                        <EditLongCopyContainer data={data} />
                      </TableCell>

                      <TableCell className={classes.revertBackCell}>
                        {data.isLongCopyEdited && (
                          <Tooltip title="Revert back Long Copy">
                            <UndoIcon
                              className={classes.revertBack}
                              onClick={() => revertBackLongCopy(data.tcin)}
                            />
                          </Tooltip>
                        )}
                      </TableCell>

                      {/* <TableCell className={classes.celAlignCustom}>
                        {data?.mvi ? (
                          <Tooltip title="Auto Generate">
                            <AddCircleIcon
                              className={classes.autoGen}
                              onClick={(event) => handleAutoGenAi(data.tcin)}
                            />
                          </Tooltip>
                        ) : null}

                        <DialogBoxAutoGenAiContainer
                          data={data}
                          perirscopeFlow={false}
                          bulkUploadFlow={true}
                        />
                      </TableCell> */}
                      <TableCell>
                        <Tooltip title="Delete">
                          <DeleteIcon
                            className={classes.deleteButton}
                            onClick={(event) =>
                              handleDeleteConfirmation(data.tcin)
                            }
                          />
                        </Tooltip>
                        <DialogBoxDeleteContainer data={data} />
                      </TableCell>
                      {lwoDiscFunc(data.ltwoDisclaimer) && (
                        <span>
                          {data.isLongCopyEditable ||
                          data.isFeatureBulletsEditable ? (
                            ''
                          ) : (
                            <div className={classes.ltwoDisclaimer}>
                              <FlagIcon
                                style={{
                                  color: 'white',
                                  verticalAlign: 'middle',
                                }}
                              />
                              {loneDiscFunc(data.ltwoDisclaimer)
                                ? ''
                                : INAPROPRIATE_PROFANE_WARNING}
                            </div>
                          )}
                        </span>
                      )}
                      {loneDiscFunc(data.ltwoDisclaimer) && (
                        <span>
                          {data.isLongCopyEditable ||
                          data.isFeatureBulletsEditable ? (
                            ''
                          ) : (
                            <div className={classes.ltwoDisclaimer}>
                              <FlagIcon
                                style={{
                                  color: 'white',
                                  verticalAlign: 'middle',
                                }}
                              />
                              {RESTRICTED_PROFANE_WARNING}
                            </div>
                          )}
                        </span>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      ) : (
        <div className={classes.noResult}>
          No results matched your filter criteria
        </div>
      )}
    </div>
  )
}

TableUploadData = reduxForm({ form: 'bulkUploadData' })(TableUploadData)
TableUploadData = connect((state) => ({
  initialValues: {
    tcin: '',
    notes: '',
    feature_Bullets: '',
  }, // pull initial values from account reducer
}))(TableUploadData)
export default withStyles(styles)(TableUploadData)
