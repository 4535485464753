import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Tooltip } from '@material-ui/core'

import {
  RESTRICTED_PROFANE_WARNING,
  INAPROPRIATE_PROFANE_WARNING,
} from '../../../../Shared/Constants'

function SharedWarningProfane(props) {
  const { classes } = props

  return (
    <div className={classes.secondaryHeadingPartTwo}>
      {' '}
      <div className={classes.secondaryHeadingInner}>
        <Tooltip title={RESTRICTED_PROFANE_WARNING}>
          <div>
            <span className={classes.loneColour} /> Restricted word
          </div>
        </Tooltip>
      </div>
      <div className={classes.secondaryHeadingInner}>
        <Tooltip title={INAPROPRIATE_PROFANE_WARNING}>
          <div>
            <span className={classes.ltwoColour} /> Inappropriate word
          </div>
        </Tooltip>
      </div>
    </div>
  )
}
export default withStyles(styles)(SharedWarningProfane)
