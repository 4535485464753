import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Grid, Button } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'

// import AddIcon from '@material-ui/icons/Add'

function ItemSearch(props) {
  const {
    classes,
    handleSearchForLongCopyAndFeatureBullets,
    searchedItems,
    handleAddTcin,
    handleDeleteTcin,
  } = props
  return (
    <div className={classes.itemSearchContainer}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <p className={classes.subHeadingSearch}>
            Modify or Generate copy by entering TCINs
          </p>

          <p className={classes.subHeadingSearchOne}>
            You can modify or generate copy for both new & exisiting TCINs
          </p>
        </Grid>
      </Grid>
      <div className={searchedItems.length > 12 ? classes.tcinSearch : null}>
        <ChipInput
          value={searchedItems}
          fullWidth
          fullWidthInput
          blurBehavior="add"
          onAdd={(chips) => handleAddTcin(chips)}
          onDelete={(deletedChip) => handleDeleteTcin(deletedChip)}
          placeholder="Enter one or more TCIN"
        />
      </div>
      <div>
        <Button
          disabled={searchedItems.length < 1}
          className={classes.primaryButton}
          onClick={() => handleSearchForLongCopyAndFeatureBullets()}
          color="primary"
          variant="contained"
        >
          SEARCH
        </Button>
        {/* <Button
          disabled={true}
          className={classes.primaryButton}
          onClick={() => handleSearchForLongCopyAndFeatureBullets()}
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
        >
          AUTO GENERATE CONTENT
        </Button> */}
      </div>
    </div>
  )
}
export default withStyles(styles)(ItemSearch)
