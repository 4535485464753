import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import CopyDetailContainer from './Components/EditCopy/CopyDetail/CopyDetailContainer'
import { Grid, CircularProgress } from '@material-ui/core'
import styles from './theme'
import { withStyles } from '@material-ui/core/styles'
import { viewCopyDetailEvent } from '../../../store/longCopy/actionCreator'
import { withRouter } from '../../Shared/WithRouterHoc'

const EditLongCopy = (props) => {
  useEffect(() => {
    props.viewCopyDetailEvent(props.router.params.id)
  }, [props.router.params.id])

  return (
    <div>
      <HeaderTitle title="Long Copy" />
      <Helmet title="Edit Long Copy" />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            {props?.isItemDataAvailable && props?.isEditCopyDataAvailable && (
              <div>
                {' '}
                <CopyDetailContainer />{' '}
              </div>
            )}
            {(!props?.isItemDataAvailable ||
              !props?.isEditCopyDataAvailable) && (
              <div className="center">
                <CircularProgress />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      viewCopyDetailEvent,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { longCopy } = state
  const { isItemDataAvailable, isEditCopyDataAvailable } = longCopy
  return {
    isItemDataAvailable,
    isEditCopyDataAvailable,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(EditLongCopy)))
