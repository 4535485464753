const styles = (theme) => ({
  spacer: {
    marginTop: '35px',
  },
  itemsCountContainer: {
    marginLeft: '10px',
  },
  divider: {
    fontWeight: 500,
    fontSize: '16px',
    '@media (max-width: 870px)': {
      padding: 0,
    },
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    color: '#6B6B6B',
  },
  bulkUploadTopCard: {
    padding: '30px 15px',
    '@media (max-width: 870px)': {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  browseButton: {
    color: 'rgba(76, 144, 254, 1)',
    cursor: 'pointer',
    fontWeight: '700',
    textDecoration: 'none',
  },
  dropZoneNotActive: {
    margin: 'auto',
    border: '1px #C5CBD2 dashed  ',
    //padding: '0px 20px 25px 20px',
    backgroundColor: '#F0F2F8',
    height: '92px',
    borderRadius: '4px',
  },
  itemSearchContainer: {
    margin: 'auto',
    // width: '350px',
    //border: '1px black',
    padding: '25px',
    //backgroundColor: '#F0F2F8',
    border: '1px solid #C5CBD2',
    borderRadius: '8px',
  },
  dropZoneActive: {
    backgroundColor: 'rgba(173, 216, 230, 0.5)',
    margin: 'auto',
    width: '350px',
    border: 'dashed 1px rgba(33,150,243, 1)',
    padding: '0px 20px 25px 20px',
  },
  uploadIcon: {
    width: '24px',
    height: '25px',
    color: '#366CD9',
    marginTop: '10px',
  },
  DropZoneLargeText: {
    marginTop: '0px !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '12px',
    color: '#999999',
    textAlign: 'center',
    width: '100%',
    lineHeight: '14px',
    marginBottom: '5px !important',
  },
  DropZoneSmallText: {
    marginTop: '0 !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '10px',
    color: '#6B6B6B',
    textAlign: 'center',
    width: '100%',
  },
  progressBar: {
    marginTop: '30px',
    marginLeft: '50%',
  },
  secondaryButton: {
    margin: '10px 10px 0 10px',
    padding: '4px 30px',
  },
  primaryButton: {
    marginLeft: '10px',
    marginTop: '10px',
    padding: '5px 30px',
    display: 'inline-block',
  },
  itemImage: {
    maxWidth: '65px',
    maxHeight: '65px',
  },
  revertBack: {
    height: '50px',
    width: '50px',
    cursor: 'pointer',
  },
  margin10: {
    margin: '10px',
  },
  marginAuto: {
    margin: 'auto',
  },
  noCopy: {
    margin: '10px',
    height: '50px',
  },
  noBullets: {
    height: '50px',
  },
  deleteButton: {
    cursor: 'pointer',
  },
  wrongFileTitle: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 1)',
  },
  editLongCopy: {
    maxWidth: '300px',
    overflowWrap: 'break-word',
  },
  clickToEditContainer: {
    margin: '10px',
    marginBottom: '30px',
    minHeight: '62px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
  editFeatureBullets: {
    maxWidth: '200px',
  },
  tcinSearch: {
    overflowY: 'auto',
    height: '150px',
  },
  tableWrapper: {
    overflowX: 'auto',
    minWidth: '1020px',
    width: '100%',
    marginTop: '5px',
  },
  noResult: {
    textAlign: 'center',
    padding: '20px',
  },
  marginTopBottom5: {
    margin: '5px 0',
  },
  downloadDataButton: {
    marginLeft: '90px',
    marginRight: 0,
    width: '190px',
    padding: '4px',
  },
  revertBackCell: {
    maxWidth: '80px',
    paddingLeft: '30px',
    paddingRight: '20px',
  },
  defaultCursor: {
    cursor: 'default',
  },
  loneColour: {
    display: 'inline-block',
    height: '14px',
    width: '14px',
    backgroundColor: '#eeee00',
    verticalAlign: 'text-top',
  },
  ltwoColour: {
    display: 'inline-block',
    height: '14px',
    width: '14px',
    backgroundColor: '#ffa500',
    verticalAlign: 'text-top',
  },
  profaneHeading: {
    fontSize: '12px',
    textAlign: 'left',
    display: 'block',
    fontWeight: 'bold',
  },
  profaneWrap: {
    display: 'inline-Block',
    marginRight: '10px',
  },
  profaneMainWrap: {
    textAlign: 'right',
    marginRight: '35px',
  },
  ltwoDisclaimer: {
    color: 'white',
    position: 'absolute',
    bottom: '5px',
    left: '39%',
    backgroundColor: '#ffa500',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  saveFilterTitle: {
    backgroundColor: '#3f51b5',
  },
  saveTitleText: {
    color: 'white',
  },
  marginTop20: {
    marginTop: '20px',
  },
  multiSelectWidth: {
    width: '250px',
  },
  verticalAlignBotttom: {
    verticalAlign: 'bottom',
  },
  manageSearchWrapper: {
    overflowX: 'auto',
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 250px)',
  },
  subHeadingSearch: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#333333',
    lineHeight: '18px',
  },
  subHeadingSearchOne: {
    fontSize: '13px',
    fontWeight: '400',
    color: '#6B6B6B',
    lineHeight: '15px',
  },
  mainHeadingCopy: {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '0',
    paddingLeft: '15px',
    display: 'inline-block',
    width: '48%',
  },
  itemBulkUploadContainer: {
    margin: 'auto',
    padding: '20px',
    border: '1px solid #C5CBD2',
    borderRadius: '8px',
  },
  rightSubHeading: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
  rightSubHeadingOne: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '15px',
    color: '#6B6B6B',
  },
  orWrapper: {
    position: 'relative',
  },
  tableWrapperInner: {
    overflowX: 'auto',
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 250px)',
  },
  secondaryHeading: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '24px',
    color: '#000000',
    display: 'inline-Block',
    width: '50%',
  },
  secondaryHeadingPartTwo: {
    display: 'inline-Block',
    width: '50%',
    textAlign: 'right',
  },
  secondaryHeadingInner: {
    display: 'inline-Block',
    marginLeft: '20px',
    fontSize: '14px',
    fontWeight: '400',
    color: '#6B6B6B',
  },
  selectedTcinCount: {
    display: 'inline-block',
    width: '50%',
    fontSize: '16px',
    fontWeight: '500',
  },
  groupCTAWrapper: {
    display: 'inline-block',
    width: '50%',
    textAlign: 'right',
  },
  singleCTA: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingLeft: '10px',
  },
  mainWrapperCopy: {
    marginTop: '20px',
  },
  faqSec: {
    display: 'inline-block',
    width: '50%',
    textAlign: 'right',
    texTransform: 'uppercase',
    color: ' #366CD9',
    fontWeight: '500',
    cursor: 'pointer',
  },
  autoGen: {
    color: '#366CD9',
    cursor: 'pointer',
  },
  celAlignCustom: {
    paddingLeft: '80px',
  },
  historyFeatureBullet: {
    margin: '16px 0 9px 0',
    padding: '19px 6px',
    width: '96%',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '4px',
    overflowY: 'auto',
    maxHeight: '300px',
    color: 'rgba(0, 0, 0, 1)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
  historyLongCopy: {
    margin: '16px 0 9px 0',
    padding: '6px 14px',
    width: '94%',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '4px',
    overflowY: 'auto',
    maxHeight: '300px',
    color: 'rgba(0, 0, 0, 1)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
  historyheaderHighlightText: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '600',
    fontStyle: 'strong',
    fontSize: '18px',
    marginLeft: '5px',
  },
  loadingSpinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '100px 0 0 0',
    minHeight: '250px',
  },
  wrapperSpace: {
    display: 'inline-block',
    width: '95%',
    paddingTop: '15px',
  },
  wrapperSpaceMain: {
    position: 'relative',
  },
  deleteIconAlignMent: {
    position: 'absolute',
    top: '40%',
    cursor: 'pointer',
  },
  errorMessageMosaic: {
    color: 'red',
    textAlign: ' left',
    paddingLeft: '10px',
    left: '15px',
    paddingBottom: '5px',
  },
  simplePopOverAlignment: {
    position: 'absolute',
    top: '40%',
    cursor: 'pointer',
    right: 0,
  },
  evalWarning: {
    color: 'red',
  },
  aiDiclaimer: {
    width: '85%',
    fontSize: '14px',
  },
})

export default styles
