/* eslint-disable array-callback-return */
import {
  REQUEST_UPLOAD_DATA,
  RECEIVE_UPLOAD_DATA,
  BULK_UPLOAD_RESET_FETCH,
  CLEAR_UPLOAD_DATA,
  BULKUPLOAD_DROP_ZONE_ACTIVE,
  SELECT_DATA_EVENT,
  REMOVE_DATA_EVENT,
  UPDATE_LONG_COPY,
  UPDATE_LONG_COPY_EDIT_STATE,
  UPDATE_FEATURE_BULLETS,
  UPDATE_FEATURE_BULLETS_EDIT_STATE,
  REVERT_FEATURE_BULLETS,
  REVERT_LONG_COPY,
  HANDLE_DELETE_CONFIRMATION,
  WRONG_FILE_ADDED,
  PUBLISH_EVENT_SUCCESS,
  UPDATE_SELECTED_LONG_COPY,
  UPDATE_SELECTED_FEATURE_BULLETS,
  UPDATE_ITEM_SEARCH,
  DOWNLOAD_START_BULK_UPLOAD,
  DOWNLOAD_FINISH_BULK_UPLOAD,
  CHANGE_SEARCH_ITEM,
  STORE_BULK_UPLOAD_STRAPI_DATA,
  SHOW_DEFAULT_ALERT_BULK_UPLOAD,
  BULK_UPLOAD_SUBMIT_ERROR,
  HANDLE_AUT0GEN_AI,
  HANDLE_AUT0GEN_RESPONSE,
  FEATURE_BULLETS_EDIT_GENAI,
  UPDATE_FEATURE_BULLETS_AI,
  LONG_COPY_EDIT_GENAI,
  UPDATE_LONG_COPY_AI,
  GENERATED_SUBMISSION,
  RESET_GENAI_RESPONSE,
  CLOSE_HANDLE_AUT0GEN_AI,
} from './actionType'

const initState = {
  selectedData: [],
  uploadData: [],
  defaultUploadData: [],
  isFetching: false,
  defaultPageSize: 10,
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  confirmationPayload: undefined,
  dropZoneEnter: false,
  validFile: false,
  fileName: '',
  dropZoneErrorMessage: '',
  dropZoneErrorTitle: '',
  inputTcins: [],
  searchedItems: [],
  isCopyEdited: false,
  alert: null,
  information: null,
  faq: null,
  error: null,
  showDefaultAlert: false,
  bulletCountMessage: null,
  bulkUploadSubmitError: false,
  genAiResponse: null,
}

export default function bulkUploadReducer(state = initState, action = {}) {
  switch (action.type) {
    case REQUEST_UPLOAD_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case RESET_GENAI_RESPONSE: {
      const newState = {
        ...state,
        genAiResponse: null,
      }
      return newState
    }
    case GENERATED_SUBMISSION: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload?.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: payload?.longCopy,
              featureBullets: payload?.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: temp.isLongCopyEditable,
              isLongCopyEdited: payload.isLongCopyEdited,
              isFeatureBulletsEditable: temp.isFeatureBulletsEditable,
              isFeatureBulletsEdited: payload.isFeatureBulletsEdited,
              confirmAutoGenAi: payload.confirmAutoGenAi,
              generated_long_copy: payload.generated_long_copy,
              generated_feature_bullets: payload.generated_feature_bullets,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
        selectedData: state.selectedData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: payload?.longCopy,
              featureBullets: payload.featureBullets,
              valid: payload.valid,
              generated_long_copy: payload.generated_long_copy,
              generated_feature_bullets: payload.generated_feature_bullets,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case HANDLE_AUT0GEN_RESPONSE: {
      const { payload } = action
      const newState = {
        ...state,
        genAiResponse: payload,
      }
      return newState
    }
    case FEATURE_BULLETS_EDIT_GENAI: {
      const { payload } = action
      const newState = {
        ...state,
        genAiResponse: {
          ...state.genAiResponse,
          isFeatureBulletsEditable: payload.isFeatureBulletsEditable,
        },
      }
      return newState
    }

    case LONG_COPY_EDIT_GENAI: {
      const { payload } = action
      const newState = {
        ...state,
        genAiResponse: {
          ...state.genAiResponse,
          isLongCopyEditable: payload.isLongCopyEditable,
        },
      }
      return newState
    }

    case UPDATE_FEATURE_BULLETS_AI: {
      const { payload } = action
      const newState = {
        ...state,
        genAiResponse: {
          ...state.genAiResponse,
          tcin: payload.tcin,
          featureBullets: payload.featureBullets,
          isFeatureBulletsEditable: payload.isFeatureBulletsEditable,
          isFeatureBulletsEdited: payload.isFeatureBulletsEdited,
          valid: payload.valid,
          ltwoDisclaimer: payload.ltwoDisclaimer,
        },
      }
      return newState
    }
    case UPDATE_LONG_COPY_AI: {
      const { payload } = action
      const newState = {
        ...state,
        genAiResponse: {
          ...state.genAiResponse,
          tcin: payload.tcin,
          longCopy: payload.longCopy,
          isLongCopyEditable: payload.isLongCopyEditable,
          isLongCopyEdited: payload.isLongCopyEdited,
          valid: payload.valid,
          ltwoDisclaimer: payload.profane_category,
        },
      }
      return newState
    }
    case DOWNLOAD_START_BULK_UPLOAD: {
      const newState = {
        ...state,
        isFetching: true,
      }
      return newState
    }
    case DOWNLOAD_FINISH_BULK_UPLOAD: {
      const newState = {
        ...state,
        isFetching: false,
      }
      return newState
    }
    case RECEIVE_UPLOAD_DATA: {
      const { payload } = action
      payload.uploadData.data.map((data) => {
        if (data.long_copy !== null && data.long_copy !== undefined) {
          data.long_copy = data.long_copy.replace(/(\n)/g, '<br />')
        }
        data.itemData = {}
        data.isLongCopyEditable = false
        data.isLongCopyEdited = false
        data.isFeatureBulletsEditable = false
        data.isFeatureBulletsEdited = false
        data.confirmDelete = false
      })
      const newState = {
        ...state,
        defaultUploadData: payload.uploadData.data.map((item) => {
          var data = {}
          data.tcin = item.tcin
          data.longCopy = item.long_copy
          data.featureBullets = item.feature_bullets
          data.valid = item.valid
          data.ltwoDisclaimer = item.profane_category
          return data
        }),
        uploadData: payload.uploadData.data.map((item) => {
          var data = {}
          data.tcin = item.tcin
          data.longCopy = item.long_copy
          data.featureBullets = item.feature_bullets
          data.valid = item.valid
          data.isLongCopyEditable = item.isLongCopyEditable
          data.isLongCopyEdited = false
          data.isFeatureBulletsEditable = item.isFeatureBulletsEditable
          data.isFeatureBulletsEdited = false
          data.confirmDelete = false
          data.ltwoDisclaimer = item.profane_category
          data.mvi = item.mvi
          data.generated_feature_bullets = item.generated_feature_bullets
          data.generated_long_copy = item.generated_long_copy
          return data
        }),
        selectedData: payload.uploadData.data.map((item) => {
          var data = {}
          data.tcin = item.tcin
          data.longCopy = item.long_copy
          data.featureBullets = item.feature_bullets
          data.valid = item.valid
          data.ltwoDisclaimer = item.profane_category
          data.generated_feature_bullets = item.generated_feature_bullets
          data.generated_long_copy = item.generated_long_copy
          return data
        }),
        isFetching: payload.isFetching,
      }
      return {
        ...newState,
        isCopyEdited:
          newState.uploadData.filter((item) => {
            return (
              item.isLongCopyEdited === true ||
              item.isFeatureBulletsEdited === true
            )
          }).length > 0,
      }
    }
    case BULK_UPLOAD_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case CLEAR_UPLOAD_DATA: {
      const newState = {
        ...state,
        selectedData: [],
        uploadData: [],
        defaultUploadData: [],
        fileName: '',
        dropZoneErrorMessage: '',
        dropZoneErrorTitle: '',
        inputTcins: [],
      }
      return newState
    }
    case BULKUPLOAD_DROP_ZONE_ACTIVE: {
      const { payload } = action
      const newState = {
        ...state,
        dropZoneEnter: payload.dropZoneEnter,
      }
      return newState
    }
    case SELECT_DATA_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedData: payload.selectedData,
      }
      return newState
    }
    case REMOVE_DATA_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: payload.uploadData,
        selectedData: payload.selectedData,
        defaultUploadData: payload.defaultUploadData,
      }
      return {
        ...newState,
        isCopyEdited:
          newState.uploadData.filter((item) => {
            return (
              item.isLongCopyEdited === true ||
              item.isFeatureBulletsEdited === true
            )
          }).length > 0,
      }
    }

    case UPDATE_LONG_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            let initialDataHoldLongCopy = {
              generated_long_copy: {
                ...temp.generated_long_copy,
                modified: true,
              },
            }
            let checkForAiGenLongCopy = temp.generated_long_copy
              ? initialDataHoldLongCopy
              : null
            var item = {
              tcin: temp.tcin,
              longCopy: payload.longCopy,
              featureBullets: temp.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: payload.isLongCopyEditable,
              isLongCopyEdited: payload.isLongCopyEdited,
              isFeatureBulletsEditable: temp.isFeatureBulletsEditable,
              isFeatureBulletsEdited: temp.isFeatureBulletsEdited,
              confirmDelete: temp.confirmDelete,
              valid: payload.valid,
              ltwoDisclaimer: payload.ltwoDisclaimer,
              generated_long_copy: checkForAiGenLongCopy?.generated_long_copy,
              generated_feature_bullets: temp.generated_feature_bullets,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
        selectedData: state.selectedData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: payload.longCopy,
              featureBullets: temp.featureBullets,
              valid: payload.valid,
              generated_long_copy: temp.generated_long_copy,
              generated_feature_bullets: temp.generated_feature_bullets,
            }
            return item
          }
          return temp
        }),
      }
      return {
        ...newState,
        isCopyEdited:
          newState.uploadData.filter((item) => {
            return (
              item.isLongCopyEdited === true ||
              item.isFeatureBulletsEdited === true
            )
          }).length > 0,
      }
    }
    case UPDATE_LONG_COPY_EDIT_STATE: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: temp.longCopy,
              featureBullets: temp.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: payload.isLongCopyEditable,
              isLongCopyEdited: temp.isLongCopyEdited,
              isFeatureBulletsEditable: temp.isFeatureBulletsEditable,
              isFeatureBulletsEdited: temp.isFeatureBulletsEdited,
              confirmDelete: temp.confirmDelete,
              valid: temp.valid,
              ltwoDisclaimer: temp.ltwoDisclaimer,
              generated_long_copy: temp.generated_long_copy,
              generated_feature_bullets: temp.generated_feature_bullets,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case UPDATE_FEATURE_BULLETS: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            let initialDataHoldBullets = {
              generated_feature_bullets: {
                ...temp.generated_feature_bullets,
                modified: true,
              },
            }
            let checkForAiGenBullets = temp.generated_feature_bullets
              ? initialDataHoldBullets
              : null

            var item = {
              tcin: temp.tcin,
              longCopy: temp.longCopy,
              featureBullets: payload.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: temp.isLongCopyEditable,
              isLongCopyEdited: temp.isLongCopyEdited,
              isFeatureBulletsEditable: payload.isFeatureBulletsEditable,
              isFeatureBulletsEdited: payload.isFeatureBulletsEdited,
              confirmDelete: temp.confirmDelete,
              valid: payload.valid,
              ltwoDisclaimer: payload.ltwoDisclaimer,
              confirmAutoGenAi: temp.confirmAutoGenAi,
              generated_long_copy: temp.generated_long_copy,
              generated_feature_bullets:
                checkForAiGenBullets?.generated_feature_bullets,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
        selectedData: state.selectedData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: temp.longCopy,
              featureBullets: payload.featureBullets,
              valid: payload.valid,
              generated_long_copy: temp.generated_long_copy,
              generated_feature_bullets: temp.generated_feature_bullets,
            }
            return item
          }
          return temp
        }),
      }
      return {
        ...newState,
        isCopyEdited:
          newState.uploadData.filter((item) => {
            return (
              item.isLongCopyEdited === true ||
              item.isFeatureBulletsEdited === true
            )
          }).length > 0,
      }
    }
    case UPDATE_FEATURE_BULLETS_EDIT_STATE: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: temp.longCopy,
              featureBullets: temp.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: temp.isLongCopyEditable,
              isLongCopyEdited: temp.isLongCopyEdited,
              isFeatureBulletsEditable: payload.isFeatureBulletsEditable,
              isFeatureBulletsEdited: temp.isFeatureBulletsEdited,
              confirmDelete: temp.confirmDelete,
              ltwoDisclaimer: temp.ltwoDisclaimer,
              confirmAutoGenAi: temp.confirmAutoGenAi,
              generated_feature_bullets: temp.generated_feature_bullets,
              generated_long_copy: temp.generated_long_copy,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case REVERT_FEATURE_BULLETS: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: temp.longCopy,
              featureBullets: payload.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: temp.isLongCopyEditable,
              isLongCopyEdited: temp.isLongCopyEdited,
              isFeatureBulletsEditable: temp.isFeatureBulletsEditable,
              isFeatureBulletsEdited: payload.isFeatureBulletsEdited,
              confirmDelete: temp.confirmDelete,
              valid: payload.valid,
              ltwoDisclaimer: payload.ltwoDisclaimer,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
      }
      return {
        ...newState,
        isCopyEdited:
          newState.uploadData.filter((item) => {
            return (
              item.isLongCopyEdited === true ||
              item.isFeatureBulletsEdited === true
            )
          }).length > 0,
      }
    }
    case REVERT_LONG_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: payload.longCopy,
              featureBullets: temp.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: temp.isLongCopyEditable,
              isLongCopyEdited: payload.isLongCopyEdited,
              isFeatureBulletsEditable: temp.isFeatureBulletsEditable,
              isFeatureBulletsEdited: temp.isFeatureBulletsEdited,
              confirmDelete: temp.confirmDelete,
              valid: payload.valid,
              ltwoDisclaimer: payload.ltwoDisclaimer,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
      }
      return {
        ...newState,
        isCopyEdited:
          newState.uploadData.filter((item) => {
            return (
              item.isLongCopyEdited === true ||
              item.isFeatureBulletsEdited === true
            )
          }).length > 0,
      }
    }
    case HANDLE_DELETE_CONFIRMATION: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: temp.longCopy,
              featureBullets: temp.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: temp.isLongCopyEditable,
              isLongCopyEdited: temp.isLongCopyEdited,
              isFeatureBulletsEditable: temp.isFeatureBulletsEditable,
              isFeatureBulletsEdited: temp.isFeatureBulletsEdited,
              confirmDelete: payload.confirmDelete,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case HANDLE_AUT0GEN_AI: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: temp.longCopy,
              featureBullets: temp.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: temp.isLongCopyEditable,
              isLongCopyEdited: temp.isLongCopyEdited,
              isFeatureBulletsEditable: temp.isFeatureBulletsEditable,
              isFeatureBulletsEdited: temp.isFeatureBulletsEdited,
              confirmAutoGenAi: payload.confirmAutoGenAi,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }

    case CLOSE_HANDLE_AUT0GEN_AI: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: temp.longCopy,
              featureBullets: temp.featureBullets,
              itemData: temp.itemData,
              isLongCopyEditable: temp.isLongCopyEditable,
              isLongCopyEdited: temp.isLongCopyEdited,
              isFeatureBulletsEditable: temp.isFeatureBulletsEditable,
              isFeatureBulletsEdited: temp.isFeatureBulletsEdited,
              confirmAutoGenAi: payload.confirmAutoGenAi,
              generated_feature_bullets: temp.generated_feature_bullets,
              generated_long_copy: temp.generated_long_copy,
              mvi: temp?.mvi,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }

    case WRONG_FILE_ADDED: {
      const { payload } = action
      const newState = {
        ...state,
        fileName: payload.fileName,
        dropZoneErrorTitle: payload.dropZoneErrorTitle,
        dropZoneErrorMessage: payload.dropZoneErrorMessage,
        validFile: payload.validFile,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case PUBLISH_EVENT_SUCCESS: {
      const { payload } = action
      const newState = {
        ...state,
        selectedData: [],
        uploadData: [],
        defaultUploadData: [],
        fileName: '',
        dropZoneErrorMessage: '',
        dropZoneErrorTitle: '',
        isFetching: payload.isFetching,
      }
      return newState
    }
    case UPDATE_SELECTED_LONG_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        selectedData: state.selectedData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: payload.longCopy,
              featureBullets: temp.featureBullets,
              valid: payload.valid,
              ltwoDisclaimer: payload.ltwoDisclaimer,
              generated_feature_bullets: temp.generated_feature_bullets,
              generated_long_copy: {
                ...temp.generated_long_copy,
                modified: true,
              },
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case UPDATE_SELECTED_FEATURE_BULLETS: {
      const { payload } = action
      const newState = {
        ...state,
        selectedData: state.selectedData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              longCopy: temp.longCopy,
              featureBullets: payload.featureBullets,
              valid: payload.valid,
              ltwoDisclaimer: payload.ltwoDisclaimer,
              generated_long_copy: temp.generated_long_copy,
              generated_feature_bullets: {
                ...temp.generated_feature_bullets,
                modified: true,
              },
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case UPDATE_ITEM_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        inputTcins: payload.inputTcins,
        searchedItems: [],
        isFetching: payload.isFetching,
      }
      return newState
    }
    case CHANGE_SEARCH_ITEM: {
      const { payload } = action
      const newState = {
        ...state,
        searchedItems: payload.searchedItems,
      }
      return newState
    }
    case STORE_BULK_UPLOAD_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
        error: payload.error,
        bulletCountMessage: payload.error,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_BULK_UPLOAD: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case BULK_UPLOAD_SUBMIT_ERROR: {
      const { payload } = action
      const newState = {
        ...state,
        bulkUploadSubmitError: payload,
      }
      return newState
    }
    default:
      return state
  }
}
