import React from 'react'
import { Toolbar, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import GetAppIcon from '@material-ui/icons/GetApp'
import { flattenDeep } from 'lodash'
import { isExternalUser } from '../../../Shared/SharedUtils'

let AutoDecisionDataContainerToolbar = (props) => {
  const { selectedCopy = [], classes = {}, submitPassivePathDecisons } = props

  let selectedTcins = flattenDeep(selectedCopy.map((item) => item.tcin))

  const originalSelectedCopy = JSON.parse(JSON.stringify(selectedCopy))

  const rowCountSelected = originalSelectedCopy
    .map((item) => {
      ;['feature_bullets', 'long_copy'].forEach((key) => {
        if (
          item[key] &&
          (item[key].checked === false || item[key].valid === false)
        ) {
          delete item[key]
        }
      })
      return item.feature_bullets || item.long_copy ? item : null
    })
    .filter(Boolean)

  const numSelected = rowCountSelected ? rowCountSelected.length : 0

  const approveOrRejectPassivePathData = (dataCurated, decision) => {
    const apiQueryparams = dataCurated?.map((item) => {
      ;['long_copy', 'feature_bullets'].forEach((key) => {
        if (item[key] && item[key].review_status) {
          item[key] = { ...item[key], review_status: decision }
        }
      })
      return { ...item }
    })
    submitPassivePathDecisons(
      apiQueryparams,
      props?.selectedFilters,
      props?.auth,
      isExternalUser(props?.auth)
    )
  }

  return (
    <React.Fragment>
      {numSelected > 0 && (
        <Toolbar className={classes.toolBar}>
          <div className={classes.title}></div>
          {numSelected > 0 ? null : <div className={classes.spacer} />}
          <div className={classes.actions}>
            {numSelected > 0 && (
              <>
                <Button
                  className={classes.drawerButton}
                  // @ts-ignore
                  onClick={() => {
                    navigator.clipboard.writeText(selectedTcins.join('\n'))
                  }}
                  disabled={numSelected === 0}
                >
                  <GetAppIcon />
                  <span className={classes.marginLeft5}>
                    Copy TCIN(s) to clipboard
                  </span>
                </Button>
                <Button
                  className={classes.drawerButton}
                  //   onClick={() => downloadSelectedTcinsCopy()}
                >
                  <ThumbUpIcon />
                  <span
                    className={classes.marginLeft5}
                    onClick={() =>
                      approveOrRejectPassivePathData(
                        rowCountSelected,
                        'Approve'
                      )
                    }
                  >
                    Approve
                  </span>
                </Button>
                <Button
                  className={classes.drawerButton}
                  //   onClick={() => downloadSelectedTcinsCopy()}
                >
                  <ThumbDownIcon />
                  <span
                    className={classes.marginLeft5}
                    onClick={() =>
                      approveOrRejectPassivePathData(rowCountSelected, 'Reject')
                    }
                  >
                    Reject
                  </span>
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      )}
    </React.Fragment>
  )
}

AutoDecisionDataContainerToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AutoDecisionDataContainerToolbar)
