import {
  NEW_FILTER_EVENT,
  BUILD_FILTER_EVENT,
  REQUEST_COPY_DATA,
  RECEIVE_COPY_DATA,
  CHANGE_FILTER_EVENT,
  SELECT_FILTER_VALUE_EVENT,
  CHANGE_DEFAULT_PAGE_SIZE_LONG_COPY,
  CHANGE_CURRENT_PAGE_LONG_COPY,
  SELECT_COPY_EVENT,
  REMOVE_FILTER_EVENT,
  LONG_COPY_RESET_FETCH,
  CLEAR_COPY_DATA_EVENT,
  VIEW_COPY_DETAIL_EVENT,
  REQUEST_DRAFT_COPY_EVENT,
  REQUEST_FIRST_DRAFT_COPY_EVENT,
  REQUEST_SAVE_COPY_EVENT,
  REQUEST_PUBLISH_COPY_EVENT,
  REQUEST_ITEM_DATA,
  RECEIVE_ITEM_DATA,
  REQUEST_DEPARTMENT,
  RECIEVE_DEPARTMENT,
  TOGGLE_ACTION_DRAWER,
  SET_ROUTE_TEAM,
  SET_IS_FETCHING_WRITERS,
  SET_ASSIGNED_WRITERS_LIST,
  SET_ASSIGNED_WRITER,
  REQUEST_ASSIGN_COPY,
  SUCCESS_ASSIGN_COPY,
  ADD_PLANNER_NOTES,
  REQUEST_SET_ROUTE_TEAM,
  SUCCESS_SET_ROUTE_TEAM,
  REQUEST_SAVE_PLANNER_NOTES,
  SUCCESS_SAVE_PLANNER_NOTES,
  SUCCESS_SAVE_PLANNER_NOTES_EDIT_PAGE,
  UPDATE_COPY_LIST,
  REQUEST_COPY_DETAIL_EVENT,
  UPDATE_SELECTED,
  UPDATE_CURRENT_IMAGES,
  UPDATE_CURRENT_IMAGE_SELECTED,
  UPDATE_EDIT_FEATURE_BULLET_STATE,
  UPDATE_EDIT_FEATURE_BULLET,
  UPDATE_EDIT_LONG_COPY_STATE,
  UPDATE_EDIT_LONG_COPY,
  ENTERED_EVENT,
  DOWNLOAD_START_LONG_COPY,
  DOWNLOAD_FINISH_LONG_COPY,
  STICKER_DATA_CALLED,
  STICKER_DATA_SUCCESS,
  UPDATE_SELECTED_STICKER,
  CLEAR_SELECTED_STICKER,
  DELETE_COPY_BULLET_CLICKED,
  CANCEL_COPY_BULLET_CLICKED,
  DELETE_OPTION_VALUE,
  CONFIRMATION_DELETE,
  DELETE_BACT_TO_LIST,
  CANCEL_QUICK_PUBLISH,
  HISTORY_INDEX_UPDATE,
  TAB_INDEX_UPDATE,
  BUILD_FILTER_EXPAND,
  REQUEST_MODAL_META_DATA,
  RECIEVE_MODAL_META_DATA,
  SET_SORT_FIELD,
  SET_SORT_DIRECTION,
  STORE_LONG_COPY_STRAPI_DATA,
  SHOW_DEFAULT_ALERT_LONG_COPY,
  STORE_SWATCH_DATA,
  ERROR_SEARCHING,
  RECEIVE_CLAIMS_DATA,
  RECEIVE_CLAIMS_ERROR,
  UPDATE_PROFANITY_FEATURE_BULLETS,
  UPDATE_SINGLE_EDIT_LONG_COPY,
  UPDATE_PROFANITY_LONG_COPY_API,
  PROFANITY_API_LOADER,
  SUBJECTIVE_CLAIMS_POPUP,
  AUTO_GEN_SINGLE_SOURCE,
  VIEW_RELATED_ITEMS_DATA,
  RESET_VIEW_RELATED_COPY,
  REQUEST_COPY_VC_DATA,
} from './actionType'
import { GENERATED_SUBMISSION_PERIRSCOPE } from '../bulkUpload/actionType'
import { find } from 'lodash'

const initState = {
  mvi: false,
  buildFilterContainerShown: false,
  newFilterContainerShown: false,
  copyDataContainerShown: false,
  selectedFilters: [],
  copyData: [],
  selectedCopyData: undefined,
  isFetching: false,
  selectedFilter: -1,
  filterValues: {
    copyWritingStatus: -1,
    departmentData: -1,
    divisions: [],
    tcins: [],
    routedTeams: -1,
    departmentClass: -1,
    noteOptions: -1,
  },
  defaultFilterValues: {
    copyWritingStatus: -1,
    departmentData: -1,
    divisions: [],
    tcins: [],
    routedTeams: -1,
    departmentClass: -1,
    noteOptions: -1,
  },
  defaultPageSize: 10,
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  selectedCopy: [],
  selectedItemData: [],
  selectedDepartmentData: [],
  isActionDrawerOpen: false,
  drawerAction: undefined,
  selectedNotesData: undefined,
  currentRouteTeam: undefined,
  isFetchingWriters: false,
  assignedWritersList: [],
  currentWriterAssigned: undefined,
  isAssigningCopy: false,
  plannerNotes: '',
  isLoading: false,
  successMessage: '',
  isEditCopyDataAvailable: false,
  isItemDataAvailable: false,
  countFilterValues: [
    {
      eventType: 'ReadyForCopy',
      count: undefined,
      isSelected: false,
      dataCalled: false,
    },
    {
      eventType: 'RoutedToGroup',
      count: undefined,
      isSelected: false,
      dataCalled: false,
    },
    {
      eventType: 'NewWriteRequest',
      count: undefined,
      isSelected: false,
      dataCalled: false,
    },
    {
      eventType: 'WritingStarted',
      count: undefined,
      isSelected: false,
      dataCalled: false,
    },
    {
      eventType: 'ReadyForQA',
      count: undefined,
      isSelected: false,
      dataCalled: false,
    },
    {
      eventType: 'Done',
      count: undefined,
      isSelected: false,
      dataCalled: false,
    },
    {
      eventType: 'WriteToAssociation',
      count: undefined,
      isSelected: false,
      dataCalled: false,
    },
    {
      eventType: 'SendToItemService',
      count: undefined,
      isSelected: false,
      dataCalled: false,
    },
  ],
  stickerValues: [
    {
      eventType: 'ReadyForCopy',
      count: undefined,
      isSelected: false,
    },
    {
      eventType: 'RoutedToGroup',
      count: undefined,
      isSelected: false,
    },
    {
      eventType: 'NewWriteRequest',
      count: undefined,
      isSelected: false,
    },
    {
      eventType: 'WritingStarted',
      count: undefined,
      isSelected: false,
    },
    {
      eventType: 'ReadyForQA',
      count: undefined,
      isSelected: false,
    },
    {
      eventType: 'Done',
      count: undefined,
      isSelected: false,
    },
    {
      eventType: 'WriteToAssociation',
      count: undefined,
      isSelected: false,
    },
    {
      eventType: 'SendToItemService',
      count: undefined,
      isSelected: false,
    },
  ],
  currentImagesSelected: undefined,
  currentSwatch: null,
  currentImage: null,
  editCopyData: {
    longCopy: '',
    isLongCopyEdit: false,
    featureBullets: [],
    isEdited: false,
    editedFeatureBullets: [],
    isfeatureBulletsEdit: false,
    isValid: true,
    isLongCopySingleEdit: false,
    ltwoDisclaimer: [],
  },
  enteredEvent: undefined,
  deleteData: {
    tcinList: [],
    confirmationDelete: false,
    selectDeleteType: 'COPY_AND_FEATURE_BULLETS',
    showSelectedDeleteType: false,
    editSection: false,
    suceesfullDeleted: false,
  },
  quickEditConfirm: false,
  historyIndex: 0,
  currentTabIndex: 0,
  buildFilterExpansionPanel: false,
  modalMetadata: [],
  modalMetadataFetching: false,
  sortField: 'TCIN',
  sortDirection: 'asc',
  alert: null,
  information: null,
  faq: null,
  showDefaultAlert: false,
  swatchData: [],
  errorSearching: false,
  // DO NOT CHANGE THE PAGENAME!
  pageName: 'LongCopySearchManage',
  claimsData: [],
  claimsError: null,
  profanityLoading: false,
  subjectiveClaimsPopUpData: false,
  singleSourceAutoGen: false,
  copyViewRelatedItems: [],
  viewRelatedItemCopyPageNumber: 0,
  viewRelatedItemTotalElements: 0,
  isFetchingCopyVc: false,
}

export default function longCopyReducer(state = initState, action = {}) {
  switch (action.type) {
    case VIEW_RELATED_ITEMS_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        copyViewRelatedItems: [
          ...state.copyViewRelatedItems,
          ...(payload?.copyViewRelatedItems ?? []),
        ],
        viewRelatedItemTotalElements: payload?.viewRelatedItemTotalElements,
        viewRelatedItemCopyPageNumber: payload?.viewRelatedItemCopyPageNumber,
      }
      return newState
    }
    case REQUEST_COPY_VC_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        isFetchingCopyVc: payload.isFetchingCopyVc,
      }
      return newState
    }
    case NEW_FILTER_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterContainerShown: payload,
      }
      return newState
    }
    case GENERATED_SUBMISSION_PERIRSCOPE: {
      const { payload } = action
      const newState = {
        ...state,
        editCopyData: {
          ...state.editCopyData,
          longCopy: payload.longCopy,
          featureBullets: payload.featureBullets,
          generated_long_copy: payload?.generated_long_copy,
          generated_feature_bullets: payload?.generated_feature_bullets,
        },
      }
      return newState
    }

    case AUTO_GEN_SINGLE_SOURCE: {
      const { payload } = action
      const newState = {
        ...state,
        singleSourceAutoGen: payload,
      }
      return newState
    }
    case RESET_VIEW_RELATED_COPY: {
      const newState = {
        ...state,
        copyViewRelatedItems: [],
        viewRelatedItemCopyPageNumber: 0,
        viewRelatedItemTotalElements: 0,
      }
      return newState
    }
    case REMOVE_FILTER_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        copyDataContainerShown: payload.copyDataContainerShown,
        selectedFilters: payload.selectedFilters,
      }
      return newState
    }
    case BUILD_FILTER_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        copyDataContainerShown: payload.copyDataContainerShown,
        selectedFilters: payload.selectedFilters,
        // Resetting the value after adding
        filterValues: initState.filterValues,
      }
      return newState
    }
    case REQUEST_COPY_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        copyData: payload.copyData,
        isFetching: payload.isFetching,
        selectedCopy: payload.selectedCopy,
      }
      return newState
    }
    case CLEAR_COPY_DATA_EVENT: {
      const newState = {
        ...state,
        copyData: [],
      }
      return newState
    }
    case LONG_COPY_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
        isLoading: payload.isFetching,
      }
      return newState
    }
    case SELECT_COPY_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedCopy: payload.selectedCopy,
      }
      return newState
    }
    case CHANGE_CURRENT_PAGE_LONG_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        currentPage: payload.currentPage,
      }
      return newState
    }
    case RECEIVE_COPY_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        copyData: payload.copyData,
        isFetching: payload.isFetching,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
      }
      return newState
    }
    case CHANGE_FILTER_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedFilter: payload.selectedFilter,
        filterValues: initState.filterValues,
      }
      return newState
    }
    case SELECT_FILTER_VALUE_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        filterValues: payload.filterValues,
      }
      return newState
    }
    case CHANGE_DEFAULT_PAGE_SIZE_LONG_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        defaultPageSize: payload.defaultPageSize,
      }
      return newState
    }
    case VIEW_COPY_DETAIL_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        plannerNotes: payload.plannerNotes ? payload.plannerNotes : '',
        selectedCopyData: payload.selectedCopyData,
        isEditCopyDataAvailable: payload.isEditCopyDataAvailable,
        mvi: payload.mvi,
        editCopyData: {
          ...state.editCopyData,
          longCopy: payload.longCopy ? payload.longCopy : '',
          featureBullets: payload.featureBullets ? payload.featureBullets : [],
          isEdited: payload.isEdited,
          editedFeatureBullets: payload.editedFeatureBullets,
          isValid: payload.isValid,
          ltwoDisclaimer: payload.ltwoDisclaimer,
          tcin: payload?.selectedCopyData?.tcin,
          generated_feature_bullets: payload?.generated_feature_bullets,
          generated_long_copy: payload?.generated_long_copy,
        },
      }
      return newState
    }
    case UPDATE_PROFANITY_FEATURE_BULLETS: {
      const { payload } = action
      let initialDataHoldBullets = {
        generated_feature_bullets: {
          ...state.editCopyData.generated_feature_bullets,
          modified: true,
        },
      }
      let checkForAiGenBullets = state.editCopyData.generated_feature_bullets
        ? initialDataHoldBullets
        : null
      const newState = {
        ...state,
        editCopyData: {
          ...state.editCopyData,
          featureBullets: payload.bullets,
          isValid: payload.valid,
          ltwoDisclaimer: payload.ltwoDisclaimer,
          longCopy: payload.longCopy,
          generated_feature_bullets:
            checkForAiGenBullets?.generated_feature_bullets,
          isEdited: true,
        },
      }
      return newState
    }
    case UPDATE_PROFANITY_LONG_COPY_API: {
      const { payload } = action
      let initialDataHoldLongCopy = {
        generated_long_copy: {
          ...state.editCopyData.generated_long_copy,
          modified: true,
        },
      }
      let checkForAiGenLongCopy = state.editCopyData.generated_long_copy
        ? initialDataHoldLongCopy
        : null
      const newState = {
        ...state,
        editCopyData: {
          ...state.editCopyData,
          longCopy: payload.longCopy,
          isLongCopySingleEdit: false,
          isValid: payload.valid,
          ltwoDisclaimer: payload.ltwoDisclaimer,
          featureBullets: payload.bullets,
          generated_long_copy: checkForAiGenLongCopy?.generated_long_copy,
          isEdited: true,
        },
      }
      return newState
    }
    case UPDATE_SINGLE_EDIT_LONG_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        editCopyData: {
          ...state.editCopyData,
          isLongCopySingleEdit: payload,
        },
      }
      return newState
    }
    case REQUEST_DRAFT_COPY_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedCopyData: {
          ...state.selectedCopyData,
          draft_copy: {
            long_copy: payload.long_copy,
            feature_bullets: payload.feature_bullets,
            version: payload.version,
            created_by: payload.created_by,
          },
        },
      }
      return newState
    }
    case REQUEST_FIRST_DRAFT_COPY_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedCopyData: {
          ...state.selectedCopyData,
          current_event: {
            event_type: payload.event_type,
          },
          last_updated_by: payload.last_updated_by,
        },
      }
      return newState
    }
    case REQUEST_SAVE_COPY_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case REQUEST_PUBLISH_COPY_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case REQUEST_ITEM_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case RECEIVE_ITEM_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
        selectedItemData: payload.selectedItemData,
        currentImagesSelected: payload.currentImagesSelected,
        isItemDataAvailable: payload.isItemDataAvailable,
        currentSwatch: initState.currentSwatch,
        currentImage: payload.currentImage,
        swatchData: initState.swatchData,
      }
      return newState
    }
    case REQUEST_DEPARTMENT: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case RECIEVE_DEPARTMENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedDepartmentData: payload.selectedDepartmentData,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case TOGGLE_ACTION_DRAWER: {
      const { payload } = action
      const newState = {
        ...state,
        isActionDrawerOpen: payload.isActionDrawerOpen,
        drawerAction: payload.drawerAction,
      }
      return newState
    }
    case SET_ROUTE_TEAM: {
      const { payload } = action
      const newState = {
        ...state,
        currentRouteTeam: payload.currentRouteTeam,
      }
      return newState
    }
    case SET_ASSIGNED_WRITER: {
      const { payload } = action
      const newState = {
        ...state,
        currentWriterAssigned: payload.currentWriterAssigned,
      }
      return newState
    }
    case SET_IS_FETCHING_WRITERS: {
      const { payload } = action
      const newState = {
        ...state,
        isFetchingWriters: payload.isFetchingWriters,
      }
      return newState
    }
    case SET_ASSIGNED_WRITERS_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        assignedWritersList: payload.assignedWritersList,
      }
      return newState
    }
    case ADD_PLANNER_NOTES: {
      const { payload } = action
      const newState = {
        ...state,
        plannerNotes: payload.plannerNotes,
      }
      return newState
    }
    case REQUEST_SET_ROUTE_TEAM: {
      const { payload } = action
      const newState = {
        ...state,
        isLoading: payload.isFetching,
      }
      return newState
    }
    case SUCCESS_SET_ROUTE_TEAM: {
      const { payload } = action
      const newState = {
        ...state,
        isLoading: payload.isFetching,
        successMessage: payload.successMessage,
        selectedCopy: [],
      }
      return newState
    }
    case REQUEST_ASSIGN_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        isAssigningCopy: payload.isAssigningCopy,
      }
      return newState
    }
    case SUCCESS_ASSIGN_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        isLoading: payload.isFetching,
        selectedCopy: [],
        currentWriterAssigned: undefined,
        successMessage: payload.successMessage,
      }
      return newState
    }
    case REQUEST_SAVE_PLANNER_NOTES: {
      const { payload } = action
      const newState = {
        ...state,
        isLoading: payload.isFetching,
      }
      return newState
    }
    case SUCCESS_SAVE_PLANNER_NOTES: {
      const { payload } = action
      const newState = {
        ...state,
        isLoading: payload.isFetching,
        successMessage: payload.successMessage,
        selectedCopy: [],
      }
      return newState
    }
    case SUCCESS_SAVE_PLANNER_NOTES_EDIT_PAGE: {
      const { payload } = action
      const newState = {
        ...state,
        plannerNotes: payload.PlannerNotes,
        isLoading: payload.isFetching,
      }
      return newState
    }
    case REQUEST_COPY_DETAIL_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        isEditCopyDataAvailable: payload.isEditCopyDataAvailable,
        isItemDataAvailable: payload.isItemDataAvailable,
      }
      return newState
    }
    case UPDATE_COPY_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        copyData: state.copyData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.id === payload.id) {
            var item = {
              id: temp.id,
              tcin: temp.tcin,
              tcin_info: temp.tcin_info,
              copy: temp.copy,
              assigned_to: temp.assigned_to,
              current_event: {
                event_type: payload.eventType,
                status: temp.current_event.status,
                start_time: temp.current_event.start_time,
                end_time: temp.current_event.end_time,
                error_message: temp.current_event.error_message,
                context: temp.current_event.context,
                retry_time: temp.current_event.retry_time,
                execution_count: temp.current_event.execution_count,
              },
              created_by: temp.created_by,
              planner_note: temp.planner_note,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case UPDATE_SELECTED: {
      const { payload } = action
      const newState = {
        ...state,
        countFilterValues: state.countFilterValues.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.eventType === payload.eventType) {
            var item = {
              eventType: temp.eventType,
              count: temp.count,
              isSelected: payload.isSelected,
              dataCalled: temp.dataCalled,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case UPDATE_SELECTED_STICKER: {
      const { payload } = action
      const newState = {
        ...state,
        stickerValues: state.stickerValues.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.eventType === payload.eventType) {
            var item = {
              eventType: temp.eventType,
              count: temp.count,
              isSelected: payload.isSelected,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case UPDATE_CURRENT_IMAGES: {
      const { payload } = action
      const newState = {
        ...state,
        currentImagesSelected: payload.currentImagesSelected,
        currentSwatch: payload.currentSwatch,
        currentImage: payload.currentImage,
      }
      return newState
    }
    case UPDATE_CURRENT_IMAGE_SELECTED: {
      const { payload } = action
      const newState = {
        ...state,
        currentImage: payload.currentImage,
      }
      return newState
    }
    case UPDATE_EDIT_FEATURE_BULLET_STATE: {
      const { payload } = action
      const newState = {
        ...state,
        editCopyData: {
          ...state.editCopyData,
          isfeatureBulletsEdit: payload.isfeatureBulletsEdit,
        },
      }
      return newState
    }
    case UPDATE_EDIT_FEATURE_BULLET: {
      const { payload } = action
      const newState = {
        ...state,
        editCopyData: {
          ...state.editCopyData,
          isEdited: payload.isEdited,
          editedFeatureBullets: payload.editedFeatureBullets,
        },
      }
      return newState
    }
    case UPDATE_EDIT_LONG_COPY_STATE: {
      const { payload } = action
      const newState = {
        ...state,
        editCopyData: {
          ...state.editCopyData,
          isLongCopyEdit: payload.isLongCopyEdit,
        },
      }
      return newState
    }
    case UPDATE_EDIT_LONG_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        editCopyData: {
          ...state.editCopyData,
          longCopy: payload.longCopy,
        },
      }
      return newState
    }
    case ENTERED_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        enteredEvent: payload.enteredEvent,
      }
      return newState
    }
    case DOWNLOAD_START_LONG_COPY: {
      const newState = {
        ...state,
        isLoading: true,
      }
      return newState
    }
    case DOWNLOAD_FINISH_LONG_COPY: {
      const newState = {
        ...state,
        isLoading: false,
      }
      return newState
    }
    case STICKER_DATA_CALLED: {
      const { payload } = action
      const newState = {
        ...state,
        stickerValues: state.stickerValues.map((data) => {
          var temp = Object.assign({}, data)
          var item = {
            eventType: temp.eventType,
            count: payload.count,
            isSelected: temp.isSelected,
          }
          return item
        }),
      }
      return newState
    }
    case STICKER_DATA_SUCCESS: {
      const { payload } = action
      const newState = {
        ...state,
        stickerValues: state.stickerValues.map((data) => {
          var temp = Object.assign({}, data)
          var item = {
            eventType: temp.eventType,
            count: find(payload.dataCount, function (item) {
              return Object.keys(item)[0] === temp.eventType
            })
              ? find(payload.dataCount, function (item) {
                  return Object.keys(item)[0] === temp.eventType
                })[temp.eventType]
              : 0,
            isSelected: temp.isSelected,
          }
          return item
        }),
      }
      return newState
    }
    case CLEAR_SELECTED_STICKER: {
      const { payload } = action
      const newState = {
        ...state,
        stickerValues: state.stickerValues.map((data) => {
          var temp = Object.assign({}, data)
          var item = {
            eventType: temp.eventType,
            count: temp.count,
            isSelected: payload.isSelected,
          }
          return item
        }),
      }
      return newState
    }
    case DELETE_COPY_BULLET_CLICKED: {
      const { payload } = action
      const newState = {
        ...state,
        deleteData: {
          ...state.deleteData,
          tcinList: payload.tcinList,
          showSelectedDeleteType: payload.showSelectedDeleteType,
          editSection: payload.editSection,
        },
      }
      return newState
    }
    case CANCEL_COPY_BULLET_CLICKED: {
      const { payload } = action
      const newState = {
        ...state,
        deleteData: {
          ...state.deleteData,
          tcinList: payload.tcinList,
          showSelectedDeleteType: payload.showSelectedDeleteType,
          confirmationDelete: payload.confirmationDelete,
          selectDeleteType: payload.selectDeleteType,
          editSection: payload.editSection,
          suceesfullDeleted: payload.suceesfullDeleted,
        },
      }
      return newState
    }
    case DELETE_OPTION_VALUE: {
      const { payload } = action
      const newState = {
        ...state,
        deleteData: {
          ...state.deleteData,
          selectDeleteType: payload.selectDeleteType,
        },
      }
      return newState
    }
    case CONFIRMATION_DELETE: {
      const { payload } = action
      const newState = {
        ...state,
        deleteData: {
          ...state.deleteData,
          confirmationDelete: payload.confirmationDelete,
          selectDeleteType: payload.selectDeleteType,
          showSelectedDeleteType: payload.showSelectedDeleteType,
        },
      }
      return newState
    }
    case DELETE_BACT_TO_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        deleteData: {
          ...state.deleteData,
          suceesfullDeleted: payload.suceesfullDeleted,
        },
      }
      return newState
    }
    case CANCEL_QUICK_PUBLISH: {
      const { payload } = action
      const newState = {
        ...state,
        quickEditConfirm: payload.quickEditConfirm,
      }
      return newState
    }
    case HISTORY_INDEX_UPDATE: {
      const { payload } = action
      const newState = {
        ...state,
        historyIndex: payload.historyIndex,
      }
      return newState
    }
    case TAB_INDEX_UPDATE: {
      const { payload } = action
      const newState = {
        ...state,
        currentTabIndex: payload.currentTabIndex,
      }
      return newState
    }
    case BUILD_FILTER_EXPAND: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterExpansionPanel: payload.buildFilterExpansionPanel,
      }
      return newState
    }
    case REQUEST_MODAL_META_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        modalMetadataFetching: payload.modalMetadataFetching,
      }
      return newState
    }
    case RECIEVE_MODAL_META_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        modalMetadataFetching: payload.modalMetadataFetching,
        modalMetadata: payload.modalMetadata,
      }
      return newState
    }
    case SET_SORT_FIELD: {
      const { payload } = action
      const newState = {
        ...state,
        sortField: payload,
      }
      return newState
    }
    case SET_SORT_DIRECTION: {
      const { payload } = action
      const newState = {
        ...state,
        sortDirection: payload,
      }
      return newState
    }
    case STORE_LONG_COPY_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_LONG_COPY: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case SUBJECTIVE_CLAIMS_POPUP: {
      const { payload } = action
      const newState = {
        ...state,
        subjectiveClaimsPopUpData: payload,
      }
      return newState
    }
    case STORE_SWATCH_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
        swatchData: payload.swatchData,
        currentImagesSelected: payload.currentImagesSelected,
        currentSwatch: payload.currentSwatch,
        currentImage: payload.currentImage,
      }
      return newState
    }
    case ERROR_SEARCHING: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    case RECEIVE_CLAIMS_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        claimsData: payload.claims,
      }
      return newState
    }
    case RECEIVE_CLAIMS_ERROR: {
      const { payload } = action
      const newState = {
        ...state,
        claimsError: payload.error,
      }
      return newState
    }
    case PROFANITY_API_LOADER: {
      const { payload } = action
      const newState = {
        ...state,
        profanityLoading: payload,
      }
      return newState
    }
    default:
      return state
  }
}
