import React from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
} from '@material-ui/core'
import { isContentPlanner, isCepAdmin, isCopyWriter } from './CommonUtils'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../../theme'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

function generateDynamicColumnsByRole(userInfo) {
  if (isCepAdmin(userInfo)) {
    return (
      <React.Fragment>
        <TableCell padding="none">Writing Team</TableCell>
        <TableCell padding="none">Assigned User</TableCell>
        <TableCell padding="none">Writing Started By</TableCell>
      </React.Fragment>
    )
  } else if (isContentPlanner(userInfo)) {
    return (
      <React.Fragment>
        <TableCell padding="none">Writing Team</TableCell>
      </React.Fragment>
    )
  } else if (isCopyWriter(userInfo)) {
    return (
      <React.Fragment>
        <TableCell padding="none">Assigned User</TableCell>
        <TableCell padding="none">Writing Started By</TableCell>
      </React.Fragment>
    )
  }
}

function CopyDataContainerHead(props) {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    auth,
    classes,
    selectedFilters,
    sortDirection,
    sortField,
    requestCopyData,
    setSortDirection,
    setSortField,
    copyDataLength,
    savePaginationDataEvent,
    pageName,
  } = props

  let userId = auth.email ? auth.email : auth.lanId

  function handleSetSortDirection(sortDirection) {
    if (sortDirection === 'asc') {
      return 'desc'
    }
    if (sortDirection === 'desc') {
      return 'asc'
    }
  }

  function sortColumn(
    fieldToSort,
    selectedFilters,
    rowCount,
    userId,
    sortDirection,
    pageName
  ) {
    setSortDirection(handleSetSortDirection(sortDirection))
    setSortField(fieldToSort)
    savePaginationDataEvent({
      pageSize: rowCount,
      sortOrder: sortDirection,
      sortField: fieldToSort,
      pageName: pageName,
    })
    requestCopyData(
      selectedFilters,
      0,
      rowCount,
      userId,
      handleSetSortDirection(sortDirection),
      fieldToSort
    )
  }
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={
              numSelected > 0 &&
              (numSelected < rowCount || numSelected < copyDataLength)
            }
            checked={
              copyDataLength > 0 &&
              (numSelected === rowCount || numSelected === copyDataLength)
            }
            onChange={onSelectAllClick}
            color="primary"
          />
        </TableCell>
        <TableCell padding="none">Open</TableCell>
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'STATUS',
                  selectedFilters,
                  rowCount,
                  userId,
                  sortDirection,
                  pageName
                )
              }}
            >
              Status
              {sortField === 'STATUS' && sortDirection === 'desc' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'STATUS' && sortDirection === 'asc' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'TCIN',
                  selectedFilters,
                  rowCount,
                  userId,
                  sortDirection,
                  pageName
                )
              }}
            >
              TCIN
              {sortField === 'TCIN' && sortDirection === 'desc' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'TCIN' && sortDirection === 'asc' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'PRODUCT_TITLE',
                  selectedFilters,
                  rowCount,
                  userId,
                  sortDirection,
                  pageName
                )
              }}
            >
              Product Title
              {sortField === 'PRODUCT_TITLE' && sortDirection === 'desc' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'PRODUCT_TITLE' && sortDirection === 'asc' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'TCIN_DIVISION',
                  selectedFilters,
                  rowCount,
                  userId,
                  sortDirection,
                  pageName
                )
              }}
            >
              Division
              {sortField === 'TCIN_DIVISION' && sortDirection === 'desc' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'TCIN_DIVISION' && sortDirection === 'asc' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'TCIN_BRAND',
                  selectedFilters,
                  rowCount,
                  userId,
                  sortDirection,
                  pageName
                )
              }}
            >
              Brand
              {sortField === 'TCIN_BRAND' && sortDirection === 'desc' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'TCIN_BRAND' && sortDirection === 'asc' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'TCIN_RELATION_TYPE',
                  selectedFilters,
                  rowCount,
                  userId,
                  sortDirection,
                  pageName
                )
              }}
            >
              Rel. Type
              {sortField === 'TCIN_RELATION_TYPE' &&
                sortDirection === 'desc' && <KeyboardArrowDownIcon />}
              {sortField === 'TCIN_RELATION_TYPE' &&
                sortDirection === 'asc' && <KeyboardArrowUpIcon />}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell padding="none">VAP/VC Rel type Count</TableCell>
        {generateDynamicColumnsByRole(auth)}
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'TCIN_LAUNCH_DATE',
                  selectedFilters,
                  rowCount,
                  userId,
                  sortDirection,
                  pageName
                )
              }}
            >
              Launch Date
              {sortField === 'TCIN_LAUNCH_DATE' && sortDirection === 'desc' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'TCIN_LAUNCH_DATE' && sortDirection === 'asc' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

// @ts-ignore
export default withStyles(styles)(CopyDataContainerHead)
