import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Tooltip,
} from '@material-ui/core'
import MDReactComponent from 'markdown-react-js'
import { some } from 'lodash'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import {
  RESTRICTED_PROFANE_WARNING,
  INAPROPRIATE_PROFANE_WARNING,
} from '../../../../Shared/Constants'
// import AddIcon from '@material-ui/icons/Add'

function HeaderSection(props) {
  const {
    classes,
    clearData,
    selectedDataLength,
    publishData,
    uploadDataLength,
    handleDownload,
    isCopyEdited,
    checkUpload,
    showBulletCountDialog,
    cancelPublish,
    bulletCountMessage,
    uploadData,
  } = props

  return (
    <div className={classes.mainWrapperCopy}>
      <div>
        <div className={classes.secondaryHeading}>
          Review Content and Publish
        </div>
        <div className={classes.secondaryHeadingPartTwo}>
          {' '}
          <div className={classes.secondaryHeadingInner}>
            <Tooltip title={RESTRICTED_PROFANE_WARNING}>
              <div>
                <span className={classes.loneColour} /> Restricted word
              </div>
            </Tooltip>
          </div>
          <div className={classes.secondaryHeadingInner}>
            <Tooltip title={INAPROPRIATE_PROFANE_WARNING}>
              <div>
                <span className={classes.ltwoColour} /> Inappropriate word
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={classes.mainWrapperCopy}>
        <div className={classes.selectedTcinCount}>
          Selected TCINs ({selectedDataLength})
        </div>
        <div className={classes.groupCTAWrapper}>
          <div className={classes.singleCTA}>
            {' '}
            <Button
              //className={classes.secondaryButton}
              onClick={clearData}
              //variant="outlined"
              color="primary"
              startIcon={<DeleteIcon />}
            >
              DELETE ALL
            </Button>
          </div>
          <div className={classes.singleCTA}>
            {' '}
            {uploadDataLength > 0 && (
              <Grid item>
                <div className={classes.itemsCountContainer}>
                  {/* <span>{uploadDataLength} items</span> */}
                  {!isCopyEdited && (
                    <Button
                      // className={`${classes.secondaryButton} `}
                      onClick={() => handleDownload()}
                      //variant="outlined"
                      color="primary"
                      startIcon={<GetAppIcon />}
                    >
                      DOWNLOAD ALL
                    </Button>
                  )}
                </div>
              </Grid>
            )}
          </div>

          <div className={classes.singleCTA}>
            {' '}
            {/* <Button
              disabled={true}
              onClick={clearData}
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
            >
              AUTO GENERATE CONTENT
            </Button> */}
          </div>

          <div className={classes.singleCTA}>
            {' '}
            <Button
              disabled={
                selectedDataLength <= 0 ||
                some(
                  uploadData,
                  (data) =>
                    data.isLongCopyEditable || data.isFeatureBulletsEditable
                )
              }
              className={classes.primaryButton}
              onClick={checkUpload}
              variant="contained"
              color="primary"
            >
              Publish Content
            </Button>
          </div>
        </div>
      </div>

      {/* <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Button
                className={classes.secondaryButton}
                onClick={() => downloadBlankTemplate()}
                variant="outlined"
                color="primary"
              >
                Get Template
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item></Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          ></Grid>
        </Grid>
      </Grid> */}
      <Dialog
        open={showBulletCountDialog}
        onClose={cancelPublish}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {bulletCountMessage
            ? bulletCountMessage.title
            : 'Highlights / Feature Bullet Count'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {bulletCountMessage ? (
              <MDReactComponent text={bulletCountMessage.body} />
            ) : (
              <React.Fragment>
                <div>
                  You have at least one item that does not have the recommended
                  number of bullets.
                </div>
                <p>Target recommends three bullet points as a minimum.</p>
                <p>Press cancel to go back or publish to continue.</p>
              </React.Fragment>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelPublish} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={publishData} variant="contained" color="primary">
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default withStyles(styles)(HeaderSection)
