import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ItemSearch from './ItemSearch'
import {
  updateItemSearch,
  changeSearchItem,
  requestLongCopyDataByTcins,
} from '../../../../../store/bulkUpload/actionCreator'
import { splitTcins } from '../../../../Shared/SharedUtils'

class ItemSearchContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  handleSearchForLongCopyAndFeatureBullets = () => {
    let enteredTcins = this.props.searchedItems
    let searchTcins = this.props.inputTcins.concat(enteredTcins)
    let userId = this.props.auth.email
      ? this.props.auth.email
      : this.props.auth.lanId
    this.props.requestLongCopyDataByTcins(searchTcins, userId.toLowerCase())
    this.props.updateItemSearch(searchTcins)
  }

  handleAddTcin = (selectedTcins) => {
    let chips = []
    splitTcins(selectedTcins).forEach((i) => {
      chips.push(i)
    })
    if (
      this.props.searchedItems !== undefined &&
      this.props.searchedItems.length > 0
    ) {
      this.props.searchedItems.forEach((item) => {
        chips.push(item)
      })
    }
    this.props.changeSearchItem(chips)
  }

  handleDeleteTcin = (deletedTcin) => {
    let chips = []
    this.props.searchedItems.forEach((item) => {
      if (item !== deletedTcin) {
        chips.push(item)
      }
    })
    this.props.changeSearchItem(chips)
  }
  render() {
    const { searchedItems } = this.props
    return (
      <div>
        <ItemSearch
          handleSearchForLongCopyAndFeatureBullets={
            this.handleSearchForLongCopyAndFeatureBullets
          }
          searchedItems={searchedItems}
          handleAddTcin={this.handleAddTcin}
          handleDeleteTcin={this.handleDeleteTcin}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateItemSearch,
      requestLongCopyDataByTcins,
      changeSearchItem,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, bulkUpload } = state
  const { inputTcins, searchedItems } = bulkUpload
  return {
    auth,
    inputTcins,
    searchedItems,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemSearchContainer)
