import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AutoDecisionDataContainerToolbar from './AutoDecisionDataContainerToolbar'
import styles from '../../theme'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core'
import EditLongCopyContainer from './EditLongCopy/EditLongCopyContainer'
import EditFeatureBulletsContainer from './EditFeatureBullets/EditFeatureBulletsContainer'
import { TableHead } from '@material-ui/core'
import {
  RESTRICTED_PROFANE_WARNING,
  INAPROPRIATE_PROFANE_WARNING,
} from '../../../Shared/Constants'
import { lwoDiscFunc, loneDiscFunc } from '../../../Shared/SharedUtils'
import FlagIcon from '@material-ui/icons/Flag'

function AutoDecisionData(props) {
  const {
    selectedCopy = [],
    classes = {},
    data = [],
    emptyRows = 0,
    isSelected = false,
    auth = {},
    downloadSelectedTcinsCopy = () => {},
    deleteBulletAndCopy = () => {},
    handleSelectCopy,
    submitPassivePathDecisons,
    selectedFilters,
  } = props

  const checkSelection = (data) => {
    const allChecked = data?.every(
      //   (item) => item?.long_copy?.checked && item?.feature_bullets?.checked

      (item) =>
        (item.long_copy.value ? item?.long_copy?.checked : true) &&
        (item.feature_bullets.value ? item?.feature_bullets?.checked : true)
    )
    const anyUnchecked = data?.some(
      (item) => !item?.long_copy?.checked || !item?.feature_bullets?.checked
    )

    if (allChecked) {
      return 'select all'
    } else if (anyUnchecked) {
      return 'intermittent'
    } else {
      return false
    }
  }

  const handleRowCheckboxChange = (tcin, value) => {
    const updatedData = data.map((item) =>
      item.tcin === tcin
        ? {
            ...item,
            long_copy: {
              ...item.long_copy,
              checked: item.long_copy.value ? value : false,
            },
            feature_bullets: {
              ...item.feature_bullets,
              checked: item.feature_bullets.value ? value : false,
            },
          }
        : item
    )

    handleSelectCopy({
      selectedCopy: updatedData,
    })
  }

  const handleColumnCheckboxChange = (tcin, column) => {
    const updatedData = data.map((item) =>
      item.tcin === tcin
        ? {
            ...item,
            [column]: { ...item[column], checked: !item[column].checked },
          }
        : item
    )

    handleSelectCopy({
      selectedCopy: updatedData,
    })
  }

  const handleHeaderCheckboxChange = (event) => {
    const allChecked = data.map((item) => ({
      ...item,
      long_copy: {
        ...item.long_copy,
        checked: item.long_copy.value ? event.target.checked : false,
      },
      feature_bullets: {
        ...item.feature_bullets,
        checked: item.feature_bullets.value ? event.target.checked : false,
      },
    }))
    handleSelectCopy({
      selectedCopy: allChecked,
    })
  }

  function renderProfaneWarning(profaneCategroy, categoryEditable) {
    return (
      <>
        {lwoDiscFunc(profaneCategroy) && (
          <span>
            {categoryEditable ? (
              ''
            ) : (
              <div className={classes.profaneWordWarnings}>
                <FlagIcon
                  style={{
                    color: 'white',
                    verticalAlign: 'middle',
                  }}
                />
                {loneDiscFunc(profaneCategroy)
                  ? ''
                  : INAPROPRIATE_PROFANE_WARNING}
              </div>
            )}
          </span>
        )}
        {loneDiscFunc(profaneCategroy) && (
          <span>
            {categoryEditable ? (
              ''
            ) : (
              <div className={classes.profaneWordWarnings}>
                <FlagIcon
                  style={{
                    color: 'white',
                    verticalAlign: 'middle',
                  }}
                />
                {RESTRICTED_PROFANE_WARNING}
              </div>
            )}
          </span>
        )}
      </>
    )
  }

  return (
    <div>
      <Paper className={classes.copyDataroot}>
        {data.length > 0 && (
          <React.Fragment>
            <div className={classes.tableWrapperInner}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          checkSelection(data) === 'select all' ? true : false
                        }
                        onChange={handleHeaderCheckboxChange}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell padding="default">TCIN</TableCell>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none">Long Copy</TableCell>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none">Feature Bullets</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.map((item) => {
                    const findIsSelected = isSelected(item)
                    const nullLongCopy = item?.long_copy?.value
                      ? item?.long_copy?.checked
                      : true
                    const nullFeatureBullets = item.feature_bullets.value
                      ? item?.feature_bullets?.checked
                      : true
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={findIsSelected}
                        tabIndex={-1}
                        key={item.id}
                        selected={nullFeatureBullets && nullLongCopy}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={nullFeatureBullets && nullLongCopy}
                            onChange={(event) =>
                              handleRowCheckboxChange(
                                item?.tcin,
                                event.target.checked
                              )
                            }
                          />
                        </TableCell>

                        <TableCell padding="none">{item.tcin}</TableCell>

                        <TableCell padding="checkbox">
                          {item.long_copy.value ? (
                            <Checkbox
                              color="primary"
                              checked={item?.long_copy?.checked}
                              onChange={() =>
                                handleColumnCheckboxChange(
                                  item?.tcin,
                                  'long_copy'
                                )
                              }
                            />
                          ) : null}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.maxWidth350}
                        >
                          {item.long_copy.value ? (
                            <>
                              <EditLongCopyContainer
                                data={{
                                  longCopy: item.long_copy?.value,
                                  tcin: item?.tcin,
                                  isLongCopyEditable: item?.isLongCopyEditable,
                                }}
                              />
                              <>
                                {renderProfaneWarning(
                                  item.long_copy?.profane_category,
                                  item?.isLongCopyEditable
                                )}
                              </>
                            </>
                          ) : null}
                        </TableCell>

                        <TableCell padding="checkbox">
                          {item.feature_bullets.value ? (
                            <Checkbox
                              color="primary"
                              checked={item?.feature_bullets?.checked}
                              onChange={() =>
                                handleColumnCheckboxChange(
                                  item?.tcin,
                                  'feature_bullets'
                                )
                              }
                            />
                          ) : null}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.maxWidth350}
                        >
                          {item.feature_bullets.value ? (
                            <>
                              <EditFeatureBulletsContainer
                                data={{
                                  featureBullets: item.feature_bullets?.value,
                                  tcin: item?.tcin,
                                  isFeatureBulletsEditable:
                                    item?.isFeatureBulletsEditable,
                                }}
                              />
                              <>
                                {renderProfaneWarning(
                                  item.feature_bullets?.profane_category,
                                  item?.isFeatureBulletsEditable
                                )}
                              </>
                            </>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={11} />
                    </TableRow>
                  )}
                  <TableRow className={classes.height49}>
                    <TableCell colSpan={11} />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <AutoDecisionDataContainerToolbar
              selectedCopy={selectedCopy}
              downloadSelectedTcinsCopy={downloadSelectedTcinsCopy}
              deleteBulletAndCopy={deleteBulletAndCopy}
              auth={auth}
              submitPassivePathDecisons={submitPassivePathDecisons}
              selectedFilters={selectedFilters}
            />
          </React.Fragment>
        )}
      </Paper>
      {!data.length && (
        <div className={classes.noResult}>
          No results matched your filter criteria
        </div>
      )}
    </div>
  )
}
export default withStyles(styles)(AutoDecisionData)
