export const NEW_FILTER_EVENT_PASSIVE_PATH = 'NEW_FILTER_EVENT_PASSIVE_PATH'
export const BUILD_FILTER_EXPAND_PASSIVE_PATH =
  'BUILD_FILTER_EXPAND_PASSIVE_PATH'
export const REMOVE_FILTER_EVENT_PASSIVE_PATH =
  'REMOVE_FILTER_EVENT_PASSIVE_PATH'
export const CHANGE_FILTER_EVENT_PASSIVE_PATH =
  'CHANGE_FILTER_EVENT_PASSIVE_PATH'
export const SELECT_FILTER_VALUE_EVENT_PASSIVE_PATH =
  'SELECT_FILTER_VALUE_EVENT_PASSIVE_PATH'
export const BUILD_FILTER_EVENT_PASSIVE_PATH = 'BUILD_FILTER_EVENT_PASSIVE_PATH'
export const CLEAR_PASSIVE_PATH_DATA_EVENT = 'CLEAR_PASSIVE_PATH_DATA_EVENT'
export const ERROR_SEARCHING_PASSIVE_PATH = 'ERROR_SEARCHING_PASSIVE_PATH'
export const REQUEST_PASSIVE_PATH_DATA = 'REQUEST_PASSIVE_PATH_DATA'
export const RECEIVE_PASSIVE_PATH_DATA = 'RECEIVE_PASSIVE_PATH_DATA'
export const PASSIVE_PATH_RESET_FETCH = 'PASSIVE_PATH_RESET_FETCH'
export const CHANGE_CURRENT_PAGE_PASSIVE_PATH =
  'CHANGE_CURRENT_PAGE_PASSIVE_PATH'
export const SELECT_PASSIVE_PATH_EVENT = 'SELECT_PASSIVE_PATH_EVENT'
export const UPDATE_FEATURE_BULLETS_EDIT_STATE_PASSIVE_PATH =
  'UPDATE_FEATURE_BULLETS_EDIT_STATE_PASSIVE_PATH'
export const PROFANITY_API_LOADER_PASSIVE_PATH =
  'PROFANITY_API_LOADER_PASSIVE_PATH'
export const UPDATE_FEATURE_BULLETS_PASSIVE_PATH =
  'UPDATE_FEATURE_BULLETS_PASSIVE_PATH'
export const UPDATE_LONG_COPY_EDIT_STATE_PASSIVE_PATH =
  'UPDATE_LONG_COPY_EDIT_STATE_PASSIVE_PATH'
export const UPDATE_LONG_COPY_PASSIVE_PATH = 'UPDATE_LONG_COPY_PASSIVE_PATH'
