/* eslint-disable array-callback-return */
import {
  NEW_FILTER_EVENT,
  BUILD_FILTER_EVENT,
  REQUEST_COPY_DATA,
  RECEIVE_COPY_DATA,
  CHANGE_FILTER_EVENT,
  SELECT_FILTER_VALUE_EVENT,
  CHANGE_DEFAULT_PAGE_SIZE_LONG_COPY,
  CHANGE_CURRENT_PAGE_LONG_COPY,
  SELECT_COPY_EVENT,
  REMOVE_FILTER_EVENT,
  LONG_COPY_RESET_FETCH,
  CLEAR_COPY_DATA_EVENT,
  VIEW_COPY_DETAIL_EVENT,
  REQUEST_DRAFT_COPY_EVENT,
  REQUEST_SAVE_COPY_EVENT,
  REQUEST_PUBLISH_COPY_EVENT,
  REQUEST_ITEM_DATA,
  RECEIVE_ITEM_DATA,
  REQUEST_DEPARTMENT,
  RECIEVE_DEPARTMENT,
  TOGGLE_ACTION_DRAWER,
  SET_ROUTE_TEAM,
  SET_IS_FETCHING_WRITERS,
  SET_ASSIGNED_WRITERS_LIST,
  SET_ASSIGNED_WRITER,
  REQUEST_ASSIGN_COPY,
  SUCCESS_ASSIGN_COPY,
  ADD_PLANNER_NOTES,
  REQUEST_SET_ROUTE_TEAM,
  SUCCESS_SET_ROUTE_TEAM,
  REQUEST_SAVE_PLANNER_NOTES,
  SUCCESS_SAVE_PLANNER_NOTES,
  SUCCESS_SAVE_PLANNER_NOTES_EDIT_PAGE,
  REQUEST_FIRST_DRAFT_COPY_EVENT,
  REQUEST_COPY_DETAIL_EVENT,
  UPDATE_COPY_LIST,
  UPDATE_SELECTED,
  UPDATE_CURRENT_IMAGES,
  UPDATE_CURRENT_IMAGE_SELECTED,
  ENTERED_EVENT,
  DOWNLOAD_START_LONG_COPY,
  DOWNLOAD_FINISH_LONG_COPY,
  STICKER_DATA_CALLED,
  STICKER_DATA_SUCCESS,
  UPDATE_SELECTED_STICKER,
  CLEAR_SELECTED_STICKER,
  DELETE_COPY_BULLET_CLICKED,
  CANCEL_COPY_BULLET_CLICKED,
  DELETE_OPTION_VALUE,
  CONFIRMATION_DELETE,
  DELETE_BACT_TO_LIST,
  CANCEL_QUICK_PUBLISH,
  BUILD_FILTER_EXPAND,
  SET_SORT_FIELD,
  SET_SORT_DIRECTION,
  SHOW_DEFAULT_ALERT_LONG_COPY,
  STORE_LONG_COPY_STRAPI_DATA,
  STORE_SWATCH_DATA,
  ERROR_SEARCHING,
  RECEIVE_CLAIMS_DATA,
  RECEIVE_CLAIMS_ERROR,
  UPDATE_SINGLE_EDIT_LONG_COPY,
  SUBJECTIVE_CLAIMS_POPUP,
  AUTO_GEN_SINGLE_SOURCE,
  VIEW_RELATED_ITEMS_DATA,
  RESET_VIEW_RELATED_COPY,
  REQUEST_COPY_VC_DATA,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { cloneDeep, each, first, isEmpty, isNil } from 'lodash'
import changeCase from 'change-case'
import { savePaginationDataEvent } from '../auth/actionCreator'
import { DownloadDate } from '../../components/Shared/SharedUtils'
import { toast } from 'react-toastify'
import {
  RELATIONSHIP_TYPE_CODE,
  TOAST_BODY,
  RESTRICTED_PROFANE_WARNING,
} from '../../components/Shared/Constants'

const fileDownload = require('js-file-download')

function dispatchDraftDataEvent(draftData) {
  return {
    type: REQUEST_DRAFT_COPY_EVENT,
    payload: {
      long_copy: draftData.long_copy,
      feature_bullets: draftData.feature_bullets,
      version: draftData.version,
      created_by: draftData.created_by,
      isFetching: true,
    },
  }
}

function dispatchFirstDraftDataEvent(createdBy) {
  return {
    type: REQUEST_FIRST_DRAFT_COPY_EVENT,
    payload: {
      event_type: 'WritingStarted',
      last_updated_by: createdBy,
      isFetching: true,
    },
  }
}

function dispatchSaveDataEvent() {
  return {
    type: REQUEST_SAVE_COPY_EVENT,
    payload: {
      isFetching: true,
    },
  }
}

function dispatchPublishDataEvent() {
  return {
    type: REQUEST_PUBLISH_COPY_EVENT,
    payload: {
      isFetching: true,
    },
  }
}

export function draftDataEvent(trackingId, data) {
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/${trackingId}/draft_copy?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        data
      )
      .then((res) => {
        dispatch(
          resetFetch(false),
          toast.success('Draft save successfully', TOAST_BODY)
        )
        dispatch(updateCopyEvent(trackingId, 'WritingStarted'))
        dispatch(dispatchDraftDataEvent(data))
        dispatch(viewCopyDetailEvent(trackingId))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function firstDraftDataEvent(trackingId, data) {
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/${trackingId}/draft_copy?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        data
      )
      .then((res) => {
        dispatch(dispatchFirstDraftDataEvent(data.created_by))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function saveDataEvent(trackingId, data) {
  return (dispatch) => {
    dispatch(dispatchSaveDataEvent())
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/${trackingId}/save_copy?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        data
      )
      .then((res) => {
        dispatch(
          resetFetch(false),
          toast.success('Copy submitted successfully for QA', TOAST_BODY)
        )
        dispatch(updateCopyEvent(trackingId, 'ReadyForQA'))
        dispatch(viewCopyDetailEvent(trackingId))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function publishDataEvent(trackingId, data) {
  return (dispatch) => {
    dispatch(dispatchPublishDataEvent())
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/${trackingId}/publish_copy?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        data
      )
      .then((res) => {
        dispatch(
          resetFetch(false),
          toast.success('Published Successfully', TOAST_BODY)
        )
        dispatch(updateCopyEvent(trackingId, 'Done'))
        dispatch(viewCopyDetailEvent(trackingId))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function quickEditPublishDataEvent(trackingId, data) {
  return (dispatch) => {
    dispatch(dispatchPublishDataEvent())
    dispatch(cancelQuickPublish())
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/${trackingId}/quick_edit?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        data
      )
      .then((res) => {
        dispatch(
          resetFetch(false),
          toast.success('Published Successfully', TOAST_BODY)
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function newFilterAction(isShown) {
  return {
    type: NEW_FILTER_EVENT,
    payload: isShown,
  }
}

function viewCopyDetailEventSuccess(data, planerNotesData) {
  return {
    type: VIEW_COPY_DETAIL_EVENT,
    payload: {
      selectedCopyData: data,
      plannerNotes: planerNotesData,
      isEditCopyDataAvailable: true,
      longCopy:
        data.current_event.event_type !== 'Done' &&
        data.current_event.event_type !== 'ReadyForQA'
          ? data.draft_copy
            ? data.draft_copy.long_copy
            : data.current_copy
            ? data.current_copy.long_copy
            : ''
          : data.current_copy
          ? data.current_copy.long_copy
          : '',
      featureBullets:
        data.current_event.event_type !== 'Done' &&
        data.current_event.event_type !== 'ReadyForQA'
          ? data.draft_copy
            ? data.draft_copy.feature_bullets
            : data.current_copy
            ? data.current_copy.feature_bullets
            : ''
          : data.current_copy
          ? data.current_copy.feature_bullets
          : '',
      generated_feature_bullets:
        data.current_event.event_type !== 'Done' &&
        data.current_event.event_type !== 'ReadyForQA'
          ? data.draft_copy
            ? data.draft_copy.generated_feature_bullets
            : data.current_copy
            ? data.current_copy.generated_feature_bullets
            : ''
          : data.current_copy
          ? data.current_copy.generated_feature_bullets
          : '',
      generated_long_copy:
        data.current_event.event_type !== 'Done' &&
        data.current_event.event_type !== 'ReadyForQA'
          ? data.draft_copy
            ? data.draft_copy.generated_long_copy
            : data.current_copy
            ? data.current_copy.generated_long_copy
            : ''
          : data.current_copy
          ? data.current_copy.generated_long_copy
          : '',
      isEdited: false,
      editedFeatureBullets: [],
      isValid: true,
      ltwoDisclaimer: [],
      mvi: data?.mvi || false,
    },
  }
}

function updateCopyEvent(trackingId, eventType) {
  return {
    type: UPDATE_COPY_LIST,
    payload: {
      id: trackingId,
      eventType: eventType,
    },
  }
}

function requestCopyDetailEvent(isEditCopyDataAvailable) {
  return {
    type: REQUEST_COPY_DETAIL_EVENT,
    payload: {
      isEditCopyDataAvailable: false,
      isItemDataAvailable: false,
    },
  }
}

function requestItemDataEvent(tcin) {
  return (dispatch) => {
    dispatch(dispatchItemDataEvent())
    axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/tcin/${tcin}?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        let itemData = response.data
        dispatch(
          itemDataEventSuccess({
            dpci: itemData.dpci,
            tcin: itemData.tcin,
            relationship_type_code: itemData.relationship_type,
            enrichment: itemData.enrichment,
            attributes: itemData.attributes,
            parent_items: itemData.parent_items,
            product_description: itemData.product_description,
            variation: itemData.variation,
            merchandise_classification: itemData.merchandise_classification,
            merchandise_type_attributes: itemData.merchandise_type_attributes,
            product_classification: itemData.product_classification,
            product_brand: itemData.product_brand,
            product_vendors: itemData.product_vendors,
            wellness_merchandise_attributes:
              itemData.wellness_merchandise_attributes,
            variationPrimaryImage: itemData.variationPrimaryImage,
            intended_launch_date_time: itemData.intended_launch_date_time,
            launch_date_time: itemData.launch_date_time,
            claimsTcin:
              itemData.relationship_type_code === RELATIONSHIP_TYPE_CODE.VAP
                ? itemData.child_items &&
                  itemData.child_items[0] &&
                  itemData.child_items[0].tcin
                : itemData.tcin,
          })
        )
        itemData.relationship_type_code !== RELATIONSHIP_TYPE_CODE.VC &&
        itemData.relationship_type_code !== RELATIONSHIP_TYPE_CODE.SA &&
        itemData.relationship_type_code !== RELATIONSHIP_TYPE_CODE.CC
          ? dispatch(getChildItemData(itemData))
          : dispatch(resetFetch(false))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function storeSwatchData(imageDetails) {
  let currentImagesSelected
  let currentImage
  let currentSwatch
  let firstChild = imageDetails?.[0]
  currentImagesSelected = firstChild?.images?.map((image) => {
    return {
      label: image,
      value: image,
      imageUrl: firstChild?.baseUrl + image,
    }
  })
  currentSwatch = {
    label: firstChild?.color,
    value: firstChild,
    swatchUrl: firstChild?.baseUrl + firstChild?.swatchUrl,
  }
  currentImage = currentImagesSelected?.[0]
  return {
    type: STORE_SWATCH_DATA,
    payload: {
      swatchData: imageDetails,
      currentImagesSelected: currentImagesSelected,
      isFetching: false,
      currentImage: currentImage,
      currentSwatch: currentSwatch,
    },
  }
}

function getChildItemData(itemData) {
  var requestData = {
    query: {
      bool: {
        must: {
          terms: {
            'item_data.parent_tcins': [itemData.tcin],
          },
        },
      },
    },
    _source: {
      includes: ['item_data.digital_assets', 'item_data.variations'],
    },
  }
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/pipeline_search/search/tcin_index?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestData
      )
      .then((res) => {
        let childAssets = new Map()
        if (res?.data?.hits?.hits) {
          res?.data?.hits?.hits?.forEach((item, i = 0) => {
            let imageData = item._source.item_data.digital_assets.images
            const color = item._source.item_data.variations.themes.filter(
              (r) => r.name === 'color'
            )
            let images =
              imageData && imageData.alternate_images
                ? imageData.alternate_images
                : []
            if (imageData && imageData.swatch_image) {
              images.unshift(imageData.primary_image)
              childAssets.set(color, {
                color: first(color) ? first(color).value : '',
                baseUrl:
                  imageData && imageData.base_url ? imageData.base_url : '',
                swatchUrl:
                  imageData && imageData.swatch_image
                    ? imageData.swatch_image
                    : `swatch` + i++,
                images: images,
              })
            }
          })
        }

        let imageDetails = Array.from(childAssets.values())
        if (imageDetails?.length > 0) {
          dispatch(storeSwatchData(imageDetails))
        }
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function dispatchItemDataEvent() {
  return {
    type: REQUEST_ITEM_DATA,
    payload: {
      isFetching: true,
    },
  }
}

function itemDataEventSuccess(data) {
  let currentImagesSelected
  let currentImage
  let imageData = data.enrichment.images
  let images =
    imageData && imageData.alternate_images ? imageData.alternate_images : []
  if (imageData) {
    images.unshift(imageData.primary_image)
  }
  currentImagesSelected = images.map((image) => {
    return {
      label: image,
      value: image,
      imageUrl: imageData.base_url + image,
    }
  })
  currentImage = currentImagesSelected[0]
  return {
    type: RECEIVE_ITEM_DATA,
    payload: {
      selectedItemData: data,
      currentImagesSelected: currentImagesSelected,
      isFetching: false,
      isItemDataAvailable: true,
      currentImage: currentImage,
    },
  }
}

export function toggleActionDrawer(isActionDrawerOpen, drawerAction) {
  return {
    type: TOGGLE_ACTION_DRAWER,
    payload: {
      isActionDrawerOpen: isActionDrawerOpen,
      drawerAction: drawerAction,
    },
  }
}

export function selectRoutingTeam(currentRouteTeam) {
  return {
    type: SET_ROUTE_TEAM,
    payload: {
      currentRouteTeam: currentRouteTeam,
    },
  }
}

export function openDrawerAndGetWritersForCopyAssignment(drawerAction) {
  return (dispatch) => {
    dispatch(setWritersFetching(true))
    dispatch(toggleActionDrawer(true, drawerAction))
    axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/copy_writers?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((res) => {
        let assignedWriters = res.data.map((emailAddress) => {
          return {
            value: emailAddress,
            label: emailAddress,
          }
        })
        dispatch(setAssignedWritersList(assignedWriters))
        dispatch(setWritersFetching(false))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function setWritersFetching(isFetchingWriters) {
  return {
    type: SET_IS_FETCHING_WRITERS,
    payload: {
      isFetchingWriters: isFetchingWriters,
    },
  }
}

function setAssignedWritersList(assignedWritersList) {
  return {
    type: SET_ASSIGNED_WRITERS_LIST,
    payload: {
      assignedWritersList: assignedWritersList,
    },
  }
}

export function selectAssignedWriter(currentWriterAssigned) {
  return {
    type: SET_ASSIGNED_WRITER,
    payload: {
      currentWriterAssigned: currentWriterAssigned,
    },
  }
}

export function addPlannerNotes(plannerNotes) {
  return {
    type: ADD_PLANNER_NOTES,
    payload: {
      plannerNotes: plannerNotes,
    },
  }
}

export function requestDepartmentFilter() {
  return (dispatch) => {
    dispatch(dispatchDepartmentDataEvent())
    axios
      .get(`${envConfigs.api.itemApi}/digital_items/v1/hierarchy/departments`)
      .then((res) => {
        const returnData = []
        each(res.data, function (department) {
          if (
            !isNil(department.department_id) &&
            department.department_id !== '0'
          ) {
            returnData.push({
              display:
                department.department_id + ' - ' + department.department_name,
              value: department.department_name,
              classes: department.class_list,
            })
          }
        })
        dispatch(
          itemDepartmentEventSuccess({
            returnData,
          })
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function dispatchDepartmentDataEvent() {
  return {
    type: REQUEST_DEPARTMENT,
    payload: {
      isFetching: true,
    },
  }
}

function itemDepartmentEventSuccess(data) {
  return {
    type: RECIEVE_DEPARTMENT,
    payload: {
      selectedDepartmentData: data,
      isFetching: false,
    },
  }
}

function receiveClaimsData(data) {
  return {
    type: RECEIVE_CLAIMS_DATA,
    payload: {
      claims: data,
    },
  }
}

function receiveClaimsError(error) {
  return {
    type: RECEIVE_CLAIMS_ERROR,
    payload: {
      error: error,
    },
  }
}

export function getClaimsData(tcin) {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/claims/${tcin}?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      if (res.data.claims && !res.data.error) {
        dispatch(receiveClaimsData(res.data.claims))
      }
      if (res.data.error) {
        dispatch(receiveClaimsError(res.data.error))
      }
    } catch (e) {
      dispatch(receiveClaimsError(e))
    }
  }
}

function getCopyViewRelatedItemsData(data, total_elements, page_number) {
  return {
    type: VIEW_RELATED_ITEMS_DATA,
    payload: {
      copyViewRelatedItems: data,
      viewRelatedItemTotalElements: total_elements,
      viewRelatedItemCopyPageNumber: page_number,
    },
  }
}

// eslint-disable-next-line no-unused-vars
const mockData = {
  content: [
    {
      tcin: '82279995',
      copy_tracking_id: '603e8b1c7a62bb0001c9d579',
      item_state: 'ReadyForCopy',
    },
    {
      tcin: '82279996',
      copy_tracking_id: '603e8b2682009700010f6ea4',
      view_related_feature_bullets: {
        value: [
          'Transform any space with Biarritz charm.',
          'Durable synthetic, high-traffic friendly.',
          'Simply clean with a garden hose.',
          'Stylish patterns meet outdoor resilience.',
          'Fashionable colors for any decor.',
          'Sizes and shapes for every area.',
          'Indoor elegance meets outdoor durability.',
          'Easy maintenance for your busy life.',
        ],
        is_genai_bullets: true,
      },
      item_state: 'ReadyForCopy',
    },
    {
      tcin: '82279997',
      copy_tracking_id: '603e8b2082009700010f6e64',
      item_state: 'ReadyForCopy',
    },
    {
      tcin: '82279998',
      copy_tracking_id: '603e8b1d7a62bb0001c9d58d',
      item_state: 'ReadyForCopy',
    },
    {
      tcin: '82279909',
      copy_tracking_id: '603e8b217a62bb0001c9d5ac',
      item_state: 'ReadyForCopy',
    },
    {
      tcin: '82279854',
      copy_tracking_id: '603e8b1a82009700010f6e22',
      item_state: 'ReadyForCopy',
    },
    {
      tcin: '82279999',
      copy_tracking_id: '603e8b1d82009700010f6e43',
      item_state: 'ReadyForCopy',
    },
    {
      tcin: '82279855',
      copy_tracking_id: '603e8b1c7a62bb0001c9d56f',
      item_state: 'ReadyForCopy',
    },
    {
      tcin: '82280000',
      copy_tracking_id: '603e8b1b82009700010f6e2f',
      item_state: 'ReadyForCopy',
    },
    {
      tcin: '82280001',
      copy_tracking_id: '603e8b277a62bb0001c9d5ec',
      item_state: 'ReadyForCopy',
    },
  ],
  pageable: {
    sort: {
      empty: true,
      unsorted: true,
      sorted: false,
    },
    offset: 0,
    page_number: 0,
    page_size: 10,
    paged: true,
    unpaged: false,
  },
  total_pages: 1,
  total_elements: 10,
  last: true,
  size: 10,
  number: 0,
  sort: {
    empty: true,
    unsorted: true,
    sorted: false,
  },
  number_of_elements: 10,
  first: true,
  empty: false,
}

function dispatchCopyVCDataEvent(flag) {
  return {
    type: REQUEST_COPY_VC_DATA,
    payload: {
      isFetchingCopyVc: flag,
    },
  }
}

export function getCopyViewRelatedItems(tcin, pageNumber) {
  const requestPayload = {
    is_external: false,
    tcin: tcin,
    page_no: pageNumber,
    page_size: 10,
  }
  return (dispatch) => {
    dispatch(dispatchCopyVCDataEvent(true))
    axios
      .post(
        `${envConfigs.api.aggregator}item_assets/v1/view_related_item/search?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestPayload
      )
      .then((response) => {
        dispatch(dispatchCopyVCDataEvent(false))
        dispatch(
          getCopyViewRelatedItemsData(
            response?.data?.content,
            response.data.total_elements,
            response.data.pageable?.page_number
          )
        )
      })
      .catch((error, data) => {
        dispatch(dispatchCopyVCDataEvent(false))
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function resetViewRelatedItemFetch() {
  return {
    type: RESET_VIEW_RELATED_COPY,
  }
}

export function viewCopyDetailEvent(copyId) {
  return (dispatch) => {
    dispatch(requestCopyDetailEvent(false))
    dispatch(resetViewRelatedItemFetch())
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/${copyId}?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((res) => {
        dispatch(requestItemDataEvent(res.data.tcin))
        dispatch(getCopyViewRelatedItems(res.data.tcin, 0))
        dispatch(
          viewCopyDetailEventSuccess(
            {
              id: res.data.id,
              tcin: res.data.tcin,
              tcin_info: res.data.tcin_info,
              current_copy: res.data.current_copy,
              copy_history: res.data.copy_history,
              draft_copy: res.data.draft_copy,
              current_event: res.data.current_event,
              created_by: res.data.created_by,
              last_updated_by: res.data.last_updated_by,
              item_description: res.data.item_details,
              mvi: res.data.mvi,
            },
            res.data.planner_note
          )
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function resetFetch(data) {
  return {
    type: LONG_COPY_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function changeFilterEvent(data) {
  return {
    type: CHANGE_FILTER_EVENT,
    payload: {
      selectedFilter: data.selectedFilter,
    },
  }
}

export function handleSelectCopy(data) {
  return {
    type: SELECT_COPY_EVENT,
    payload: {
      selectedCopy: data.selectedCopy,
    },
  }
}

export function selectFilterValueEvent(data) {
  return {
    type: SELECT_FILTER_VALUE_EVENT,
    payload: {
      filterValues: data.filterValues,
    },
  }
}

export function removeFilterHandler(
  data,
  currentPage,
  defaultPageSize,
  emailId,
  sortDirection,
  sortField
) {
  return (dispatch) => {
    var filter = dispatch(removeFilterEvent(data))
    dispatch(
      requestCopyData(
        filter.payload.selectedFilters,
        currentPage,
        defaultPageSize,
        emailId,
        sortDirection,
        sortField
      )
    )
  }
}

export function removeFilterEvent(data) {
  var newFilterContainerShown = true
  var copyDataContainerShown = true
  var deepCopy = cloneDeep(data.selectedFilters)
  var currentFilter = deepCopy.filter(
    (v) => v.filterValue === data.mainFilterValue
  )[0]
  currentFilter.selectedValues = currentFilter.selectedValues.filter(
    (item) => item.value !== data.filterValue
  )
  deepCopy = deepCopy.filter((v) => v.selectedValues.length >= 1)
  if (currentFilter.selectedValues.length === 0 && deepCopy.length <= 0) {
    deepCopy = deepCopy.filter((v) => v.filterValue !== data.mainFilterValue)
    newFilterContainerShown = false
    copyDataContainerShown = false
  }
  return {
    type: REMOVE_FILTER_EVENT,
    payload: {
      newFilterContainerShown: newFilterContainerShown,
      copyDataContainerShown: copyDataContainerShown,
      selectedFilters: deepCopy,
    },
  }
}

function changePage(currentPage) {
  return {
    type: CHANGE_CURRENT_PAGE_LONG_COPY,
    payload: {
      currentPage: currentPage,
    },
  }
}

export function handleChangePage(data, emailId, sortDirection, sortField) {
  return (dispatch) => {
    dispatch(changePage(data.currentPage))
    dispatch(
      requestCopyData(
        data.selectedFilters,
        data.currentPage,
        data.defaultPageSize,
        emailId,
        sortDirection,
        sortField
      )
    )
  }
}

function changePageSize(defaultPageSize) {
  return {
    type: CHANGE_DEFAULT_PAGE_SIZE_LONG_COPY,
    payload: {
      defaultPageSize: defaultPageSize,
    },
  }
}

export function changeDefaultPageSize(
  data,
  emailId,
  sortDirection,
  sortField,
  pageName
) {
  return (dispatch) => {
    dispatch(changePageSize(data.defaultPageSize))
    dispatch(
      savePaginationDataEvent({
        pageSize: data.defaultPageSize,
        sortOrder: sortDirection,
        sortField: sortField,
        pageName: pageName,
      })
    )
    // Always setting the currentPage to 0 to prevent bugs
    dispatch(changePage(0))
    dispatch(
      requestCopyData(
        data.selectedFilters,
        0,
        data.defaultPageSize,
        emailId,
        sortDirection,
        sortField
      )
    )
  }
}

/**
 * Data will have following attributes
 * NewFilterContainer
 * CopyDataContainer
 * @param {*} data
 */
export function buildFilterAction(
  data,
  pageNumber,
  pageSize,
  emailId,
  sortDirection,
  sortField,
  filterValues
) {
  var alreadySelectedFilters = data.selectedFilters.filter(
    (item) => item.filterValue === data.selectedFilter.value
  )
  const filterContainsNotes =
    data.selectedFilters.filter((item) => item.filterValue === 'notes').length >
    0
  const newFilterContainsNotes = filterValues.noteOptions !== -1
  if (filterContainsNotes && newFilterContainsNotes) {
    return (dispatch) => {
      dispatch(
        resetFetch(false),
        toast.error('You have attempted to add duplicate filters.', TOAST_BODY)
      )
    }
  }
  if (alreadySelectedFilters.length > 0) {
    // If filter already selected and different value selected.
    let isDuplicateValueFound = false
    if (Array.isArray(data.selectedFilterValue)) {
      data.selectedFilterValue.forEach((item) => {
        isDuplicateValueFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val) => val.value === item.value
          ).length === 0
      })
    } else {
      isDuplicateValueFound =
        alreadySelectedFilters[0].selectedValues.filter(
          (val) => val.value === data.selectedFilterValue.value
        ).length === 0
    }
    if (isDuplicateValueFound) {
      return (dispatch) => {
        dispatch(changePage(0)) // Reset Current Page to first page
        var filter = dispatch(handleAlreadySelectedFilter(data))
        dispatch(
          requestCopyData(
            filter.payload.selectedFilters,
            pageNumber,
            pageSize,
            emailId,
            sortDirection,
            sortField
          )
        )
      }
    } else {
      return (dispatch) => {
        dispatch(
          resetFetch(false),
          toast.error(
            'You have attempted to add duplicate filters.',
            TOAST_BODY
          )
        )
      }
    }
  } else {
    return (dispatch) => {
      dispatch(changePage(0)) // Reset Current Page to first page
      var filter = dispatch(handleNewFilter(data))
      dispatch(
        requestCopyData(
          filter.payload.selectedFilters,
          0,
          pageSize,
          emailId,
          sortDirection,
          sortField
        )
      )
    }
  }
}

function handleAlreadySelectedFilter(data) {
  var deepCopy = cloneDeep(data.selectedFilters)
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  let oldSelectedValue = deepCopy.filter(
    (v) => v.filterValue === data.selectedFilter.value
  )[0].selectedValues
  selectedValues.forEach((item) => {
    oldSelectedValue.push(item)
  })
  deepCopy
    .filter((v) => v.filterValue === data.selectedFilter.value)[0]
    .selectedValues.concat(selectedValues)
  return {
    type: BUILD_FILTER_EVENT,
    payload: {
      newFilterContainerShown: true,
      copyDataContainerShown: true,
      selectedFilters: deepCopy,
    },
  }
}

function handleNewFilter(data) {
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  var newSelectedFilters = [
    {
      filterValue: data.selectedFilter.value,
      filterDisplay: data.selectedFilter.display,
      selectedValues: selectedValues,
    },
  ]
  return {
    type: BUILD_FILTER_EVENT,
    payload: {
      newFilterContainerShown: true,
      copyDataContainerShown: true,
      selectedFilters: data.selectedFilters.concat(newSelectedFilters),
    },
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING,
    payload: payload,
  }
}

/**
 * Requesting copy data
 * @param {*} data
 */
export function requestCopyData(
  data,
  pageNumber,
  pageSize,
  emailId,
  sortDirection,
  sortField
) {
  var queryParam = {
    email_id: emailId,
  }

  data.map((item) => {
    if (item.filterValue === 'division') {
      queryParam.division = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'tcin') {
      queryParam.tcin = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'eventType') {
      queryParam.event_type = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'department') {
      queryParam.department = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'routedTeams') {
      queryParam.routed_teams = item.selectedValues.map(
        (filter) => filter.value
      )
    }
    if (item.filterValue === 'notes') {
      if (item.selectedValues[0].value === 'showItemsWithNotes') {
        queryParam.planner_note_available = true
      }
      if (item.selectedValues[0].value === 'showItemsWithoutNotes') {
        queryParam.planner_note_available = false
      }
    }
  })

  if (data === undefined || data.length === 0) {
    return clearCopyDataEvent()
  }

  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(getStickerCount(data))
    dispatch(dispatchRequestCopyDataEvent(data))
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/search/page?page_number=${pageNumber}&page_size=${pageSize}&sort_direction=${sortDirection}&sort_field=${sortField}&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        queryParam
      )
      .then((res) => {
        dispatch(
          successRequestDataEvent(
            res.data.content.map((d) => {
              var item = {
                id: d.id,
                tcin: d.tcin,
                tcin_info: d.tcin_info,
                copy: d.copy,
                current_event: d.current_event,
                assigned_to: d.assigned_to,
                assigned_to_user: d.assigned_to_user,
                created_by: d.created_by,
                planner_note: d.planner_note,
                writing_started_by: d.writing_started_by,
                vc_count_with_copy: d?.vc_count_with_copy,
                vc_count_without_copy: d?.vc_count_without_copy,
              }
              return item
            }),
            res.data.totalPages,
            res.data.totalElements
          )
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

function clearCopyDataEvent() {
  return {
    type: CLEAR_COPY_DATA_EVENT,
  }
}

function successRequestDataEvent(json, totalPages, totalElements) {
  return {
    type: RECEIVE_COPY_DATA,
    payload: {
      isFetching: false,
      copyData: json,
      totalPages: totalPages,
      totalElements: totalElements,
    },
  }
}

function dispatchRequestCopyDataEvent(data) {
  var newCopyData = cloneDeep(data)
  newCopyData.splice(0, newCopyData.length)
  return {
    type: REQUEST_COPY_DATA,
    payload: {
      isFetching: true,
      copyData: newCopyData,
      selectedCopy: [],
    },
  }
}

/**
 * This method confirmed route teams
 * @param {*} routeTeam New assigned Route Team
 * @param {*} confirmedBy Confirmed By
 * @param {*} trackingIds Tracking Ids
 */
export function setRoutingTeamEvent(requestBody) {
  return (dispatch) => {
    dispatch(setRouteTeamEventRequest())
    axios
      .put(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/confirm_routing?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestBody
      )
      .then((res) => {
        dispatch(setRouteTeamEventSuccess('Routed Successfully'))
        dispatch(
          resetFetch(false),
          toast.success('Routed Successfully', TOAST_BODY)
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function setRouteTeamEventRequest() {
  return {
    type: REQUEST_SET_ROUTE_TEAM,
    payload: {
      isFetching: true,
    },
  }
}

function setRouteTeamEventSuccess(message) {
  return {
    type: SUCCESS_SET_ROUTE_TEAM,
    payload: {
      isFetching: false,
      successMessage: message,
    },
  }
}

/**
 * This method assigns copy to a user
 * @param {*} writer Writer to assign copy to
 * @param {*} assignedBy Assigned By
 * @param {*} trackingIds Tracking Ids
 */
export function assignWriterToCopy(writer, trackingIds, assignedBy) {
  return (dispatch) => {
    dispatch(setAssignCopyRequest())
    axios
      .put(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/assign_to_writer?assigned_by=${assignedBy}&writer=${writer}&tracking_ids=${trackingIds}&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((res) => {
        dispatch(setAssignCopySuccess('Copy Assigned Successfully'))
        dispatch(
          resetFetch(false),
          toast.success('Copy Assigned Successfully', TOAST_BODY)
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function setAssignCopyRequest() {
  return {
    type: REQUEST_ASSIGN_COPY,
    payload: {
      isFetching: true,
    },
  }
}

function setAssignCopySuccess(message) {
  return {
    type: SUCCESS_ASSIGN_COPY,
    payload: {
      isFetching: false,
      successMessage: message,
    },
  }
}

/**
 *
 * @param {*} note : Notes from planner
 * @param {*} trackingIds : Tracking ids
 * @param {*} createdBy : Created by
 */
export function setPlannerNotesEvent(note, trackingIds, createdBy) {
  return (dispatch) => {
    dispatch(setPlannerNotesEventRequest())
    axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/bulk_planner_notes?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        { tracking_ids: trackingIds, note: note, created_by: createdBy }
      )
      .then((res) => {
        dispatch(setPlannerNotesEventSuccess('Notes save successfully.'))
        dispatch(
          resetFetch(false),
          toast.success('Notes saved successfully', TOAST_BODY)
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function setPlannerNotesEditPageEvent(note, trackingId, createdBy) {
  return (dispatch) => {
    dispatch(setPlannerNotesEventRequest())
    axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/bulk_planner_notes?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        { tracking_ids: [trackingId], note: note, created_by: createdBy }
      )
      .then((res) => {
        dispatch(setPlannerNotesEventSuccessEditPage(note))
        dispatch(
          resetFetch(false),
          toast.success('Notes saved successfully', TOAST_BODY)
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function setPlannerNotesEventRequest() {
  return {
    type: REQUEST_SAVE_PLANNER_NOTES,
    payload: {
      isFetching: true,
    },
  }
}

function setPlannerNotesEventSuccess(message) {
  return {
    type: SUCCESS_SAVE_PLANNER_NOTES,
    payload: {
      isFetching: false,
      successMessage: message,
    },
  }
}

function setPlannerNotesEventSuccessEditPage(note) {
  return {
    type: SUCCESS_SAVE_PLANNER_NOTES_EDIT_PAGE,
    payload: {
      isFetching: false,
      plannerNotes: note,
    },
  }
}

export function loadExistingFilter(selectedFilters) {
  return {
    type: BUILD_FILTER_EVENT,
    payload: {
      newFilterContainerShown: true,
      copyDataContainerShown: true,
      selectedFilters: selectedFilters,
    },
  }
}

export function getStickerCount(selectedFilters) {
  var requestBody = {}
  selectedFilters.map((item) => {
    var filterValue = []
    item.selectedValues.map((sv) => {
      filterValue.push(sv.value)
    })
    requestBody[changeCase.snakeCase(item.filterValue)] = filterValue
  })
  return (dispatch) => {
    dispatch(stickerCall())
    axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/search/status_count_by_criteria?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestBody
      )
      .then((res) => {
        if (res.data.terms_aggregations.esSearchAggregation.values) {
          var arr = Object.keys(
            res.data.terms_aggregations.esSearchAggregation.values
          ).map(function (key) {
            return {
              [key]:
                res.data.terms_aggregations.esSearchAggregation.values[key],
            }
          })
          dispatch(stickerCountSuccess(arr))
        } else {
          var emptyArray = [
            { NewWriteRequest: '0' },
            { Done: '0' },
            { ReadyForCopy: '0' },
            { ReadyForQA: '0' },
            { WritingStarted: '0' },
          ]
          dispatch(stickerCountSuccess(emptyArray))
        }
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function stickerCall() {
  return {
    type: STICKER_DATA_CALLED,
    payload: {
      count: -1,
    },
  }
}

function stickerCountSuccess(dataCount) {
  return {
    type: STICKER_DATA_SUCCESS,
    payload: {
      dataCount: dataCount,
    },
  }
}

export function updateFilterSelected(eventType, isSelected, dataCalled) {
  return {
    type: UPDATE_SELECTED,
    payload: {
      eventType: eventType,
      isSelected: isSelected,
      dataCalled: dataCalled,
    },
  }
}

export function updateFilterSelectedSticker(eventType, isSelected, dataCalled) {
  return {
    type: UPDATE_SELECTED_STICKER,
    payload: {
      eventType: eventType,
      isSelected: isSelected,
    },
  }
}

export function clearSelectedSticker() {
  return {
    type: CLEAR_SELECTED_STICKER,
    payload: {
      isSelected: false,
    },
  }
}

export function updateCurrentImages(swatchImage) {
  let currentImagesSelected = swatchImage.value.images.map((image) => {
    return {
      label: image,
      value: image,
      imageUrl: swatchImage.value.baseUrl + image,
    }
  })
  return {
    type: UPDATE_CURRENT_IMAGES,
    payload: {
      currentImagesSelected: currentImagesSelected,
      currentSwatch: swatchImage,
      currentImage: currentImagesSelected[0],
    },
  }
}

export function updateCurrentSelectedImage(item) {
  return {
    type: UPDATE_CURRENT_IMAGE_SELECTED,
    payload: {
      currentImage: item,
    },
  }
}

export function clearFilters() {
  return {
    type: REMOVE_FILTER_EVENT,
    payload: {
      newFilterContainerShown: false,
      copyDataContainerShown: false,
      selectedFilters: [],
    },
  }
}

export function enterEvent(eventType) {
  return {
    type: ENTERED_EVENT,
    payload: {
      enteredEvent: eventType,
    },
  }
}

export function downloadSelectedTcinsCopyEvent(queryParamter) {
  // harbinger.trackEvent('s7g49u', [{ key: 'Item Count', value: queryParamter.split(',').length }, { key: 'Button Pressed', value: 'Download Selected' }])
  return (dispatch) => {
    dispatch(downloadStartCopy(queryParamter))
    axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/download_copy_and_feature_bullets?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        `[${queryParamter}]`,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        fileDownload(
          res.data,
          `copy_and_feature_bullets_${DownloadDate}_v.2.0.xlsx`
        )
        dispatch(downloadFinishCopy(queryParamter))
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(downloadFinishCopy(queryParamter))
      })
  }
}

export function downloadAllToExcel(selectedFilters, totalElements) {
  var requestBody = {}
  selectedFilters.map((item) => {
    var filterValue = []
    item.selectedValues.map((sv) => {
      filterValue.push(sv.value)
    })
    requestBody[changeCase.snakeCase(item.filterValue)] = filterValue
  })
  // harbinger.trackEvent('s7g49u', [{ key: 'Item Count', value: totalElements }, { key: 'Button Pressed', value: 'Download All' }])
  return (dispatch) => {
    dispatch(downloadStartCopy(requestBody))
    axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/download_copy_and_feature_bullets_by_criteria?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestBody,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        fileDownload(
          res.data,
          `copy_and_feature_bullets_${DownloadDate}_v.2.0.xlsx`
        )
        dispatch(downloadFinishCopy(requestBody))
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(downloadFinishCopy(requestBody))
      })
  }
}

export function downloadStartCopy(downloadedTcins) {
  return {
    type: DOWNLOAD_START_LONG_COPY,
    payload: {
      downloadedTcins: downloadedTcins,
    },
  }
}

export function downloadFinishCopy(downloadedTcins) {
  return {
    type: DOWNLOAD_FINISH_LONG_COPY,
    payload: {
      downloadedTcins: downloadedTcins,
    },
  }
}

export function deleteBulletAndCopy(
  tcinList,
  showSelectedDeleteType,
  editSection = false
) {
  return {
    type: DELETE_COPY_BULLET_CLICKED,
    payload: {
      tcinList: tcinList,
      showSelectedDeleteType: showSelectedDeleteType,
      editSection: editSection,
    },
  }
}

export function cancelCopyBulletDelete() {
  return {
    type: CANCEL_COPY_BULLET_CLICKED,
    payload: {
      tcinList: [],
      confirmationDelete: false,
      selectDeleteType: 'COPY_AND_FEATURE_BULLETS',
      showSelectedDeleteType: false,
      editSection: false,
      suceesfullDeleted: false,
    },
  }
}

export function handleValueDeleteOption(value) {
  return {
    type: DELETE_OPTION_VALUE,
    payload: {
      selectDeleteType: value,
    },
  }
}

export function selectTypeCopyBulletDelete(
  confirmationDelete,
  selectDeleteType,
  showSelectedDeleteType
) {
  return {
    type: CONFIRMATION_DELETE,
    payload: {
      confirmationDelete: confirmationDelete,
      selectDeleteType: selectDeleteType,
      showSelectedDeleteType: showSelectedDeleteType,
    },
  }
}

export function confirmDeleteSelection(deleteData, email) {
  var queryParam = ''
  deleteData.tcinList.map((item) => {
    // eslint-disable-next-line no-useless-concat
    queryParam = queryParam + 'tcins' + '=' + item + '&'
  })
  return (dispatch) => {
    axios
      .delete(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/clear_copy_and_feature_bullets?deleted_by=${email}&scope=${deleteData.selectDeleteType}&${queryParam}key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((res) => {
        var data = {
          selectedCopy: [],
        }
        dispatch(
          resetFetch(false),
          toast.success('Deleted Data Successfully', TOAST_BODY)
        )
        if (deleteData.editSection) {
          dispatch(backTolistPage())
        }
        dispatch(cancelCopyBulletDelete())
        dispatch(handleSelectCopy(data))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function backTolistPage() {
  return {
    type: DELETE_BACT_TO_LIST,
    payload: {
      suceesfullDeleted: true,
    },
  }
}

export function cancelQuickPublish() {
  return {
    type: CANCEL_QUICK_PUBLISH,
    payload: {
      quickEditConfirm: false,
    },
  }
}

export function quickEditPublishConfirm() {
  return {
    type: CANCEL_QUICK_PUBLISH,
    payload: {
      quickEditConfirm: true,
    },
  }
}

export function publishDataError() {
  return (dispatch) => {
    dispatch(
      resetFetch(false),
      toast.error(
        `${RESTRICTED_PROFANE_WARNING}:  items will not be published.`,
        TOAST_BODY
      )
    )
  }
}

export function buildFilterExpand(value) {
  return {
    type: BUILD_FILTER_EXPAND,
    payload: {
      buildFilterExpansionPanel: value,
    },
  }
}

export function setSortField(value) {
  return {
    type: SET_SORT_FIELD,
    payload: value,
  }
}

export function setSortDirection(value) {
  return {
    type: SET_SORT_DIRECTION,
    payload: value,
  }
}

export function storeLongCopyStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_LONG_COPY_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_LONG_COPY,
    payload: data,
  }
}

export function getLongCopyStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Copy%20Manage&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        if (isEmpty(response.data)) {
          dispatch(showDefaultAlert(true))
        } else {
          dispatch(storeLongCopyStrapiData(response.data))
          dispatch(showDefaultAlert(false))
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function updateSingleEditLongCopy(data) {
  return {
    type: UPDATE_SINGLE_EDIT_LONG_COPY,
    payload: data,
  }
}

export function subjectiveClaimsPopUp(value) {
  return {
    type: SUBJECTIVE_CLAIMS_POPUP,
    payload: value,
  }
}

export function autoGenerateSingleSource(value) {
  return {
    type: AUTO_GEN_SINGLE_SOURCE,
    payload: value,
  }
}
