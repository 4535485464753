import { toast } from 'react-toastify'

export const ERR_FILENAME = 'Filename must use TCIN naming conventions.'
export const ERR_FILEFORMAT =
  'File type must be .jpg or .jpeg. or .png or .tif or .psd'
export const ERR_FILESIZESMALL = 'File size must be at least 5kB.'
export const ERR_FILESIZEBIG = 'File size cannot exceed 100MB.'
export const ERR_FILEDUPLICATE = 'File with this name has already been added.'
export const ERR_DOUBLEPERIOD = 'This filename has more than one period.'
export const MSG_NO_VARIATIONS = 'No variation parents match the conditions.'
export const MSG_SAVE_RULE = 'Rule saved successfully.'
export const MSG_SAVE_FILTER = 'Search saved successfully.'
export const MSG_DELETE_FILTER = 'Saved search successfully deleted.'
export const MSG_DELETE_RULE = 'Rule deleted successfully.'
export const MSG_PUBLISH_ITEM_IMAGES =
  'Changes saved successfully and may take up to 30 minutes to be published.'
export const MSG_PUBLISH_SYNC = 'Item sync in progress.'
export const MSG_IMAGE_REVIEW = 'Image review saved successfully.'
export const MSG_IMAGE_REVIEW_UPDATE = 'Image review successfully updated.'
export const MSG_MAX_ALLOWED_TCINS = 'Maximum TCINS allowed are '
export const MSG_UNSAVED_DIALOG =
  'You have unsaved changes. Do you want to leave this page and discard your changes or stay?'
export const MSG_VIDEO_UPLOAD = 'Video group uploaded successfully.'
export const MSG_VIDEO_UPLOAD_IN_PROGRESS =
  'File uploads are currently in progress and will be lost if you leave this page now. Are you sure you want to leave?'
export const MSG_COPY_ADD = 'Copy added to items successfully.'
export const MSG_COPY_REMOVE = 'Copy removed from items successfully.'
export const ERR_SAVE_RULE =
  'Unable to save rule. Please contact support if this issue persists.'
export const ERR_SAVE_FILTER = 'Unable to save search. '
export const ERR_UPDATE_RULE =
  'Unable to update rule. Please contact support if this issue persists.'
export const ERR_DELETE_RULE =
  'Unable to delete rule. Please contact support if this issue persists.'
export const ERR_LOGIN = 'The LAN ID or Password you entered is incorrect.'
export const ERR_PUBLISH_ITEM_IMAGES =
  'Unable to publish changes at this time. Please contact support if this issue persists.'
export const ERR_ITEM_IMAGES_SEARCH =
  'Error searching for TCINs. Please contact support if this issue persists.'
export const ERR_GENERIC =
  'An error has occurred. Please contact support if this issue persists.'
export const ERR_INVALID_FILTERS =
  "You've attempted to add an invalid filter. Please try again or contact support."
export const ERR_VIDEO_UPLOAD =
  'Video group failed to upload. Please contact support if this issue persists.'
export const ERR_IMG_FILENAME = 'Filename must use TCIN naming conventions.'
export const ERR_IMG_FILEFORMAT = 'File type must be .jpg or .jpeg.'
export const ERR_IMG_FILESIZESMALL = 'File size must be at least 5kB.'
export const ERR_IMG_FILESIZEBIG = 'File size cannot exceed 100MB.'
export const ERR_IMG_FILEDUPLICATE =
  'File with this name has already been added.'
export const WARN_PERMISSIONS =
  'Your permissions do not allow you to edit to this content'
export const WARN_DUPLICATE_FILTERS =
  "You've attempted to add duplicate filters. The duplicate filters have been removed."
export const WARN_MAX_FILTERS =
  'Max number of allowed filters for this type was reached and some filters were not applied.  Max = '
export const MSG_CLIPBOARD_SUCCESS = 'Copied.'
export const MSG_CLIPBOARD_FAILURE = 'Copy link not available.'
export const MSG_DELETED_FILE = 'File deleted successfully.'
export const RESTRICTED_PROFANE_WARNING = `Word does not align with Target’s policies. Please replace or remove to continue.`
export const INAPROPRIATE_PROFANE_WARNING = `Word(s) may not align with Target’s policies. Please review, replace, or proceed if applicable.`
export const IMG_REJECTION_REASONS = [
  'Not a photo',
  'Poor quality',
  'Text on image',
  'Style guide violation',
  'Does not match item description',
  'Other',
]
export const EVALRESPONSERROROWNEDBRAND = ` Please make sure the content is appropriate for the context
of this TCIN`
export const EVALRESPONSERRORBULLETS = `Encountered error Highlights- Please submit your own content`
export const EVALRESPONSERRORLONGCOPY = `Encountered error Long Copy- Please submit your own content`
export const AIDISCLAIMER = `This suggested copy is generated by artificial intelligence. By clicking "Accept" below, you warrant and represent that you have reviewed all copy, it is true, not deceptive or misleading, and legally compliant, and you acknowledge that you are still legally responsible for compliance and accuracy of all statements, claims and descriptions.`

export const ROUTES = {
  IMAGES: {
    ROOT: '/v2/images',
    SEARCH: '/v2/images/search',
    MANAGE: '/v2/images/search/manage',
    REVIEW: '/v2/images/search/review',
    REVIEW_HISTORY: '/v2/images/search/review/history',
    LOOK_CLOSER: '/v2/images/search/review/closer',
    UPLOAD: '/v2/images/upload',
    UPLOAD_HISTORY: '/v2/images/history',
    LABELS: '/v2/images/labels',
    LABELS_CREATE: '/v2/images/labels/create',
    LABELS_TRAINING: '/v2/images/labels/training',
    LABELS_CLASSIFIER: '/v2/images/labels/classifier',
    STAGEDATA: '/v2/images/stagedata',
  },
}

export const ERR_MORE_THAN_ONE = 'Only one file allowed.'
export const VIDEO_ASSETS = {
  VIDEO: {
    id: 'video',
    type: 'Video',
  },
  CLOSED_CAPTION: {
    id: 'closedcaption',
    type: 'Closed Caption',
  },
  MEDIA_ALTERNATIVE: {
    id: 'transcript',
    type: 'Media Alternative',
  },
  POSTER_FRAME: {
    id: 'posterframe',
    type: 'Poster Frame',
  },
}

export const VIDEO_APPROVAL_STATUS = {
  PENDING: 'Pending',
  RETRY: 'Retry',
  APPROVED: 'Approved',
  IN_PROCESS: 'InProcess',
  REJECTED: 'Rejected',
}

export const TOAST_BODY = {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const TOAST_BODY_RETRY = {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 20000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const VIDEO_EDIT_MODE = {
  HISTORY: 'HISTORY',
  UPLOAD: 'UPLOAD',
  REVIEW: 'REVIEW',
}

export const IMAGE_VIEW_TYPE = {
  PRIMARY: 'primary',
  ALTERNATE: 'alternate',
  SWATCH: 'swatch',
  READY_FOR_REVIEW: 'readyForReview',
  REJECTED: 'rejected',
  RETOUCH: 'retouch',
  DISABLED: 'disabled',
  REUSE: 'reuse',
}

export const IMAGE_ROW_ID = {
  PRIMARY: 'primary',
  ALTERNATE: 'alternate',
  DISABLED: 'disabled',
}

export const urlProtocol = 'https:'

export const IMAGE_APPROVAL_STATUS = {
  APPROVED: 'Approved',
  IN_PROCESS: 'InProcess',
  REJECTED: 'Rejected',
  RETOUCH: 'Retouch',
  PENDING: 'Pending',
  ERROR: 'Error',
}

export const RELATIONSHIP_TYPE_CODE = {
  SA: 'SA',
  VAP: 'VAP',
  VPC: 'VPC',
  VC: 'VC',
  CC: 'CC',
  FCMP: 'FCMP',
  NA: 'N/A',
}

export const REVIEW_DRAWER_ACTION = {
  APPROVE: 'APPROVE',
  RETOUCH: 'RETOUCH',
  REJECT: 'REJECT',
}

export const noSwatchUrl =
  'https://target.scene7.com/is/image/Target/pipeline_swatch_missing'

export const LIMIT_TCIN_SEARCH_EXPAND = 12
export const DISABLE_ADD_BUTTON = 1

export const diffLogicError = {
  INVALID_ALTTEXT: 'Invalid Alt Text',
  LENGTH_EXCEED_ALTTEXT: 'Alt Text Length Exceeded.Limit characters to 255',
  L2: ` Word(s) may not align with Target’s policies. Please review, replace, or proceed if applicable.`,
  L1: ` Word does not align with Target’s policies. Please replace or remove to continue.`,
}
export const constantParams = {
  tcin: '99999999',
  alt_text: 'Bullseye in red and white',
  image_url:
    'https://target.scene7.com/is/image/TargetDev/GUEST_0a644e89-415c-4897-9251-fb2d21c3495f',
}
