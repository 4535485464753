export const REQUEST_UPLOAD_DATA = 'REQUEST_UPLOAD_DATA'
export const RECEIVE_UPLOAD_DATA = 'RECEIVE_UPLOAD_DATA'
export const BULK_UPLOAD_RESET_FETCH = 'BULK_UPLOAD_RESET_FETCH'
export const CLEAR_UPLOAD_DATA = 'CLEAR_UPLOAD_DATA'
export const BULKUPLOAD_DROP_ZONE_ACTIVE = 'BULKUPLOAD_DROP_ZONE_ACTIVE'
export const SELECT_DATA_EVENT = 'SELECT_DATA_EVENT'
export const REMOVE_DATA_EVENT = 'REMOVE_DATA_EVENT'
export const UPDATE_LONG_COPY = 'UPDATE_LONG_COPY'
export const UPDATE_LONG_COPY_EDIT_STATE = 'UPDATE_LONG_COPY_EDIT_STATE'
export const UPDATE_FEATURE_BULLETS = 'UPDATE_FEATURE_BULLETS'
export const UPDATE_FEATURE_BULLETS_EDIT_STATE =
  'UPDATE_FEATURE_BULLETS_EDIT_STATE'
export const REVERT_FEATURE_BULLETS = 'REVERT_FEATURE_BULLETS'
export const REVERT_LONG_COPY = 'REVERT_LONG_COPY'
export const HANDLE_DELETE_CONFIRMATION = 'HANDLE_DELETE_CONFIRMATION'
export const WRONG_FILE_ADDED = 'WRONG_FILE_ADDED'
export const PUBLISH_EVENT_SUCCESS = 'PUBLISH_EVENT_SUCCESS'
export const UPDATE_SELECTED_LONG_COPY = 'UPDATE_SELECTED_LONG_COPY'
export const UPDATE_SELECTED_FEATURE_BULLETS = 'UPDATE_SELECTED_FEATURE_BULLETS'
export const STORE_BULK_UPLOAD_STRAPI_DATA = 'STORE_BULK_UPLOAD_STRAPI_DATA'
export const UPDATE_ITEM_SEARCH = 'UPDATE_ITEM_SEARCH'
export const DOWNLOAD_START_BULK_UPLOAD = 'DOWNLOAD_START_BULK_UPLOAD'
export const DOWNLOAD_FINISH_BULK_UPLOAD = 'DOWNLOAD_FINISH_BULK_UPLOAD'
export const CHANGE_SEARCH_ITEM = 'CHANGE_SEARCH_ITEM'
export const SHOW_DEFAULT_ALERT_BULK_UPLOAD = 'SHOW_DEFAULT_ALERT_BULK_UPLOAD'
export const BULK_UPLOAD_SUBMIT_ERROR = 'BULK_UPLOAD_SUBMIT_ERROR'
export const HANDLE_AUT0GEN_AI = 'HANDLE_AUT0GEN_AI'
export const HANDLE_AUT0GEN_RESPONSE = 'HANDLE_AUT0GEN_RESPONSE'
export const FEATURE_BULLETS_EDIT_GENAI = 'FEATURE_BULLETS_EDIT_GENAI'
export const UPDATE_FEATURE_BULLETS_AI = 'UPDATE_FEATURE_BULLETS_AI'
export const LONG_COPY_EDIT_GENAI = 'LONG_COPY_EDIT_GENAI'
export const UPDATE_LONG_COPY_AI = 'UPDATE_LONG_COPY_AI'
export const GENERATED_SUBMISSION = 'GENERATED_SUBMISSION'
export const RESET_GENAI_RESPONSE = 'RESET_GENAI_RESPONSE'
export const CLOSE_HANDLE_AUT0GEN_AI = 'CLOSE_HANDLE_AUT0GEN_AI'
export const GENERATED_SUBMISSION_PERIRSCOPE = 'GENERATED_SUBMISSION_PERIRSCOPE'
