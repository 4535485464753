import { withStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
  TableHead,
  TableContainer,
  Button,
} from '@material-ui/core'
import { Link, Icon } from '../../../../../react-praxis-components/src/SideNav'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import { Backdrop, CircularProgress } from '@material-ui/core'
import styles from '../../../theme'

function escapeHtml(input) {
  return { __html: input }
}

function ViewRelatedItemsCopyContainer({
  copyViewRelatedItems,
  classes = {},
  getCopyViewRelatedItems,
  viewRelatedItemTotalElements,
  viewRelatedItemCopyPageNumber,
  selectedCopyData,
  isFetchingCopyVc,
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Open</TableCell>
            <TableCell align="left">TCIN</TableCell>
            <TableCell align="left">Item State</TableCell>
            <TableCell align="left">Feature Bullets</TableCell>
            <TableCell align="left"> Long Copy</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {copyViewRelatedItems?.map((item) => {
            return (
              <TableRow>
                <TableCell align="left">
                  <Tooltip title={'Open'}>
                    <span role="presentation">
                      <Link
                        to={{
                          pathname: `/v2/longcopy/editCopy/${item?.copy_tracking_id}`,
                        }}
                      >
                        <Icon>
                          <OpenInBrowserIcon />
                        </Icon>
                      </Link>
                    </span>
                  </Tooltip>
                </TableCell>

                <TableCell align="left">{item?.tcin}</TableCell>
                <TableCell align="left">{item?.item_state}</TableCell>
                <TableCell align="left">
                  <div
                    className={
                      item?.view_related_feature_bullets?.is_genai_bullets
                        ? 'genAiIndicator'
                        : 'genAiNotPresent'
                    }
                  >
                    <span
                      className={
                        item?.view_related_feature_bullets?.is_genai_bullets
                          ? 'genAiIndicatorText'
                          : 'genAiIndicatorHidden'
                      }
                    ></span>
                    <ul>
                      {item?.view_related_feature_bullets?.value.map(
                        (bullet) => {
                          return (
                            <li
                              key={bullet}
                              dangerouslySetInnerHTML={escapeHtml(bullet)}
                            />
                          )
                        }
                      )}
                    </ul>
                  </div>
                </TableCell>
                <TableCell align="left">
                  <div
                    className={
                      item?.view_related_long_copy?.is_genai_copy
                        ? 'genAiIndicator'
                        : 'genAiNotPresent'
                    }
                  >
                    <span
                      className={
                        item?.view_related_long_copy?.is_genai_copy
                          ? 'genAiIndicatorText'
                          : 'genAiIndicatorHidden'
                      }
                    ></span>

                    {item?.view_related_long_copy?.value}
                  </div>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <div className={classes.showMoreCenterAlign}>
        <div className={classes.marginBottom20}>
          Showing {copyViewRelatedItems?.length} of{' '}
          {viewRelatedItemTotalElements} results
        </div>
        <Button
          variant="outlined"
          color="primary"
          disabled={
            copyViewRelatedItems?.length === viewRelatedItemTotalElements
          }
          onClick={() =>
            getCopyViewRelatedItems(
              selectedCopyData?.tcin,
              viewRelatedItemCopyPageNumber + 1
            )
          }
        >
          Show More
        </Button>
      </div>
      {isFetchingCopyVc && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open
          style={{ zIndex: 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </TableContainer>
  )
}
export default withStyles(styles)(ViewRelatedItemsCopyContainer)
