import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DialogBoxAutoGenAi from './DialogBoxAutoGenAi'
import {
  deleteDataEvent,
  cancelDeleteEvent,
  generateContentApi,
  generatedSubmition,
  resetGenAiResponse,
  closeHandleAutoGenAi,
  generatedSubmitionPerirscopeFlow,
  auditDpGenAISaveDetails,
  checkProfanity,
} from '../../../../../store/bulkUpload/tableActionCreator'
import {
  autoGenerateSingleSource,
  publishDataError,
} from '../../../../../store/longCopy/actionCreator'

function DialogBoxAutoGenAiContainer(props) {
  const cancelDeleteConfirmation = (tcin) => {
    props.cancelDeleteEvent(tcin)
  }

  return (
    <DialogBoxAutoGenAi
      data={props.data}
      cancelDeleteConfirmation={cancelDeleteConfirmation}
      generateContentApi={props.generateContentApi}
      auth={props.auth}
      genAiResponse={props.genAiResponse}
      profanityLoading={props.profanityLoading}
      singleSourceAutoGen={props.singleSourceAutoGen}
      generatedSubmition={props.generatedSubmition}
      resetGenAiResponse={props.resetGenAiResponse}
      closeHandleAutoGenAi={props.closeHandleAutoGenAi}
      generatedSubmitionPerirscopeFlow={props.generatedSubmitionPerirscopeFlow}
      perirscopeFlow={props.perirscopeFlow}
      autoGenerateSingleSource={props.autoGenerateSingleSource}
      auditDpGenAISaveDetails={props.auditDpGenAISaveDetails}
      bulkUploadFlow={props.bulkUploadFlow}
      checkProfanity={props.checkProfanity}
      publishDataError={props.publishDataError}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteDataEvent,
      cancelDeleteEvent,
      generateContentApi,
      generatedSubmition,
      resetGenAiResponse,
      closeHandleAutoGenAi,
      generatedSubmitionPerirscopeFlow,
      autoGenerateSingleSource,
      auditDpGenAISaveDetails,
      checkProfanity,
      publishDataError,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { bulkUpload, auth, longCopy } = state
  const { uploadData, selectedData, defaultUploadData, genAiResponse } =
    bulkUpload
  const { profanityLoading, singleSourceAutoGen } = longCopy
  return {
    uploadData,
    selectedData,
    defaultUploadData,
    auth,
    genAiResponse,
    profanityLoading,
    singleSourceAutoGen,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogBoxAutoGenAiContainer)
