import { TableHead, TableRow, TableCell, Checkbox } from '@material-ui/core'

function BulkUploadReportDataContainerHead(props) {
  const {
    onSelectAllClick = () => {},
    numSelected = 0,
    rowCount = 0,
    copyDataLength = 0,
  } = props

  return (
    <TableHead>
      {JSON.stringify(props)}
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={
              numSelected > 0 &&
              (numSelected < rowCount || numSelected < copyDataLength)
            }
            checked={
              copyDataLength > 0 &&
              (numSelected === rowCount || numSelected === copyDataLength)
            }
            onChange={onSelectAllClick}
            color="primary"
          />
        </TableCell>
        <TableCell padding="none">TCIN</TableCell>
        <TableCell padding="none">Long Copy</TableCell>
        <TableCell padding="none">Feature Bullets</TableCell>
        <TableCell padding="none">Company</TableCell>
        <TableCell padding="none">Created By</TableCell>
        <TableCell padding="none">Created Date</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default BulkUploadReportDataContainerHead
