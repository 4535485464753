import AutoDecisionData from './AutoDecisionData'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  handleChangePage,
  changeDefaultPageSize,
  //   handleSelectCopy,
  downloadSelectedTcinsBulkUploadReportEvent,
  downloadAllToExcel,
  resetFetch,
} from '../../../../store/bulkUploadReport/actionCreator'

import {
  handleSelectCopy,
  submitPassivePathDecisons,
} from '../../../../store/AutoDecisionPassiveFlow/actionCreator'

import { gridCheckedLogic } from '../../../Shared/SharedUtils'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../Shared/Constants'

function AutoDecisionDataContainer(props) {
  const {
    defaultPageSize,
    copyData,
    currentPage,
    selectedCopy,
    totalElements,
    auth,
    selectedFilters,
    rowsPerPage,
  } = props

  const emptyRows =
    defaultPageSize -
    Math.min(defaultPageSize, (totalElements - currentPage) * defaultPageSize)

  const handleSelectAllClick = (event, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = props.copyData
    }
    props.handleSelectCopy({
      selectedCopy: newSelected,
    })
  }

  const handleClick = (_, checkedCopy) => {
    const { selectedCopy } = props
    props.handleSelectCopy({
      selectedCopy: gridCheckedLogic(selectedCopy, checkedCopy),
    })
  }

  const handleChangePage = (_, page) => {
    this.props.handleChangePage(
      {
        currentPage: page,
        defaultPageSize: props.defaultPageSize,
        selectedFilters: props.selectedFilters,
      },
      this.props.sortDirection,
      this.props.sortField
    )
  }

  const downloadAllToExcel = () => {
    if (props.totalElements > 10000) {
      props.resetFetch(false)
      toast.info('Download up to 10000 items only allowed', TOAST_BODY)
      return false
    }
    this.props.downloadAllToExcel(props.selectedFilters, props.totalElements)
  }

  const downloadSelectedTcinsCopy = () => {
    let query = props.selectedCopy.map((item) => item.id)
    props.downloadSelectedTcinsBulkUploadReportEvent(query)
  }

  const handleChangeDefaultPageSize = (event) => {
    this.props.changeDefaultPageSize(
      {
        defaultPageSize: event.target.value,
        selectedFilters: props.selectedFilters,
      },
      props.sortDirection,
      props.sortField,
      props.pageName
    )
  }

  const isSelected = (checkedCopy) =>
    props.selectedCopy.indexOf(checkedCopy) !== -1

  return (
    <AutoDecisionData
      data={copyData}
      selectedCopy={selectedCopy}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      emptyRows={emptyRows}
      totalElements={totalElements}
      handleSelectAllClick={handleSelectAllClick}
      isSelected={isSelected}
      auth={auth}
      handleClick={handleClick}
      handleChangePage={handleChangePage}
      downloadSelectedTcinsCopy={downloadSelectedTcinsCopy}
      handleChangeRowsPerPage={handleChangeDefaultPageSize}
      downloadAllToExcel={downloadAllToExcel}
      selectedFilters={selectedFilters}
      handleSelectCopy={props.handleSelectCopy}
      submitPassivePathDecisons={props.submitPassivePathDecisons}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleChangePage,
      changeDefaultPageSize,
      handleSelectCopy,
      downloadSelectedTcinsBulkUploadReportEvent,
      downloadAllToExcel,
      resetFetch,
      submitPassivePathDecisons,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { passivePathReducer, auth } = state
  const {
    copyData,
    currentPage,
    selectedCopy,
    defaultPageSize,
    currentRouteTeam,
    plannerNotes,
    totalPages,
    totalElements,
    selectedFilters,
    assignedWritersList,
    sortDirection,
    sortField,
    pageName,
  } = passivePathReducer
  return {
    copyData,
    currentPage,
    selectedCopy,
    defaultPageSize,
    currentRouteTeam,
    plannerNotes,
    auth,
    totalPages,
    totalElements,
    selectedFilters,
    assignedWritersList,
    sortDirection,
    sortField,
    pageName,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoDecisionDataContainer)
