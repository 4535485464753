/* eslint-disable no-undef */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UploadCopyBullets from './UploadCopyBullets'
import {
  getBulkUploadData,
  bulkUploadDropZoneActive,
  wrongFileType,
  downloadAllToExcelByTcins,
} from '../../../../../store/bulkUpload/actionCreator'
import { endsWith } from 'lodash'
import readXlsxFile from 'read-excel-file'
import envConfigs from '../../../../../config/apiConfig'

class UploadCopyBulletsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  onDrop = (files) => {
    // harbinger.trackEvent('knqx6m')

    if (endsWith(files[0].name.toLowerCase(), '.xlsx')) {
      readXlsxFile(files[0]).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        if (rows.length < envConfigs.bulkUploadLongCopy.maxNumberofFiles) {
          this.props.bulkUploadDropZoneActive(false)
          var requestBody = {
            files: files,
            processed_by: this.props.auth.email
              ? this.props.auth.email
              : this.props.auth.lanId,
          }
          this.props.getBulkUploadData(requestBody, files[0].name)
        } else {
          var errorMessage = `More than ${
            envConfigs.bulkUploadLongCopy.maxNumberofFiles - 1
          } Excel Rows Not Allowed`
          this.props.wrongFileType(files[0].name, errorMessage, errorMessage)
          this.props.bulkUploadDropZoneActive(false)
        }
      })
    } else {
      // harbinger.trackEvent('nbyyhy', [{ key: 'Error', value: 'Not an excel file' }])
      var errorTitle = 'is not valid for bulk copy upload'
      var errorMessage = 'Please submit an excel document (.xlsx)'
      this.props.wrongFileType(files[0].name, errorTitle, errorMessage)
      this.props.bulkUploadDropZoneActive(false)
    }
  }

  onDragEnter = (files) => {
    this.props.bulkUploadDropZoneActive(true)
  }

  onDragLeave = (files) => {
    this.props.bulkUploadDropZoneActive(false)
  }
  downloadBlankTemplate = () => {
    this.props.downloadAllToExcelByTcins(['-11111111'])
  }

  render() {
    return (
      <UploadCopyBullets
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        dropZoneEnter={this.props.dropZoneEnter}
        downloadBlankTemplate={this.downloadBlankTemplate}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBulkUploadData,
      bulkUploadDropZoneActive,
      wrongFileType,
      downloadAllToExcelByTcins,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, bulkUpload } = state
  const { dropZoneEnter } = bulkUpload
  return {
    auth,
    dropZoneEnter,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadCopyBulletsContainer)
