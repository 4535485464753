/* eslint-disable jsx-a11y/alt-text */
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
  Checkbox,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import {
  RESTRICTED_PROFANE_WARNING,
  INAPROPRIATE_PROFANE_WARNING,
  diffLogicError,
} from '../../../Shared/Constants'
import FlagIcon from '@material-ui/icons/Flag'

import AltTextTableHead from './AltTextTableHead'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { useState } from 'react'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import EditAltTextContainer from '../../EditAltText/EditAltTextContainer'
import DeleteIcon from '@material-ui/icons/Delete'
import DeleteAltText from '../DeleteAltText/DeleteAltText'

const modalStyle = {
  content: {
    top: '88px',
  },
}

function AltTextTableUploadData(props) {
  const { classes, uploadData, isSelected, handleClick, selected } = props
  const [lightboxUrl, setLightboxUrl] = useState(null)

  return (
    <Paper className={classes.tableWrapper}>
      <div className={classes.profaneMainWrap}>
        <Tooltip title={RESTRICTED_PROFANE_WARNING}>
          <div className={classes.profaneWrap}>
            <span className={classes.profaneHeading}>Restricted word</span>
            <span className={classes.loneColour} />
          </div>
        </Tooltip>
        <Tooltip title={INAPROPRIATE_PROFANE_WARNING}>
          <div className={classes.profaneWrap}>
            <span className={classes.profaneHeading}>Inappropriate Word</span>
            <span className={classes.ltwoColour} />
          </div>
        </Tooltip>
      </div>
      <Table aria-labelledby="tableTitle">
        <AltTextTableHead
          handleSelectAllClick={props.handleSelectAllClick}
          numSelected={selected.length}
          rowCount={uploadData.length}
        />
        <TableBody>
          {uploadData.map((data) => {
            const findIsSelected = isSelected(data)
            return (
              <TableRow
                selected={findIsSelected}
                className="buldUploadWrapper"
                aria-checked={findIsSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={findIsSelected}
                    onClick={(event) => handleClick(event, data)}
                    color="primary"
                  />
                </TableCell>
                <TableCell padding="checkbox" className={classes.tcinTextArea}>
                  {data.tcin}
                </TableCell>
                <TableCell
                  padding="none"
                  className={classes.editFeatureBullets}
                >
                  <a href={data.image_url} target="_blank" rel="noreferrer">
                    Img Url
                  </a>
                </TableCell>
                <TableCell
                  padding="none"
                  className={classes.editFeatureBullets}
                >
                  <div>
                    <img
                      role="presentation"
                      src={data.image_url}
                      width={'60px'}
                      height={'60px'}
                    />
                    <ZoomInIcon
                      className={`${classes.zoomin}`}
                      color="primary"
                      onClick={() => {
                        setLightboxUrl(data.image_url)
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell padding="none" className={classes.editAltText}>
                  <EditAltTextContainer data={data} />
                </TableCell>
                <TableCell>
                  <Tooltip title="Delete">
                    <DeleteIcon
                      className={classes.deleteButton}
                      onClick={(event) =>
                        props.altTextDeleteConfirmation(data.unique_id, true)
                      }
                    />
                  </Tooltip>
                  <DeleteAltText
                    data={data}
                    altTextPopUpConfirmation={props.altTextDeleteConfirmation}
                  />
                </TableCell>
                <span>
                  {data.isAltTextEditable ? (
                    ''
                  ) : (
                    <div className={classes.ltwoDisclaimer}>
                      {data?.profane_category?.length ? (
                        <>
                          {data.profane_category.map((item) => {
                            return (
                              <div>
                                {' '}
                                {item === 'L1' ? (
                                  <span>
                                    <FlagIcon
                                      style={{
                                        color: 'white',
                                        verticalAlign: 'middle',
                                      }}
                                    />
                                    {diffLogicError[item]}.
                                  </span>
                                ) : (
                                  <span>
                                    <FlagIcon
                                      style={{
                                        color: 'white',
                                        verticalAlign: 'middle',
                                      }}
                                    />
                                    {diffLogicError[item]}.
                                  </span>
                                )}
                              </div>
                            )
                          })}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </span>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {lightboxUrl && (
        <Lightbox
          reactModalStyle={modalStyle}
          mainSrc={lightboxUrl}
          onCloseRequest={() => setLightboxUrl(null)}
        />
      )}
    </Paper>
  )
}

export default withStyles(styles)(AltTextTableUploadData)
