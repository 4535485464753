import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import CopyDataContainerHead from './CopyDataContainerHead'
import CopyDataContainerToolbar from './CopyDataContainerToolbar'
import CopyDataContainerTitle from './CopyDataContainerTitle'
import CopyDataActionDrawerContainer from '../CopyData/CopyDataActionDrawer/CopyDataActionDrawerContainer'
import { Link, Icon } from '../../../../../react-praxis-components/src/SideNav'
import styles from '../../../theme'
import StickerButtonsContainer from '../StickerButtons/StickerButtonsContainer'
import Moment from 'react-moment'
import DialogBoxConfirmDeleteContainer from '../../Shared/DialogBoxConfirmDelete/DialogBoxConfirmDeleteContainer'
import {
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  Badge,
} from '@material-ui/core'
import { isContentPlanner, isCepAdmin, isCopyWriter } from './CommonUtils'
import { escapeHtml } from '../../../../../Shared/SharedUtils'

function truncateEmail(email) {
  if (email && email.length > 15) {
    return `${email.substring(0, 15)}...`
  }
}

function generateDynamicColumnsByRole(n, userInfo) {
  if (isCepAdmin(userInfo)) {
    return (
      <React.Fragment>
        <TableCell padding="none">{n.assigned_to && n.assigned_to}</TableCell>
        <TableCell padding="none">
          <Tooltip title={n.assigned_to_user} aria-label={n.assigned_to_user}>
            <div>{truncateEmail(n.assigned_to_user)}</div>
          </Tooltip>
        </TableCell>
        <TableCell padding="none">
          {n.current_event.event_type === 'WritingStarted' &&
            n.writing_started_by && (
              <Tooltip
                title={n.writing_started_by}
                aria-label={n.writing_started_by}
              >
                <div>{truncateEmail(n.writing_started_by)}</div>
              </Tooltip>
            )}
        </TableCell>
      </React.Fragment>
    )
  } else if (isContentPlanner(userInfo)) {
    return (
      <React.Fragment>
        <TableCell padding="none">{n.assigned_to && n.assigned_to}</TableCell>
      </React.Fragment>
    )
  } else if (isCopyWriter(userInfo)) {
    return (
      <React.Fragment>
        <TableCell padding="none">{n.assigned_to && n.assigned_to}</TableCell>
        <TableCell padding="none">
          {n.current_event.event_type === 'WritingStarted' && (
            <Tooltip
              title={n.writing_started_by}
              aria-label={n.writing_started_by}
            >
              <div>{truncateEmail(n.writing_started_by)}</div>
            </Tooltip>
          )}
        </TableCell>
      </React.Fragment>
    )
  }
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#CC0000',
    color: 'white',
  },
}))(Badge)

function CopyData(props) {
  const {
    selected = [],
    classes = {},
    data = [],
    page = 0,
    rowsPerPage = 0,
    emptyRows = 0,
    handleSelectAllClick = () => {},
    isSelected = false,
    handleClick = () => {},
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    toggleActionDrawer = () => {},
    toggleActionDrawerForAssigningWriters = () => {},
    totalElements = 0,
    auth = {},
    setBackgroundStatus = () => {},
    downloadSelectedTcinsCopy = () => {},
    downloadAllToExcel = () => {},
    isActionDrawerOpen = false,
    deleteBulletAndCopy = () => {},
    selectedFilters = [],
    sortDirection = '',
    sortField = '',
    requestCopyData = () => {},
    setSortDirection = () => {},
    setSortField = () => {},
    savePaginationDataEvent = () => {},
    pageName = '',
  } = props

  const numberOfNotes = data.filter((item) => item.planner_note).length

  return (
    <div>
      <Paper className={classes.copyDataroot}>
        {data.length > 0 && (
          <React.Fragment>
            <StickerButtonsContainer />
            <CopyDataContainerTitle
              numSelected={selected.length}
              downloadAllToExcel={downloadAllToExcel}
              totalElements={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              numberOfNotes={numberOfNotes}
            />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <CopyDataContainerHead
                  numSelected={selected.length}
                  auth={auth}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rowsPerPage}
                  selectedFilters={selectedFilters}
                  sortDirection={sortDirection}
                  sortField={sortField}
                  requestCopyData={requestCopyData}
                  setSortDirection={setSortDirection}
                  setSortField={setSortField}
                  copyDataLength={data.length}
                  savePaginationDataEvent={savePaginationDataEvent}
                  pageName={pageName}
                />
                <TableBody>
                  {data.map((n) => {
                    const findIsSelected = isSelected(n)
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={findIsSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={findIsSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={findIsSelected}
                            color="primary"
                            onClick={(event) => handleClick(event, n)}
                          />
                        </TableCell>
                        <TableCell padding="none">
                          <Link
                            to={{ pathname: `/v2/longcopy/editCopy/${n.id}` }}
                            className={classes.linkButton}
                          >
                            <Icon>
                              <OpenInBrowserIcon />
                            </Icon>
                          </Link>
                        </TableCell>
                        <TableCell
                          component="th"
                          padding="none"
                          scope="row"
                          style={setBackgroundStatus(
                            n.current_event.event_type
                          )}
                        >
                          <StyledBadge
                            badgeContent="!"
                            invisible={
                              n.planner_note === undefined ||
                              n.planner_note === ''
                            }
                          >
                            <div className={classes.marginLeftRight10}>
                              {props.convertStatusDisplay(
                                n.current_event.event_type
                              )}
                            </div>
                          </StyledBadge>
                        </TableCell>
                        <TableCell padding="none">{n.tcin}</TableCell>
                        <TableCell padding="none">
                          {n.tcin_info && (
                            <div
                              dangerouslySetInnerHTML={escapeHtml(
                                n.tcin_info.product_title
                              )}
                            />
                          )}
                        </TableCell>
                        <TableCell padding="none">
                          {n.tcin_info && n.tcin_info.division}
                        </TableCell>
                        <TableCell padding="none">
                          {n.tcin_info && n.tcin_info.brand_name}
                        </TableCell>
                        <TableCell padding="none">
                          {n.tcin_info && n.tcin_info.relationship_type_code}
                        </TableCell>
                        <TableCell padding="none">
                          <TableRow>
                            VC Count With Copy :
                            {n.vc_count_with_copy
                              ? n.vc_count_with_copy
                              : 'N/A'}
                          </TableRow>
                          <TableRow>
                            VC Count Without Copy :
                            {n.vc_count_without_copy
                              ? n.vc_count_without_copy
                              : 'N/A'}
                          </TableRow>
                        </TableCell>
                        {generateDynamicColumnsByRole(n, auth)}
                        <TableCell padding="none">
                          {n.tcin_info && n.tcin_info.launch_date ? (
                            <Moment
                              format={'YYYY-MMM-DD'}
                              parse={'DD-MM-YYYY HH:mm:ss'}
                            >
                              {n.tcin_info.launch_date}
                            </Moment>
                          ) : (
                            ' - '
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={11} />
                    </TableRow>
                  )}
                  <TableRow className={classes.height49}>
                    <TableCell colSpan={11} />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            {!isActionDrawerOpen && (
              <CopyDataContainerToolbar
                selectedCopy={selected}
                toggleActionDrawer={toggleActionDrawer}
                toggleActionDrawerForAssigningWriters={
                  toggleActionDrawerForAssigningWriters
                }
                downloadSelectedTcinsCopy={downloadSelectedTcinsCopy}
                deleteBulletAndCopy={deleteBulletAndCopy}
                auth={auth}
                isActionDrawerOpen={isActionDrawerOpen}
              />
            )}
            <CopyDataActionDrawerContainer
              toggleActionDrawerForAssigningWriters={
                toggleActionDrawerForAssigningWriters
              }
              downloadSelectedTcinsCopy={downloadSelectedTcinsCopy}
              deleteBulletAndCopy={deleteBulletAndCopy}
            />
            <DialogBoxConfirmDeleteContainer />
          </React.Fragment>
        )}
      </Paper>
      {!data.length && (
        <div className={classes.noResult}>
          No results matched your filter criteria
        </div>
      )}
    </div>
  )
}
export default withStyles(styles)(CopyData)
