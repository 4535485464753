import {
  NEW_FILTER_EVENT_PASSIVE_PATH,
  BUILD_FILTER_EXPAND_PASSIVE_PATH,
  REMOVE_FILTER_EVENT_PASSIVE_PATH,
  CHANGE_FILTER_EVENT_PASSIVE_PATH,
  SELECT_FILTER_VALUE_EVENT_PASSIVE_PATH,
  BUILD_FILTER_EVENT_PASSIVE_PATH,
  CLEAR_PASSIVE_PATH_DATA_EVENT,
  ERROR_SEARCHING_PASSIVE_PATH,
  REQUEST_PASSIVE_PATH_DATA,
  RECEIVE_PASSIVE_PATH_DATA,
  PASSIVE_PATH_RESET_FETCH,
  CHANGE_CURRENT_PAGE_PASSIVE_PATH,
  SELECT_PASSIVE_PATH_EVENT,
  UPDATE_FEATURE_BULLETS_EDIT_STATE_PASSIVE_PATH,
  PROFANITY_API_LOADER_PASSIVE_PATH,
  UPDATE_FEATURE_BULLETS_PASSIVE_PATH,
  UPDATE_LONG_COPY_EDIT_STATE_PASSIVE_PATH,
  UPDATE_LONG_COPY_PASSIVE_PATH,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { cloneDeep, isEqual } from 'lodash'

import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../components/Shared/Constants'

export function newFilterAction(isShown) {
  return {
    type: NEW_FILTER_EVENT_PASSIVE_PATH,
    payload: isShown,
  }
}

export function buildFilterExpand(value) {
  return {
    type: BUILD_FILTER_EXPAND_PASSIVE_PATH,
    payload: {
      buildFilterExpansionPanel: value,
    },
  }
}

export function clearFilters() {
  return {
    type: REMOVE_FILTER_EVENT_PASSIVE_PATH,
    payload: {
      newFilterContainerShown: false,
      copyDataContainerShown: false,
      selectedFilters: [],
    },
  }
}

export function changeFilterEvent(data) {
  return {
    type: CHANGE_FILTER_EVENT_PASSIVE_PATH,
    payload: {
      selectedFilter: data.selectedFilter,
    },
  }
}

export function selectFilterValueEvent(data) {
  return {
    type: SELECT_FILTER_VALUE_EVENT_PASSIVE_PATH,
    payload: {
      filterValues: data.filterValues,
    },
  }
}

export function loadExistingFilter(selectedFilters) {
  return {
    type: BUILD_FILTER_EVENT_PASSIVE_PATH,
    payload: {
      newFilterContainerShown: true,
      copyDataContainerShown: true,
      selectedFilters: selectedFilters,
    },
  }
}

export function removeFilterHandler(data, currentPage, defaultPageSize) {
  return (dispatch) => {
    var filter = dispatch(removeFilterEvent(data))
    dispatch(
      requestBulkUploadReportData(
        filter.payload.selectedFilters,
        currentPage,
        defaultPageSize
      )
    )
  }
}

// eslint-disable-next-line no-unused-vars
const MockApi = {
  data: [
    {
      tcin: 78978964,
      long_copy: {
        value: 'copy value',
        decision_id: '2345678',
        checked: false,
        profane_category: [],
      },
    },
    {
      tcin: 12345676,
      long_copy: {
        value: 'copy value',
        decision_id: '2345699',
        checked: false,
        profane_category: [],
      },
      feature_bullets: {
        value: [
          'Model wears size M and is 6’0"100% combed cotton for a premium, extra-soft feel',
        ],
        checked: false,
        decision_id: '23456456',
        profane_category: [],
      },
    },
  ],
}

export function requestBulkUploadReportData(
  data,
  pageNumber,
  pageSize,
  auth,
  isExternalUser
) {
  var queryParam = {}

  data.map((item) => {
    if (item.filterValue === 'tcin') {
      queryParam.tcin = item.selectedValues.map((filter) => filter.value)
    }
  })

  if (data === undefined || data.length === 0) {
    return clearBulkUploadReportDataEvent()
  }

  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(dispatchRequestBulkUploadReportDataEvent(data))
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/generated/decisions/tcin/search?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        { external: isExternalUser, tcin: queryParam?.tcin }
      )
      .then((res) => {
        let responseData = res?.data?.data.map((item) => {
          return {
            tcin: item.tcin,
            long_copy: {
              value: item?.long_copy?.value,
              decision_id: item?.long_copy?.decision_id,
              checked: false,
              review_status: 'Rejected',
              profane_category: item?.long_copy?.profane_category,
              valid: true,
              modified: false,
            },
            feature_bullets: {
              value: item?.feature_bullets?.value,
              checked: false,
              decision_id: item?.feature_bullets?.decision_id,
              review_status: 'Rejected',
              profane_category: item?.feature_bullets?.profane_category,
              valid: true,
              modified: false,
            },
            isFeatureBulletsEditable: false,
            isLongCopyEditable: false,
            reviewer_email: auth?.email,
          }
        })

        dispatch(successRequestDataEvent(responseData))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

export function resetFetch(data) {
  return {
    type: PASSIVE_PATH_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

function successRequestDataEvent(json, totalPages, totalElements) {
  return {
    type: RECEIVE_PASSIVE_PATH_DATA,
    payload: {
      isFetching: false,
      copyData: json,
      totalPages: totalPages,
      totalElements: totalElements,
    },
  }
}

function dispatchRequestBulkUploadReportDataEvent(data) {
  var newCopyData = cloneDeep(data)
  newCopyData.splice(0, newCopyData.length)
  return {
    type: REQUEST_PASSIVE_PATH_DATA,
    payload: {
      isFetching: true,
      copyData: newCopyData,
      selectedCopy: [],
    },
  }
}

function clearBulkUploadReportDataEvent() {
  return {
    type: CLEAR_PASSIVE_PATH_DATA_EVENT,
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING_PASSIVE_PATH,
    payload: payload,
  }
}

export function removeFilterEvent(data) {
  var newFilterContainerShown = true
  var copyDataContainerShown = true
  var deepCopy = cloneDeep(data.selectedFilters)
  var currentFilter = deepCopy.filter(
    (v) => v.filterValue === data.mainFilterValue
  )[0]
  currentFilter.selectedValues = currentFilter.selectedValues.filter(
    (item) => !isEqual(item.value, data.filterValue)
  )
  deepCopy = deepCopy.filter((v) => v.selectedValues.length >= 1)
  if (currentFilter.selectedValues.length === 0 && deepCopy.length <= 0) {
    deepCopy = deepCopy.filter((v) => v.filterValue !== data.mainFilterValue)
    newFilterContainerShown = false
    copyDataContainerShown = false
  }
  return {
    type: REMOVE_FILTER_EVENT_PASSIVE_PATH,
    payload: {
      newFilterContainerShown: newFilterContainerShown,
      copyDataContainerShown: copyDataContainerShown,
      selectedFilters: deepCopy,
    },
  }
}

/**
 * Data will have following attributes
 * NewFilterContainer
 * BulkUploadReportDataContainer
 * @param {*} data
 */
export function buildFilterAction(
  data,
  pageNumber,
  pageSize,
  auth,
  isExternalUser
) {
  var alreadySelectedFilters = data.selectedFilters.filter(
    (item) => item.filterValue === data.selectedFilter.value
  )
  if (alreadySelectedFilters.length > 0) {
    // If filter already selected and different value selected.
    let isDuplicateValueFound = false
    if (Array.isArray(data.selectedFilterValue)) {
      data.selectedFilterValue.forEach((item) => {
        isDuplicateValueFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val) => val.value === item.value
          ).length === 0
      })
    } else {
      isDuplicateValueFound =
        alreadySelectedFilters[0].selectedValues.filter(
          (val) => val.value === data.selectedFilterValue.value
        ).length === 0
    }
    if (isDuplicateValueFound) {
      return (dispatch) => {
        dispatch(changePage(0)) // Reset Current Page to first page
        var filter = dispatch(handleAlreadySelectedFilter(data))
        dispatch(
          requestBulkUploadReportData(
            filter.payload.selectedFilters,
            pageNumber,
            pageSize,
            auth,
            isExternalUser
          )
        )
      }
    } else {
      return (dispatch) => {
        dispatch(
          resetFetch(false),
          toast.error(
            'You have attempted to add duplicate filters.',
            TOAST_BODY
          )
        )
      }
    }
  } else {
    return (dispatch) => {
      dispatch(changePage(0)) // Reset Current Page to first page
      var filter = dispatch(handleNewFilter(data))
      dispatch(
        requestBulkUploadReportData(
          filter.payload.selectedFilters,
          0,
          pageSize,
          auth,
          isExternalUser
        )
      )
    }
  }
}

function changePage(currentPage) {
  return {
    type: CHANGE_CURRENT_PAGE_PASSIVE_PATH,
    payload: {
      currentPage: currentPage,
    },
  }
}

function handleNewFilter(data) {
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  var newSelectedFilters = [
    {
      filterValue: data.selectedFilter.value,
      filterDisplay: data.selectedFilter.display,
      selectedValues: selectedValues,
    },
  ]
  return {
    type: BUILD_FILTER_EVENT_PASSIVE_PATH,
    payload: {
      newFilterContainerShown: true,
      copyDataContainerShown: true,
      selectedFilters: data.selectedFilters.concat(newSelectedFilters),
    },
  }
}

function handleAlreadySelectedFilter(data) {
  var deepCopy = cloneDeep(data.selectedFilters)
  let selectedValues = []
  let dateRangeAlreadySelected =
    deepCopy.filter((item) => item.filterValue === 'dateRange').length > 0
  let dateRangeAdded = data.selectedFilter.value === 'dateRange'
  if (dateRangeAlreadySelected && dateRangeAdded) {
    deepCopy
      .filter((item) => item.filterValue === 'dateRange')[0]
      .selectedValues.shift()
  }
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  let oldSelectedValue = deepCopy.filter(
    (v) => v.filterValue === data.selectedFilter.value
  )[0].selectedValues
  selectedValues.forEach((item) => {
    oldSelectedValue.push(item)
  })
  deepCopy
    .filter((v) => v.filterValue === data.selectedFilter.value)[0]
    .selectedValues.concat(selectedValues)
  return {
    type: BUILD_FILTER_EVENT_PASSIVE_PATH,
    payload: {
      newFilterContainerShown: true,
      copyDataContainerShown: true,
      selectedFilters: deepCopy,
    },
  }
}

export function handleSelectCopy(data) {
  return {
    type: SELECT_PASSIVE_PATH_EVENT,
    payload: {
      selectedCopy: data.selectedCopy,
    },
  }
}

export function changeToEditStateFeatureBullets(
  tcin,
  isFeatureBulletsEditable
) {
  return {
    type: UPDATE_FEATURE_BULLETS_EDIT_STATE_PASSIVE_PATH,
    payload: {
      tcin: tcin,
      isFeatureBulletsEditable: isFeatureBulletsEditable,
    },
  }
}

export function checkProfanity(tcin, longCopy, featureBullet, feature) {
  var requestBody = {
    long_copy: longCopy,
    feature_bullets: featureBullet,
    tcin: tcin,
  }
  return (dispatch) => {
    dispatch(profanityLoader(true))
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/profanity_check?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestBody
      )
      .then((res) => {
        if (feature === 'featureBullet') {
          dispatch(updateFeatureBullets(tcin, res.data))
          dispatch(profanityLoader(false))
        }
        if (feature === 'longCopy') {
          dispatch(updateLongCopy(tcin, res.data))

          dispatch(profanityLoader(false))
        }
      })
      .catch((error, data) => {
        dispatch(profanityLoader(false))
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function updateLongCopy(tcin, data, longCopy) {
  return {
    type: UPDATE_LONG_COPY_PASSIVE_PATH,
    payload: {
      tcin: tcin,
      longCopy: data.long_copy,
      isLongCopyEditable: false,
      valid: data.valid,
      profane_category: data.profane_category,
    },
  }
}

export function profanityLoader(value) {
  return {
    type: PROFANITY_API_LOADER_PASSIVE_PATH,
    payload: value,
  }
}

export function updateFeatureBullets(tcin, data, longCopy) {
  return {
    type: UPDATE_FEATURE_BULLETS_PASSIVE_PATH,
    payload: {
      tcin: tcin,
      featureBullets: data.feature_bullets,
      isFeatureBulletsEditable: false,
      valid: data.valid,
      profane_category: data.profane_category,
    },
  }
}

export function changeToEditStateLongCopy(tcin, isLongCopyEditable) {
  return {
    type: UPDATE_LONG_COPY_EDIT_STATE_PASSIVE_PATH,
    payload: {
      tcin: tcin,
      isLongCopyEditable: isLongCopyEditable,
    },
  }
}

export function submitPassivePathDecisons(
  queryparams,
  reloadTcins,
  auth,
  isExternaluser
) {
  queryparams.map((obj) => {
    delete obj?.long_copy?.checked
    delete obj?.long_copy?.profane_category
    delete obj.feature_bullets?.profane_category
    delete obj?.long_copy?.valid
    delete obj.feature_bullets?.checked
    delete obj.feature_bullets?.valid
    delete obj?.isFeatureBulletsEditable
    delete obj?.isLongCopyEditable
    return obj
  })

  return (dispatch) => {
    dispatch(profanityLoader(true))
    //this is mock api implementation will be once Api is enabled from backend will be changed to actual APi.
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/generated/decisions/review/action?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        { data: queryparams }
      )
      .then((res) => {
        dispatch(
          requestBulkUploadReportData(reloadTcins, 0, 10, auth, isExternaluser)
        )
        dispatch(profanityLoader(false))
      })
      .catch((error, data) => {
        dispatch(profanityLoader(false))
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}
