import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import CopyDetailLayout from './CopyDetailLayout'
import { changeTabEdit } from '../../../../../../store/longCopy/editLongCopyActionCreator'
import { getCopyViewRelatedItems } from '../../../../../../store/longCopy/actionCreator'

class CopyDetailContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  handleTabChange = (event, currentTabIndex) => {
    this.props.changeTabEdit(currentTabIndex)
  }

  render() {
    return (
      <CopyDetailLayout
        historyIndex={this.props.historyIndex}
        handleTabChange={this.handleTabChange}
        currentTabIndex={this.props.currentTabIndex}
        hideDot={
          this.props.plannerNotes ? this.props.plannerNotes.length < 1 : true
        }
        copyViewRelatedItems={this.props.copyViewRelatedItems}
        getCopyViewRelatedItems={this.props.getCopyViewRelatedItems}
        viewRelatedItemCopyPageNumber={this.props.viewRelatedItemCopyPageNumber}
        viewRelatedItemTotalElements={this.props.viewRelatedItemTotalElements}
        selectedCopyData={this.props.selectedCopyData}
        isFetchingCopyVc={this.props.isFetchingCopyVc}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeTabEdit,
      getCopyViewRelatedItems,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { longCopy } = state
  const {
    historyIndex,
    currentTabIndex,
    plannerNotes,
    copyViewRelatedItems,
    viewRelatedItemCopyPageNumber,
    viewRelatedItemTotalElements,
    selectedCopyData,
    isFetchingCopyVc,
  } = longCopy
  return {
    historyIndex,
    currentTabIndex,
    plannerNotes,
    copyViewRelatedItems,
    viewRelatedItemCopyPageNumber,
    viewRelatedItemTotalElements,
    selectedCopyData,
    isFetchingCopyVc,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CopyDetailContainer)
