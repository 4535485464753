import React from 'react'
import { TableHead, TableRow, TableCell, Checkbox } from '@material-ui/core'
import PropTypes from 'prop-types'

const columnData = [
  { id: 'tcin', numeric: false, disablePadding: true, label: 'TCIN' },
  {
    id: 'featureBullets',
    numeric: false,
    disablePadding: false,
    label: 'Feature Bullets',
  },
  {
    id: 'revertFeatureBullets',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  { id: 'bulletError', numeric: false, disablePadding: false, label: '' },
  { id: 'longCopy', numeric: false, disablePadding: true, label: 'Long Copy' },
  { id: 'revertLongCopy', numeric: false, disablePadding: false, label: '' },
  // {
  //   id: 'autoGenerate',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Auto Generate Content',
  // },
  { id: 'delete', numeric: false, disablePadding: false, label: 'Delete' },
]

class TableUploadDataHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { onSelectAllClick, numSelected, rowCount } = this.props

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color="primary"
            />
          </TableCell>
          {columnData.map((column) => {
            return (
              <TableCell
                key={column.id}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                {column.label}
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

TableUploadDataHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default TableUploadDataHead
