import { TextField, Tooltip } from '@material-ui/core'
import styles from '../../theme'
import { withStyles } from '@material-ui/core/styles'

function escapeHtml(input) {
  return { __html: input }
}

function EditLongCopy(props) {
  const {
    tcin,
    longCopy,
    isLongCopyEditable,
    updateLongCopy,
    changeToEditState,
    convertLongCopy,
    convertLongCopyTextField,
    classes,
    genAiFlag,
  } = props
  let longDefaultValue = convertLongCopyTextField(longCopy)
  return (
    <div>
      {isLongCopyEditable ? (
        <TextField
          multiline
          rows="10"
          fullWidth
          margin="normal"
          defaultValue={longDefaultValue}
          autoFocus
          onBlur={(event) => updateLongCopy(event, tcin)}
        />
      ) : (
        // because saving an empty long copy with valid feature bullets can cause
        // a result of '<br />', adding this to the "empty" state with the dangerouslySetInnerHtml
        <Tooltip title="Click to Edit">
          <div
            className={
              genAiFlag
                ? classes.historyFeatureBullet
                : classes.clickToEditContainer
            }
            onClick={() => changeToEditState(tcin)}
            role="presentation"
            dangerouslySetInnerHTML={escapeHtml(convertLongCopy(longCopy))}
          />
        </Tooltip>
      )}
    </div>
  )
}

export default withStyles(styles)(EditLongCopy)
