import {
  NEW_FILTER_EVENT_PASSIVE_PATH,
  BUILD_FILTER_EXPAND_PASSIVE_PATH,
  REMOVE_FILTER_EVENT_PASSIVE_PATH,
  CHANGE_FILTER_EVENT_PASSIVE_PATH,
  SELECT_FILTER_VALUE_EVENT_PASSIVE_PATH,
  BUILD_FILTER_EVENT_PASSIVE_PATH,
  CLEAR_PASSIVE_PATH_DATA_EVENT,
  ERROR_SEARCHING_PASSIVE_PATH,
  REQUEST_PASSIVE_PATH_DATA,
  RECEIVE_PASSIVE_PATH_DATA,
  PASSIVE_PATH_RESET_FETCH,
  CHANGE_CURRENT_PAGE_PASSIVE_PATH,
  SELECT_PASSIVE_PATH_EVENT,
  UPDATE_FEATURE_BULLETS_EDIT_STATE_PASSIVE_PATH,
  PROFANITY_API_LOADER_PASSIVE_PATH,
  UPDATE_FEATURE_BULLETS_PASSIVE_PATH,
  UPDATE_LONG_COPY_EDIT_STATE_PASSIVE_PATH,
  UPDATE_LONG_COPY_PASSIVE_PATH,
} from './actionType'

const initState = {
  buildFilterContainerShown: false,
  newFilterContainerShown: false,
  copyDataContainerShown: false,
  selectedFilters: [],
  copyData: [],
  isFetching: false,
  selectedFilter: -1,
  filterValues: {
    dateRange: -1,
    tcins: [],
    emails: [],
    fromDate: -1,
    toDate: -1,
    companyName: -1,
  },
  defaultFilterValues: {
    dateRange: -1,
    tcins: [],
    emails: [],
    fromDate: -1,
    toDate: -1,
    companyName: -1,
  },
  defaultPageSize: 10,
  sortDirection: 'asc',
  sortField: 'TCIN',
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  selectedCopy: undefined,
  buildFilterExpansionPanel: false,
  alert: null,
  information: null,
  faq: null,
  showDefaultAlert: false,
  errorSearching: false,
  // DO NOT CHANGE THE PAGENAME!
  pageName: 'PassivePath',
  profanityLoading: false,
}

export default function passivePathReducer(state = initState, action = {}) {
  switch (action.type) {
    case REMOVE_FILTER_EVENT_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        copyDataContainerShown: payload.copyDataContainerShown,
        selectedFilters: payload.selectedFilters,
      }
      return newState
    }
    case BUILD_FILTER_EXPAND_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterExpansionPanel: payload.buildFilterExpansionPanel,
      }
      return newState
    }
    case NEW_FILTER_EVENT_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterContainerShown: payload,
      }
      return newState
    }
    case CHANGE_FILTER_EVENT_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        selectedFilter: payload.selectedFilter,
        filterValues: initState.filterValues,
      }
      return newState
    }
    case SELECT_FILTER_VALUE_EVENT_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        filterValues: payload.filterValues,
      }
      return newState
    }
    case BUILD_FILTER_EVENT_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        copyDataContainerShown: payload.copyDataContainerShown,
        selectedFilters: payload.selectedFilters,
        // Resetting the value after adding
        filterValues: initState.filterValues,
      }
      return newState
    }
    case CLEAR_PASSIVE_PATH_DATA_EVENT: {
      const newState = {
        ...state,
        copyData: [],
      }
      return newState
    }
    case ERROR_SEARCHING_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    case REQUEST_PASSIVE_PATH_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        copyData: payload.copyData,
        isFetching: payload.isFetching,
        selectedCopy: payload.selectedCopy,
      }
      return newState
    }
    case RECEIVE_PASSIVE_PATH_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        copyData: payload.copyData,
        isFetching: payload.isFetching,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
      }
      return newState
    }
    case PASSIVE_PATH_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
        isLoading: payload.isFetching,
      }
      return newState
    }

    case CHANGE_CURRENT_PAGE_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        currentPage: payload.currentPage,
      }
      return newState
    }
    case SELECT_PASSIVE_PATH_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedCopy: payload.selectedCopy,
        copyData: payload.selectedCopy,
      }
      return newState
    }
    case UPDATE_FEATURE_BULLETS_EDIT_STATE_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        copyData: state.copyData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              long_copy: temp.long_copy,
              feature_bullets: temp.feature_bullets,
              isFeatureBulletsEditable: payload.isFeatureBulletsEditable,
              reviewer_email: temp.reviewer_email,
              isLongCopyEditable: false,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case UPDATE_LONG_COPY_EDIT_STATE_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        copyData: state.copyData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.tcin === payload.tcin) {
            var item = {
              tcin: temp.tcin,
              long_copy: temp.long_copy,
              feature_bullets: temp.feature_bullets,
              isFeatureBulletsEditable: false,
              isLongCopyEditable: payload.isLongCopyEditable,
              reviewer_email: temp.reviewer_email,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case PROFANITY_API_LOADER_PASSIVE_PATH: {
      const { payload } = action
      const newState = {
        ...state,
        profanityLoading: payload,
      }
      return newState
    }

    case UPDATE_FEATURE_BULLETS_PASSIVE_PATH: {
      const { payload } = action
      const {
        featureBullets,
        profane_category,
        valid,
        isFeatureBulletsEditable,
        tcin,
      } = payload
      const newState = {
        ...state,
        copyData: state.copyData.map((data) =>
          data.tcin === tcin
            ? {
                ...data,
                feature_bullets: {
                  ...data.feature_bullets,
                  value: featureBullets,
                  profane_category,
                  valid: Boolean(valid),
                  modified: true,
                },
                isFeatureBulletsEditable,
                isLongCopyEditable: false,
              }
            : data
        ),
        selectedCopy: state.selectedCopy.map((data) =>
          data.tcin === tcin
            ? {
                ...data,
                feature_bullets: {
                  ...data.feature_bullets,
                  value: featureBullets,
                  profane_category,
                  valid: Boolean(valid),
                  modified: true,
                },
                isFeatureBulletsEditable,
                isLongCopyEditable: false,
              }
            : data
        ),
      }
      return newState
    }

    case UPDATE_LONG_COPY_PASSIVE_PATH: {
      const { payload } = action
      const { longCopy, profane_category, valid, isLongCopyEditable, tcin } =
        payload
      const newState = {
        ...state,
        copyData: state.copyData.map((data) =>
          data.tcin === tcin
            ? {
                ...data,
                long_copy: {
                  ...data.long_copy,
                  value: longCopy,
                  profane_category,
                  valid: Boolean(valid),
                  modified: true,
                },
                isFeatureBulletsEditable: false,
                isLongCopyEditable,
              }
            : data
        ),
        selectedCopy: state.selectedCopy.map((data) =>
          data.tcin === tcin
            ? {
                ...data,
                long_copy: {
                  ...data.long_copy,
                  value: longCopy,
                  profane_category,
                  valid: Boolean(valid),
                  modified: true,
                },
                isFeatureBulletsEditable: false,
                isLongCopyEditable,
              }
            : data
        ),
      }
      return newState
    }
    default:
      return state
  }
}
