import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Backdrop,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import { useState, useEffect } from 'react'

// import EditFeatureBulletsContainer from '../EditGenAiFeatureBullets/EditGenAiFeatureBulletsContainer'

import EditFeatureBulletsContainer from '../EditFeatureBullets/EditFeatureBulletsContainer'
import EditLongCopyContainer from '../EditLongCopy/EditLongCopyContainer'
import DeleteIcon from '@material-ui/icons/Delete'
import SharedWarningProfane from './../HeaderSection/SharedWarningProfane'

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import {
  EVALRESPONSERROROWNEDBRAND,
  EVALRESPONSERRORBULLETS,
  EVALRESPONSERRORLONGCOPY,
  AIDISCLAIMER,
} from '../../../../Shared/Constants'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}))

export function SimplePopover({ evaluations_explanation }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <WarningIcon
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Open Popover
      </WarningIcon>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>
          {evaluations_explanation?.map((list) => {
            return <div>{list?.conclusion}</div>
          })}
        </Typography>
      </Popover>
    </div>
  )
}

function DialogBoxAutoGenAi(props) {
  const {
    data,
    generateContentApi,
    auth,
    classes,
    genAiResponse,
    profanityLoading,
    singleSourceAutoGen,
    generatedSubmition,
    resetGenAiResponse,
    closeHandleAutoGenAi,
    generatedSubmitionPerirscopeFlow,
    perirscopeFlow,
    autoGenerateSingleSource,
    auditDpGenAISaveDetails,
    bulkUploadFlow,
    checkProfanity,
    publishDataError,
  } = props

  const [featureBullets, setFeatureBullets] = useState(true)
  const [longCopy, setLongcopy] = useState(true)

  const [deleteFeatureBullets, SetDeleteFeatureBullets] = useState(false)
  const [deleteLongCopy, SetDeleteLongcopy] = useState(false)

  const confirmApprove = (genAiResponse, reject, cancelApiSave) => {
    const flowDecider = (
      payload,
      reject,
      evalFeatureBullets,
      evalLongCopy,
      genFeatureBullets,
      genLongcopy
    ) => {
      if (perirscopeFlow && !reject) {
        return (
          generatedSubmitionPerirscopeFlow(payload),
          autoGenerateSingleSource(),
          auditDpGenAISaveDetails(
            payload,
            auth,
            evalFeatureBullets,
            evalLongCopy,
            genFeatureBullets,
            genLongcopy
          )
        )
      }
      if (bulkUploadFlow && !reject) {
        // eslint-disable-next-line no-sequences
        return (
          generatedSubmition(payload),
          auditDpGenAISaveDetails(
            payload,
            auth,
            evalFeatureBullets,
            evalLongCopy,
            genFeatureBullets,
            genLongcopy
          )
        )
      }
      if (reject) {
        return auditDpGenAISaveDetails(
          payload,
          auth,
          evalFeatureBullets,
          evalLongCopy,
          genFeatureBullets,
          genLongcopy
        )
      }
    }

    const longCopyPayload = {
      generated_long_copy: genAiResponse?.generated_long_copy,
      generated: true,
      modified: genAiResponse?.isLongCopyEdited || false,
      status: deleteLongCopy || reject ? 'Rejected' : 'Accepted',
    }

    const featureBulletsPayload = {
      generated_feature_bullets: genAiResponse?.generated_feature_bullets,
      generated: true,
      modified: genAiResponse?.isFeatureBulletsEdited || false,
      status: deleteFeatureBullets || reject ? 'Rejected' : 'Accepted',
    }

    const pubLishPayloadOnsublit = {
      tcin: genAiResponse?.tcin,
      evaluations_feature_bullets: genAiResponse?.evaluations_feature_bullets,
      evaluations_long_copy: genAiResponse?.evaluations_long_copy,
      longCopy: deleteLongCopy
        ? data.longCopy
        : genAiResponse.longCopy
        ? genAiResponse.longCopy
        : data.longCopy,

      featureBullets: deleteFeatureBullets
        ? data.featureBullets
        : genAiResponse.featureBullets
        ? genAiResponse.featureBullets
        : data.featureBullets,

      valid: genAiResponse.valid,
      isFeatureBulletsEdited:
        !deleteFeatureBullets || data.isFeatureBulletsEdited,
      isLongCopyEdited: !deleteLongCopy || data.isLongCopyEdited,
      owned_brand: genAiResponse?.owned_brand,
    }

    if (genAiResponse?.longCopy && genAiResponse?.featureBullets) {
      let finalPayload = {
        ...pubLishPayloadOnsublit,
        generated_long_copy: longCopyPayload,
        generated_feature_bullets: featureBulletsPayload,
      }
      return flowDecider(
        finalPayload,
        reject,
        genAiResponse?.evaluations_feature_bullets,
        genAiResponse?.evaluations_long_copy,
        featureBulletsPayload,
        longCopyPayload
      )
    }

    if (genAiResponse?.longCopy && !genAiResponse?.featureBullets) {
      let finalPayload = {
        ...pubLishPayloadOnsublit,
        generated_long_copy: longCopyPayload,
      }
      return flowDecider(
        finalPayload,
        reject,
        genAiResponse?.evaluations_feature_bullets,
        genAiResponse?.evaluations_long_copy,
        featureBulletsPayload,
        longCopyPayload
      )
    }

    if (genAiResponse?.featureBullets && !genAiResponse?.longCopy) {
      let finalPayload = {
        ...pubLishPayloadOnsublit,
        generated_feature_bullets: featureBulletsPayload,
      }
      return flowDecider(
        finalPayload,
        reject,
        genAiResponse?.evaluations_feature_bullets,
        genAiResponse?.evaluations_long_copy,
        featureBulletsPayload,
        longCopyPayload
      )
    }
    if (
      cancelApiSave === 'CANCEL' &&
      genAiResponse?.evaluations_long_copy?.length > 0
    ) {
      let finalPayload = {
        ...pubLishPayloadOnsublit,
        generated_long_copy: longCopyPayload,
      }
      return flowDecider(
        finalPayload,
        reject,
        genAiResponse?.evaluations_feature_bullets,
        genAiResponse?.evaluations_long_copy,
        featureBulletsPayload,
        longCopyPayload
      )
    }
    if (
      cancelApiSave === 'CANCEL' &&
      genAiResponse?.evaluations_feature_bullets?.length > 0
    ) {
      let finalPayload = {
        ...pubLishPayloadOnsublit,
        generated_feature_bullets: featureBulletsPayload,
      }
      return flowDecider(
        finalPayload,
        reject,
        genAiResponse?.evaluations_feature_bullets,
        genAiResponse?.evaluations_long_copy,
        featureBulletsPayload,
        longCopyPayload
      )
    }

    if (
      cancelApiSave === 'CANCEL' &&
      genAiResponse?.evaluations_feature_bullets?.length > 0 &&
      genAiResponse?.evaluations_long_copy?.length > 0
    ) {
      let finalPayload = {
        ...pubLishPayloadOnsublit,
        generated_feature_bullets: featureBulletsPayload,
        generated_long_copy: longCopyPayload,
      }
      return flowDecider(
        finalPayload,
        reject,
        genAiResponse?.evaluations_feature_bullets,
        genAiResponse?.evaluations_long_copy,
        featureBulletsPayload,
        longCopyPayload
      )
    }
  }

  useEffect(() => {
    if (genAiResponse?.featureBullets === null) {
      SetDeleteFeatureBullets(true)
    }
    if (genAiResponse?.longCopy === null) {
      SetDeleteLongcopy(true)
    }
  }, [genAiResponse])

  return (
    <Dialog
      open={data?.confirmAutoGenAi || singleSourceAutoGen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        {`Auto Generate Content for Tcin ${data?.tcin}`}
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              name="checkedF"
              color="primary"
              onClick={() => setFeatureBullets(!featureBullets)}
              checked={featureBullets}
            />
          }
          label="Feature Bullets"
        />
        <FormControlLabel
          control={<Checkbox color="primary" checked={longCopy} />}
          label="Long Copy"
          onClick={() => setLongcopy(!longCopy)}
        />

        <Button
          onClick={() => {
            generateContentApi(data?.tcin, featureBullets, longCopy, auth)
            SetDeleteFeatureBullets(false)
            SetDeleteLongcopy(false)
            resetGenAiResponse()
          }}
          variant="contained"
          color="primary"
          disabled={!featureBullets && !longCopy}
        >
          Generate
        </Button>
        {genAiResponse && (!deleteFeatureBullets || !deleteLongCopy) ? (
          <SharedWarningProfane />
        ) : null}

        {profanityLoading ? (
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open
            style={{ zIndex: 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}

        {genAiResponse?.featureBullets && !deleteFeatureBullets ? (
          <div className={classes.wrapperSpaceMain}>
            <div className={classes.wrapperSpace}>
              <span className={classes.historyheaderHighlightText}>
                Feature Bullets / Highlights
              </span>
              <EditFeatureBulletsContainer
                data={genAiResponse}
                genAiFlag={true}
              />
            </div>
            <DeleteIcon
              onClick={() => {
                SetDeleteFeatureBullets(true)
                !genAiResponse?.valid &&
                  checkProfanity(
                    genAiResponse?.tcin,
                    genAiResponse?.longCopy,
                    null,
                    'aiFeatureBullet'
                  )
              }}
              className={classes.deleteIconAlignMent}
            />

            {genAiResponse?.owned_brand &&
              genAiResponse?.evaluations_feature_bullets?.length > 0 && (
                <div>
                  <div className={classes.simplePopOverAlignment}>
                    <SimplePopover
                      evaluations_explanation={
                        genAiResponse?.evaluations_feature_bullets
                      }
                    />
                  </div>
                  <span className={classes.evalWarning}>
                    {EVALRESPONSERROROWNEDBRAND}
                  </span>
                </div>
              )}
          </div>
        ) : null}

        {genAiResponse?.longCopy && !deleteLongCopy ? (
          <div className={classes.wrapperSpaceMain}>
            <div className={classes.wrapperSpace}>
              <span className={classes.historyheaderHighlightText}>
                Long Copy
              </span>
              <EditLongCopyContainer data={genAiResponse} genAiFlag={true} />
            </div>
            <DeleteIcon
              onClick={() => {
                SetDeleteLongcopy(true)
                !genAiResponse?.valid &&
                  checkProfanity(
                    genAiResponse?.tcin,
                    null,
                    genAiResponse?.featureBullets,
                    'aiLongCopy'
                  )
              }}
              className={classes.deleteIconAlignMent}
            />
            {genAiResponse?.owned_brand &&
              genAiResponse?.evaluations_long_copy?.length > 0 && (
                <div>
                  <div className={classes.simplePopOverAlignment}>
                    <SimplePopover
                      evaluations_explanation={
                        genAiResponse?.evaluations_long_copy
                      }
                    />
                  </div>
                  <span className={classes.evalWarning}>
                    {EVALRESPONSERROROWNEDBRAND}
                  </span>
                </div>
              )}
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <>
          {bulkUploadFlow &&
          genAiResponse &&
          (!deleteFeatureBullets || !deleteLongCopy) ? (
            <i className={classes.aiDiclaimer}>{AIDISCLAIMER}</i>
          ) : null}
        </>
        {genAiResponse?.valid ? (
          <>
            {genAiResponse && (!deleteFeatureBullets || !deleteLongCopy) ? (
              <Button
                onClick={() => {
                  confirmApprove(genAiResponse, false)
                  resetGenAiResponse()
                  closeHandleAutoGenAi(data.tcin)
                }}
                variant="contained"
                color="primary"
                disabled={
                  genAiResponse?.isLongCopyEditable ||
                  genAiResponse?.isFeatureBulletsEditable
                }
              >
                Accept
              </Button>
            ) : null}
          </>
        ) : (
          <>
            {genAiResponse && (!deleteFeatureBullets || !deleteLongCopy) ? (
              <Button
                onClick={() => {
                  publishDataError()
                }}
                variant="contained"
                color="primary"
              >
                Accept
              </Button>
            ) : null}
          </>
        )}

        <Button
          onClick={(event) => {
            genAiResponse &&
              confirmApprove(genAiResponse, true, event.target.innerText)
            perirscopeFlow
              ? autoGenerateSingleSource(false)
              : closeHandleAutoGenAi(data.tcin)
            resetGenAiResponse()
          }}
          variant="outlined"
          color="primary"
          disabled={
            genAiResponse?.isLongCopyEditable ||
            genAiResponse?.isFeatureBulletsEditable
          }
        >
          {genAiResponse?.longCopy || genAiResponse?.featureBullets
            ? 'Reject'
            : 'Cancel'}
        </Button>
      </DialogActions>
      {genAiResponse?.feature_bullets_error && (
        <div className={classes.errorMessageMosaic}>
          {genAiResponse?.feature_bullets_error}
        </div>
      )}
      {genAiResponse?.long_copy_error && (
        <div className={classes.errorMessageMosaic}>
          {genAiResponse?.long_copy_error}
        </div>
      )}
      {!genAiResponse?.owned_brand &&
        genAiResponse?.evaluations_feature_bullets?.length > 0 && (
          <div className={classes.errorMessageMosaic}>
            {EVALRESPONSERRORBULLETS}
          </div>
        )}
      {!genAiResponse?.owned_brand &&
        genAiResponse?.evaluations_long_copy?.length > 0 && (
          <div className={classes.errorMessageMosaic}>
            {EVALRESPONSERRORLONGCOPY}
          </div>
        )}
    </Dialog>
  )
}
export default withStyles(styles)(DialogBoxAutoGenAi)
