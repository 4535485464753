import React from 'react'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import DialogBoxErrorContainer from '../DialogBoxError/DialogBoxErrorContainer'
import DropZone from '../../../../Shared/DropZone/DropZone'

function UploadCopyBullets(props) {
  const { onDrop, onDragEnter, onDragLeave, dropZoneEnter, classes } = props

  const dropZoneRef = React.createRef()

  return (
    <div className={classes.itemBulkUploadContainer}>
      <p className={classes.rightSubHeading}>Upload Long copy & Bullets</p>
      <p className={classes.rightSubHeadingOne}>
        You can upload long copy & bullets in bulk using the{' '}
        <span
          role="presentation"
          onClick={() => props.downloadBlankTemplate()}
          className={classes.browseButton}
        >
          TEMPLATE
        </span>
      </p>
      <DropZone
        onDrop={onDrop}
        dropZoneEnter={dropZoneEnter}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        disableClick
        dropZoneRef={dropZoneRef}
        requiredType={null}
        dropZoneActiveStyle={classes.dropZoneActive}
        dropZoneNotActiveStyle={classes.dropZoneNotActive}
        DropZoneContent={
          <React.Fragment>
            <div>
              <CloudUpload className={classes.uploadIcon} />
            </div>
            <p className={classes.DropZoneLargeText}>
              Drag and drop the file here or{' '}
              <span
                role="presentation"
                onClick={() => dropZoneRef.current.open()}
                className={classes.browseButton}
              >
                BROWSE
              </span>
            </p>
            <p className={classes.DropZoneSmallText}>
              XLS. XLSX and .CSV (Maximum file size allowed is 50 MB)
            </p>
          </React.Fragment>
        }
      />
      <DialogBoxErrorContainer />
    </div>
  )
}
export default withStyles(styles)(UploadCopyBullets)
