import {
  REQUEST_UPLOAD_DATA,
  RECEIVE_UPLOAD_DATA,
  BULK_UPLOAD_RESET_FETCH,
  CLEAR_UPLOAD_DATA,
  BULKUPLOAD_DROP_ZONE_ACTIVE,
  WRONG_FILE_ADDED,
  PUBLISH_EVENT_SUCCESS,
  UPDATE_ITEM_SEARCH,
  DOWNLOAD_START_BULK_UPLOAD,
  DOWNLOAD_FINISH_BULK_UPLOAD,
  CHANGE_SEARCH_ITEM,
  STORE_BULK_UPLOAD_STRAPI_DATA,
  SHOW_DEFAULT_ALERT_BULK_UPLOAD,
  BULK_UPLOAD_SUBMIT_ERROR,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { isEmpty } from 'lodash'
import { DownloadDate } from '../../components/Shared/SharedUtils'
import { toast } from 'react-toastify'
import {
  TOAST_BODY,
  RESTRICTED_PROFANE_WARNING,
} from '../../components/Shared/Constants'

const fileDownload = require('js-file-download')

export function updateItemSearch(data) {
  return {
    type: UPDATE_ITEM_SEARCH,
    payload: {
      inputTcins: data,
      isFetching: true,
    },
  }
}

export function changeSearchItem(data) {
  return {
    type: CHANGE_SEARCH_ITEM,
    payload: {
      searchedItems: data,
    },
  }
}

export function resetFetch(data) {
  return {
    type: BULK_UPLOAD_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

function itemDataEventSuccess(data) {
  return {
    type: RECEIVE_UPLOAD_DATA,
    payload: {
      uploadData: data,
      isFetching: false,
    },
  }
}

export function requestLongCopyDataByTcins(tcins, emailId) {
  var queryParam = {
    email_id: emailId,
    tcin: tcins,
  }
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/search/page?page_number=0&page_size=1000&sort_direction=asc&sort_field=tcin&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        queryParam
      )
      .then((res) => {
        if (res.data.content && res.data.content.length > 0) {
          let data = []
          res.data.content.forEach((item) => {
            let currentCopy = ''
            let currentFeatureBullets = []
            if (item.current_copy) {
              if (item.current_copy.long_copy) {
                currentCopy = item.current_copy.long_copy
              }
              if (
                item.current_copy.feature_bullets &&
                item.current_copy.feature_bullets.length > 0
              ) {
                currentFeatureBullets = item.current_copy.feature_bullets
              }
            }
            data.push({
              tcin: item.tcin,
              long_copy: currentCopy,
              feature_bullets: currentFeatureBullets,
              generated_feature_bullets:
                item?.current_copy?.generated_feature_bullets,
              generated_long_copy: item?.current_copy?.generated_long_copy,
              mvi: item.mvi,
              valid: true,
            })
          })
          dispatch(
            itemDataEventSuccess({
              data: data,
            })
          )
        } else {
          dispatch(
            resetFetch(false),
            toast.error('No TCIN Data found', TOAST_BODY)
          )
        }
      })
      .catch((error, data) => {
        dispatch(
          resetFetch(false),
          toast.error('Issue Searching TCINs, try again', TOAST_BODY),
          toast.error(error.message, TOAST_BODY)
        )
      })
  }
}

export function downloadStartCopy(downloadedTcins) {
  return {
    type: DOWNLOAD_START_BULK_UPLOAD,
    payload: {
      downloadedTcins: downloadedTcins,
    },
  }
}

export function downloadFinishCopy(downloadedTcins) {
  return {
    type: DOWNLOAD_FINISH_BULK_UPLOAD,
    payload: {
      downloadedTcins: downloadedTcins,
    },
  }
}

export function downloadAllToExcelByTcins(tcins) {
  var queryParam = {
    tcin: tcins,
  }
  return (dispatch) => {
    dispatch(downloadStartCopy(queryParam))
    axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/download_copy_and_feature_bullets_by_criteria?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        queryParam,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        fileDownload(
          res.data,
          `copy_and_feature_bullets_${DownloadDate}_v.2.0.xlsx`
        )
        dispatch(downloadFinishCopy(queryParam))
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(downloadFinishCopy(queryParam))
      })
  }
}

/**
 * Bulk Data Upload File
 * Data will have following attributes
 * files will contain data of the file
 * proceesed by will contain the user
 * @param {*} data
 */
export function getBulkUploadData(data, fileName) {
  /* eslint-disable */
  let formData = new FormData()
  /* eslint-enable */
  formData.append('files', data.files[0])
  formData.append('processed_by', data.processed_by)
  return (dispatch) => {
    dispatch(dispatchItemDataEvent())
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/bulk_upload?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        dispatch(itemDataEventSuccess(res.data))
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          // harbinger.trackEvent('nbyyhy', [{ key: 'Error', value: 'File data not formatted correctly' }])
          var title = 'is not formtted correctly for bulk copy upload.'
          var message = 'Please download a new template.'
          dispatch(wrongFileType(fileName, title, message))
        } else {
          // harbinger.trackEvent('nbyyhy', [{ key: 'Error', value: 'Service not available' }])
          dispatch(
            resetFetch(false),
            toast.error(
              'The application service is not responding. Please try again later. If this issue persists please request support through POL.',
              TOAST_BODY
            )
          )
        }
      })
  }
}

function dispatchItemDataEvent() {
  return {
    type: REQUEST_UPLOAD_DATA,
    payload: {
      isFetching: true,
    },
  }
}

export function bulkUploadDropZoneActive(data) {
  return {
    type: BULKUPLOAD_DROP_ZONE_ACTIVE,
    payload: {
      dropZoneEnter: data,
    },
  }
}

/**
 * Clear data
 */
export function clearUploadData() {
  // harbinger.trackEvent('nbyyhy', [{ key: 'Error', value: 'Discard button clicked' }])
  return {
    type: CLEAR_UPLOAD_DATA,
  }
}

/**
 * Wrong file type
 */
export function wrongFileType(
  fileName,
  dropZoneErrorTitle,
  dropZoneErrorMessage
) {
  return {
    type: WRONG_FILE_ADDED,
    payload: {
      fileName: fileName,
      dropZoneErrorTitle: dropZoneErrorTitle,
      dropZoneErrorMessage: dropZoneErrorMessage,
      validFile: true,
      isFetching: false,
    },
  }
}

export function confirmWrongFile() {
  return {
    type: WRONG_FILE_ADDED,
    payload: {
      fileName: '',
      dropZoneErrorTitle: '',
      dropZoneErrorMessage: '',
      validFile: false,
      isFetching: false,
    },
  }
}

/**
 * Publish data
 */

export function publishData(data, invalidFiles) {
  return (dispatch) => {
    if (data.data.length > 0) {
      dispatch(dispatchItemDataEvent())
      return axios
        .post(
          `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/bulk_upload/confirm?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
          data
        )
        .then((res) => {
          if (invalidFiles.length > 0) {
            dispatch(publishEventWithInvalidFiles(invalidFiles))
            dispatch(
              resetFetch(false),
              toast.error(
                `${RESTRICTED_PROFANE_WARNING}: ${invalidFiles.length} items will not be published. ${data.data.length} items are valid and will be published.`,
                TOAST_BODY
              )
            )
          } else {
            dispatch(publishEventSuccess())
            dispatch(clearUploadData())
            dispatch(
              resetFetch(false),
              toast.success(
                'Copy & Bullets submitted... publishing may take a few minutes.',
                TOAST_BODY
              )
            )
          }
        })
        .catch((error, data) => {
          // harbinger.trackEvent('nbyyhy', [{ key: 'Error', value: 'Service not available' }])
          dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
          dispatch(bulkUploadSubmitErrorAction(true))
        })
    } else {
      dispatch(publishEventWithInvalidFiles(invalidFiles))
      dispatch(
        resetFetch(false),
        toast.error(
          `${RESTRICTED_PROFANE_WARNING}: ${invalidFiles.length} items will not be published.`,
          TOAST_BODY
        )
      )
    }
  }
}

function publishEventSuccess() {
  // harbinger.trackEvent('4v2r3h')
  return {
    type: PUBLISH_EVENT_SUCCESS,
    payload: {
      validFile: false,
    },
  }
}

function publishEventWithInvalidFiles(invalidFiles) {
  var invalidFilesData = {
    data: [],
  }
  // eslint-disable-next-line array-callback-return
  invalidFiles.map((item) => {
    var data = {}
    data.long_copy = item.longCopy
    data.feature_bullets = item.featureBullets
    data.tcin = item.tcin
    data.valid = item.valid
    data.profane_category = item.ltwoDisclaimer
    invalidFilesData.data.push(data)
  })
  // harbinger.trackEvent('4v2r3h')
  return {
    type: RECEIVE_UPLOAD_DATA,
    payload: {
      uploadData: invalidFilesData,
      isFetching: false,
    },
  }
}

export function storeBulkUploadStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const errorData = data.filter(
    (item) => item.interface_document_type === 'error'
  )
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  const error =
    errorData.length > 0
      ? {
          title: errorData[0].user_facing_title,
          body: errorData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_BULK_UPLOAD_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
      error,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_BULK_UPLOAD,
    payload: data,
  }
}

export function getBulkUploadStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Copy%20Bulk%20Upload&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        if (isEmpty(response.data)) {
          dispatch(showDefaultAlert(true))
        } else {
          dispatch(storeBulkUploadStrapiData(response.data))
          dispatch(showDefaultAlert(false))
        }
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function bulkUploadSubmitErrorAction(data) {
  return {
    type: BULK_UPLOAD_SUBMIT_ERROR,
    payload: data,
  }
}
